import React from "react";
import {
  TradingViewChartDark,
  TradingViewChartLight,
} from "./TradingViewChart";

const NewTradingViewDark = ({ ticker, data }) => {
  return <TradingViewChartDark candleData={data} ticker={ticker} />;
};

const NewTradingViewLight = ({ ticker, data }) => {
  return <TradingViewChartLight candleData={data} ticker={ticker} />;
};

export { NewTradingViewDark, NewTradingViewLight };
