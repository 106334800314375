/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";

/* STYLES */
import "../styles/global.css";

/* PAGES */
import Login from "../pages/auth/login";
import Registration from "../pages/auth/register";
import Dashboard from "../pages/dashboard/index";
import FileUpload from "../pages/dashboard/import-trades/file-upload";
import DailyJournal from "../pages/dashboard/daily-journal/index";
import ProfileSettings from "../pages/dashboard/profile";
import Notebook from "../pages/dashboard/notebook";
import TradeLog from "../pages/dashboard/trade-log";
import SetupManagement from "../pages/dashboard/setup-management";
import ForgotPassword from "../pages/auth/forgot-password";
import TradeReplay from "../pages/dashboard/trade-details";
import NewReports from "../pages/dashboard/new-reports";
import NewBrokerSync from "../pages/dashboard/import-trades/broker-sync/new-index";
import ManualTrades from "../pages/dashboard/import-trades/manual-trade";
import Insights from "../pages/dashboard/insights";

/* LAYOUT COMPONENTS */
import Layout from "../layout/index";

/* TOAST IMPORT */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckLimit from "../service/utils";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  const { loggedIn } = useSelector((state) => state.user);
  const [check] = useCheckLimit("insights");
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <ToastContainer />
        {/* <Loading /> */}

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* DASHBOARD ROUTES */}
          {loggedIn ? (
            <>
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/import-trades/file-upload"
                element={
                  <Layout>
                    <FileUpload />
                  </Layout>
                }
              />
              <Route
                path="/import-trades/broker-sync"
                element={
                  <Layout>
                    <NewBrokerSync />
                  </Layout>
                }
              />
              <Route
                path="/import-trades/broker-sync-new"
                element={<Layout>{/* <NewBrokerSync /> */}</Layout>}
              />
              <Route
                path="/import-trades/manual-trade"
                element={
                  <Layout>
                    <ManualTrades />
                  </Layout>
                }
              />
              <Route
                path="/daily-journal"
                element={
                  <Layout>
                    <DailyJournal />
                  </Layout>
                }
              />
              <Route
                path="/profile"
                element={
                  <Layout>
                    <ProfileSettings />
                  </Layout>
                }
              />
              <Route
                path="/notebook"
                element={
                  <Layout>
                    <Notebook />
                  </Layout>
                }
              />
              <Route
                path="/trade-logs"
                element={
                  <Layout>
                    <TradeLog />
                  </Layout>
                }
              />
              <Route
                path="/reports"
                element={
                  <Layout>
                    <NewReports />
                  </Layout>
                }
              />
              <Route
                path="/setup-management"
                element={
                  <Layout>
                    <SetupManagement />
                  </Layout>
                }
              />
              <Route
                path="/trade-details"
                element={
                  <Layout>
                    <TradeReplay />
                  </Layout>
                }
              />
              <Route
                path="/new-reports"
                element={
                  <Layout>
                    <NewReports />
                  </Layout>
                }
              />
              {check && (
                <Route
                  path="/insights"
                  element={
                    <Layout>
                      <Insights />
                    </Layout>
                  }
                />
              )}
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
