import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

/* SAMPLE DATA */
import moment from "moment";

const NetDailyGraph = (props) => {
  const categories = props?.profitLoss?.map((item) =>
    moment(item.date).format("DD MMM YY")
  );
  const [state] = useState({
    series: [
      {
        name: "Net daily P&L",
        data: props?.profitLoss?.map((res) => res?.profit),
      },
    ],
    options: {
      xaxis: {
        categories: categories,
        // type: "datetime",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      chart: {
        type: "bar",
        fontFamily: "ProximaNova-Semibold",
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const NetDailyGraphDark = (props) => {
  const categories = props?.profitLoss?.map((item) =>
    moment(item.date).format("DD MMM YY")
  );
  const [state] = useState({
    series: [
      {
        name: "Net daily P&L",
        data: props?.profitLoss?.map((res) => res?.profit),
      },
    ],
    options: {
      xaxis: {
        categories: categories,
        // type: "datetime",
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      chart: {
        type: "bar",
        fontFamily: "ProximaNova-Semibold",
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

export { NetDailyGraph, NetDailyGraphDark };
