/* REACT IMPORTS */
import React from "react";
import ReactDOM from "react-dom/client";

/* MATERIAL TAILWIND THEMEPROVIDER */
import { ThemeProvider } from "@material-tailwind/react";

/* MAIN APPLICATION */
import Routes from "./routes/routes";

/* PWA IMPORTS */
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
