/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

/* THIRD PARTY COMPONENTS */
import { Button, Spinner } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { emailVerify, register, sendOtp } from "../../service/api";

/* ICONS */
import { IoMdEyeOff, IoMdEye } from "react-icons/io";

/* DATA */
import brokers from "../../data/brokers";
import actions from "../../redux/user/actions";

/* ASSETS */
import Logo from "../../assets/images/logo-light.png";
import OTPInput from "react-otp-input";

const Input = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      className="mt-1 w-full block border border-borderGrey rounded-md text-base font-proxima-semibold px-4 py-2 placeholder:font-proxima-regular placeholder:text-sm focus:outline-none"
    />
  );
};

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* FORM VARIABLES */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const active = {
    REGISTER: "REGISTER",
    SEND_OTP: "SEND_OTP",
  };
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [tab, setTab] = useState(active.REGISTER);
  const [OTP, setOTP] = useState();

  /* STEP VARIABLES */
  const [brokerStep, setBrokerStep] = useState(false);
  const [brokerSelected, setBrokerSelected] = useState({ name: "" });

  /* VALIDATION DATA */
  const validation = () => {
    // if (username === "") {
    //   return toast.warning("Please enter username");
    // }
    if (email === "") {
      return toast.warning("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return toast.warning("Please enter a valid email");
    }
    if (password === "") {
      return toast.warning("Please enter password");
    } else if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/i.test(
        password
      )
    ) {
      return toast.warning(
        "Password must contain minimum eight characters, at least one letter, one special character and one number."
      );
    }
    setBrokerStep(!brokerStep);
  };

  /* REGISTER API */
  const handleRegister = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    // formData.append("userName", username);
    formData.append("password", password);

    register(formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          setUser(res?.data?.userData);
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));
          setTab(active.SEND_OTP);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOtp = () => {
    if (email === "") {
      return toast.warning("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return toast.warning("Please enter a valid email");
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);

    sendOtp(formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          setTab(active.SEND_OTP);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerify = () => {
    if (!OTP) {
      return toast.warning("Please enter OTP");
    } else if (OTP.length !== 6) {
      return toast.warning("Please enter a valid OTP");
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("code", OTP);
    emailVerify(formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);

          dispatch(actions.setLoggedIn(true));
          // setUsername("");
          setEmail("");
          setPassword("");
          setFirstName("");
          setLastName("");
          setLoading(false);
          if (res?.data?.userData?.isSubscribed) {
            navigate("/dashboard");
          } else {
            navigate("/profile#subscription");
          }
          // setTab(active.CHANGE_PASSWORD);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="bg-white">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Create new account | Trade Talez</title>
      </Helmet>

      <div className="container-full-width px-2 xl:px-0 lg:px-0">
        {/* FORM SECTION */}
        <div className="h-authSMHeight xl:h-authHeight lg:h-authHeight flex items-center justify-center">
          <div className="w-full xl:w-[30%] lg:w-[30%] mx-auto">
            <div className="bg-white border border-borderGrey p-5 xl:p-10 lg:p-10 rounded-lg shadow-lg">
              <img
                src={Logo}
                className="h-auto w-[16vh] object-cover"
                alt="Trade Talez"
              />
              <div className="mt-8">
                {/* HEADING */}
                <div className="p-0">
                  <h1 className="text-3xl font-proxima-extrabold text-black tracking-tight leading-none">
                    Get started with your account
                  </h1>
                  <h2 className="text-base font-proxima-regular text-greyText mt-3">
                    Create your account to log your trades and gain a
                    comprehensive view of your trades, strategies and portfolio.
                  </h2>
                </div>

                {/* STEP 01 */}
                <div className={`${brokerStep ? "hidden" : "block"}`}>
                  <form className="mt-5">
                    <div className="flex items-center gap-5">
                      <div className="form-group w-full">
                        <label
                          htmlFor="regFirstName"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          First name <span className="text-red-500">*</span>
                        </label>
                        <Input
                          type="text"
                          name="regFirstName"
                          id="regFirstName"
                          placeholder="Type your first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label
                          htmlFor="regLastName"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          Last name <span className="text-red-500">*</span>
                        </label>
                        <Input
                          type="text"
                          name="regLastName"
                          id="regLastName"
                          placeholder="Type your last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="form-group mt-5">
                      <label
                        htmlFor="username"
                        className="text-sm font-proxima-semibold text-black"
                      >
                        Username <span className="text-red-500">*</span>
                      </label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Type your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div> */}
                    <div className="form-group mt-5">
                      <label
                        htmlFor="regEmail"
                        className="text-sm font-proxima-semibold text-black"
                      >
                        Email address <span className="text-red-500">*</span>
                      </label>
                      <Input
                        type="email"
                        name="regEmail"
                        id="regEmail"
                        placeholder="Type your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-5">
                      <label
                        htmlFor="loginPassword"
                        className="text-sm font-proxima-semibold text-black"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <Input
                          type={passwordToggle ? "text" : "password"}
                          name="loginPassword"
                          id="loginPassword"
                          placeholder="Type your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                          className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                          onClick={() => setPasswordToggle(!passwordToggle)}
                        >
                          {passwordToggle ? (
                            <IoMdEyeOff className="w-5 h-5 text-greyText" />
                          ) : (
                            <IoMdEye className="w-5 h-5 text-greyText" />
                          )}
                        </Button>
                      </div>
                    </div>
                    <Button
                      className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                      onClick={validation}
                    >
                      Next
                    </Button>
                  </form>

                  {/* LOGIN OPTION */}
                  <div className="mt-5 flex items-center justify-center gap-1">
                    <h2 className="text-sm font-proxima-regular text-black">
                      Already have an account ?
                    </h2>
                    <Link
                      to="/"
                      className="block text-sm font-proxima-semibold text-buttonColor"
                    >
                      Sign in now
                    </Link>
                  </div>
                </div>

                {/* STEP 02 */}
                {tab === active.SEND_OTP ? (
                  <form className="mt-8 ">
                    <div className="form-group">
                      <label
                        htmlFor="verificationCode"
                        className="text-sm font-proxima-semibold text-black"
                      >
                        Verification code{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <OTPInput
                          numInputs={6}
                          value={OTP}
                          onChange={(e) => setOTP(e)}
                          renderSeparator={
                            <span className="text-greyText">-</span>
                          }
                          renderInput={(props) => <input {...props} />}
                          inputStyle="!w-12 h-12 rounded-md border border-[#E5E5E5] outline-none focus:outline-none text-black font-proxima-semibold"
                          containerStyle="gap-2"
                          className="mt-1"
                        />
                      </div>
                      <Button
                        onClick={handleVerify}
                        disabled={loading}
                        className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                      >
                        {loading ? (
                          <div className="flex center justify-center">
                            <Spinner className="h-full" />
                          </div>
                        ) : (
                          " Submit"
                        )}
                      </Button>
                      <div className="mt-5">
                        <Link
                          // to="/"
                          onClick={handleOtp}
                          disabled={loading}
                          className="block text-sm font-proxima-semibold text-buttonColor text-center"
                        >
                          Resend code
                        </Link>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className={`${brokerStep ? "block" : "hidden"}`}>
                    <div className="mt-5">
                      <p className="text-sm font-proxima-semibold text-black">
                        Choose your primary broker{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <div className="mt-3 grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 gap-3">
                        {brokers.map((broker) => (
                          <div className="col-span-1" key={broker.id}>
                            <div
                              className={`w-full h-brokerBoxMobile xl:h-brokerBox lg:h-brokerBox border border-borderGrey rounded-md flex items-center justify-center cursor-pointer ${
                                brokerSelected.name === broker.name
                                  ? "bg-buttonColor bg-opacity-5 border-buttonColor"
                                  : "bg-transparent hover:bg-black hover:bg-opacity-5"
                              }`}
                              onClick={() =>
                                setBrokerSelected({ name: broker.name })
                              }
                            >
                              <div>
                                <img
                                  src={broker.logo}
                                  className="w-12 h-12 mx-auto rounded"
                                  alt={broker.name}
                                />
                                <p className="text-base font-proxima-semibold text-black text-center mt-2">
                                  {broker.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Button
                        onClick={handleRegister}
                        disabled={loading}
                        className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                      >
                        {loading ? (
                          <div className="flex center justify-center">
                            <Spinner className="h-full" />
                          </div>
                        ) : (
                          " Proceed"
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className="border-t border-borderGrey py-5">
          <div className="container">
            <div className="block xl:flex lg:flex items-center justify-between">
              <p className="text-sm font-proxima-regular text-greyText text-center">
                Copyright © 2024 TradeTalez. A product by{" "}
                <span className="text-black font-proxima-semibold">
                  Tactical Trading Technologies LLP
                </span>
                . Powered by{" "}
                <a
                  href="https://www.roundtechsquare.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 font-proxima-semibold"
                >
                  RoundTechSquare
                </a>
              </p>
              <p className="text-sm font-proxima-regular text-black text-center">
                Charts powered by{" "}
                <a
                  href="https://www.tradingview.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline font-proxima-semibold"
                >
                  TradingView
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
