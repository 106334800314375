/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/user/actions";

/* ICONS */
import { IoWallet } from "react-icons/io5";
import {
  Button,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import Input from "../../../../components/design/input";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { toast } from "react-toastify";
import { addManualTrade } from "../../../../service/api";

/* SYMBOL OPTIONS */
const symbolOptionns = [{ value: "BANKNIFTY", label: "BANKNIFTY" }];
const tickerOptions = [
  { value: "BANKNIFTY24MARFUT", label: "BANKNIFTY24MARFUT" },
];
const typeOptionns = [
  { value: "BUY", label: "BUY" },
  { value: "SELL", label: "SELL" },
];
const exchangeOptionns = [
  { value: "NSE", label: "NSE" },
  { value: "BSE", label: "BSE" },
  { value: "NFO", label: "NFO" },
];
const instrumentOptionns = [
  { value: "FUTURE", label: "FUTURE" },
  { value: "OPTIONS", label: "OPTIONS" },
  { value: "EQUITY", label: "EQUITY" },
  { value: "FNO", label: "FNO" },
];
const productOptionns = [
  { value: "MIS", label: "MIS" },
  { value: "NRML", label: "NRML" },
  { value: "CNC", label: "CNC" },
  { value: "FO", label: "FO" },
  { value: "MARGIN", label: "MARGIN" },
];

const ManualTrades = () => {
  /* REDUX STATES */
  const dispatch = useDispatch();
  const { exchanges } = useSelector((state) => state?.user);

  /* STATE VARIABLES */
  const [activeTab, setActiveTab] = useState("Stock");
  const [exchange, setExchange] = useState({
    label: "All accounts",
    value: "All accounts",
  });
  const [accountOptions, setAccountOptions] = useState([
    { label: "All accounts", value: "All accounts" },
  ]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    exchangeId: "",
    tradingSymbol: "",
    tickerName: "",
    transactionType: "",
    price: "",
    quantity: "",
    dateTime: "",
    tradeId: "",
    exchange: "",
    instrumentType: "",
    product: "",
  });

  /* USE EFFECT */
  useEffect(() => {
    if (exchange && exchange?.value !== "All accounts") {
      dispatch(
        actions.setSelectedExchange(
          exchanges?.filter((obj) => obj._id === exchange.value)[0]
        )
      );
    } else {
      dispatch(actions.setSelectedExchange(null));
    }
  }, [exchange]);

  /* USE EFFECT */
  useEffect(() => {
    if (exchanges?.length !== 0) {
      let data = [{ label: "All accounts", value: "All accounts" }];
      exchanges?.map((obj) => {
        data.push({
          label: obj?.exchange + " " + (obj?.accountId ? obj?.accountId : ""),
          value: obj?._id,
        });
      });

      setAccountOptions(data);
    }
  }, [exchanges?.length]);

  const handleSelect = (e) => {
    setPayload({
      ...payload,
      dateTime: e,
    });
  };

  const handleTrade = () => {
    if (payload?.exchangeId === null || payload?.exchangeId === "") {
      toast.error("Please select account");
      return;
    }
    if (payload?.tradingSymbol === null || payload?.tradingSymbol === "") {
      toast.error("Please select symbol");
      return;
    }
    if (payload?.tickerName === null || payload?.tickerName === "") {
      toast.error("Please select ticker name");
      return;
    }
    if (payload?.transactionType === null || payload?.transactionType === "") {
      toast.error("Please select type");
      return;
    }
    if (payload?.price === null || payload?.price === "") {
      toast.error("Please enter price");
      return;
    }
    if (payload?.quantity === null || payload?.quantity === "") {
      toast.error("Please enter quantity");
      return;
    }
    if (payload?.dateTime === null || payload?.dateTime === "") {
      toast.error("Please select date");
      return;
    }
    if (payload?.exchange === null || payload?.exchange === "") {
      toast.error("Please select exchange");
      return;
    }
    if (payload?.instrumentType === null || payload?.instrumentType === "") {
      toast.error("Please select insrtument");
      return;
    }
    if (payload?.product === null || payload?.product === "") {
      toast.error("Please select product");
      return;
    }
    if (payload?.tradeId === null || payload?.tradeId === "") {
      toast.error("Please enter trade id");
      return;
    }

    setLoading(true);
    let body = {
      exchangeId: payload?.exchangeId,
      tickerName: payload?.tickerName,
      tradingSymbol: payload?.tradingSymbol,
      transactionType: payload?.transactionType?.value,
      price: payload?.price,
      quantity: payload?.quantity,
      dateTime: moment(payload?.dateTime).format("DD/MM/YYYY HH:MM:SS"),
      tradeId: payload?.tradeId,
      exchange: payload?.exchange?.value,
      instrumentType: payload?.instrumentType?.value,
      product: payload?.product?.value,
    };

    addManualTrade(body)
      .then((res) => {
        if (res?.status) {
          setPayload({
            exchangeId: "",
            tradingSymbol: "",
            tickerName: "",
            transactionType: "",
            price: "",
            quantity: "",
            dateTime: "",
            tradeId: "",
            exchange: "",
            instrumentType: "",
            product: "",
          });
          toast.success(res?.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.message);
        setLoading(false);
      });
  };
  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Manual Trades</title>
      </Helmet>

      <div className="container-full-width">
        {/* MANUAL TRADES CARD */}
        <div className="w-full xl:w-3/5 lg:w-3/5 mx-auto">
          <h1 className="text-2xl font-proxima-bold text-black dark:text-white tracking-tight">
            Manual trades
          </h1>
          <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText">
            Use your account timezone (GMT+5:30 - Chennai, Kolkata, Mumbai, New
            Delhi)
          </p>

          {/* FORM */}
          <form className="mt-5 xl:mt-10 lg:mt-10">
            {/* ACCOUNT SELECTION */}
            <div className="form-group">
              <label className="text-sm font-proxima-semibold text-black dark:text-white">
                Account <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 w-[30vh] h-12 flex items-center gap-3 bg-white dark:bg-transparent border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4">
                <IoWallet className="w-5 h-5 text-[#C9C8C8] flex-shrink-0" />
                <div className="block dark:hidden w-full">
                  <Select
                    value={exchange}
                    options={accountOptions}
                    className="react-select font-proxima-semibold cursor-pointer"
                    classNamePrefix="account"
                    placeholder="Select accounts"
                    onChange={(e) => {
                      setExchange(e);
                      setPayload({ ...payload, exchangeId: e.value });
                    }}
                  />
                </div>
                <div className="hidden dark:block w-full">
                  <Select
                    value={exchange}
                    options={accountOptions}
                    className="react-select dark font-proxima-semibold cursor-pointer"
                    classNamePrefix="account"
                    placeholder="Select accounts"
                    onChange={(e) => {
                      setExchange(e);
                      setPayload({ ...payload, exchangeId: e.value });
                    }}
                  />
                </div>
              </div>
            </div>

            {/* TYPE SELECTION */}
            <div className="mt-5 w-full rounded-lg bg-white dark:bg-darkSidebar border border-borderGrey dark:border-darkBorder tabs-show">
              <div className="p-5 rounded-b-lg">
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Symbol <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="symbol"
                          id="symbol"
                          placeholder="BANKNIFTY"
                          value={payload?.tradingSymbol}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              tradingSymbol: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {/* <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={symbolOptionns}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select symbol"
                            value={payload?.tradingSymbol}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                tradingSymbol: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={symbolOptionns}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select symbol"
                            value={payload?.tradingSymbol}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                tradingSymbol: e,
                              });
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Ticker Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="tname"
                          id="tname"
                          placeholder="BANKNIFTY24MARFUT"
                          value={payload?.tickerName}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              tickerName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {/* <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={tickerOptions}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select symbol"
                            value={payload?.tickerName}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                tickerName: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={tickerOptions}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select symbol"
                            value={payload?.tickerName}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                tickerName: e,
                              });
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Date <span className="text-red-500">*</span>
                      </label>
                      <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="mt-1">
                          <div className="block dark:hidden">
                            <DateTime
                              className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker"
                              value={
                                payload?.dateTime && moment(payload?.dateTime)
                              }
                              onChange={handleSelect}
                              // timeFormat
                              // initialViewMode={''}
                            />
                          </div>
                          <div className="hidden dark:block">
                            <DateTime
                              className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker dark"
                              value={
                                payload?.dateTime && moment(payload?.dateTime)
                              }
                              onChange={handleSelect}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Type <span className="text-red-500">*</span>
                      </label>
                      <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={typeOptionns}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Type"
                            value={payload?.transactionType}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                transactionType: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={typeOptionns}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Type"
                            value={payload?.transactionType}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                transactionType: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Exchange <span className="text-red-500">*</span>
                      </label>
                      <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={exchangeOptionns}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.exchange}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                exchange: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={exchangeOptionns}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.exchange}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                exchange: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Instrument <span className="text-red-500">*</span>
                      </label>
                      <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={instrumentOptionns}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.instrumentType}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                instrumentType: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={instrumentOptionns}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.instrumentType}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                instrumentType: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Product <span className="text-red-500">*</span>
                      </label>
                      <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                        <div className="block dark:hidden w-full">
                          <Select
                            options={productOptionns}
                            className="react-select upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.product}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                product: e,
                              });
                            }}
                          />
                        </div>
                        <div className="hidden dark:block w-full">
                          <Select
                            options={productOptionns}
                            className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                            classNamePrefix="account"
                            placeholder="Select Exchange"
                            value={payload?.product}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                product: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Price <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="number"
                          name="price"
                          id="price"
                          placeholder="Type your price"
                          value={payload?.price}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              price: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Quantity <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="number"
                          name="quantity"
                          id="quantity"
                          placeholder="Type your quantity"
                          value={payload?.quantity}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              quantity: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black dark:text-white">
                        Trade Id <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="tradeId"
                          id="tradeId"
                          placeholder="Type your Trade ID"
                          value={payload?.tradeId}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              tradeId: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs value={activeTab} style={{ display: "none" }}>
                <TabsHeader
                  className="rounded-none border-b border-borderGrey dark:border-darkBorder p-00 bg-transparent"
                  indicatorProps={{
                    className:
                      "bg-transparent border-b-2 border-logoOcre shadow-none rounded-none",
                  }}
                >
                  <Tab
                    key="Stock"
                    value="Stock"
                    onClick={() => setActiveTab("Stock")}
                    className={`${
                      activeTab === "Stock"
                        ? "text-black dark:text-white font-proxima-semibold"
                        : "text-greyText dark:text-lightGreyText font-proxima-regular"
                    } text-sm w-[10vh] px-5 py-3`}
                  >
                    Stock
                  </Tab>
                  <Tab
                    key="Option"
                    value="Option"
                    onClick={() => setActiveTab("Option")}
                    className={`${
                      activeTab === "Option"
                        ? "text-black dark:text-white font-proxima-semibold"
                        : "text-greyText dark:text-lightGreyText font-proxima-regular"
                    } text-sm w-[10vh] px-5 py-3`}
                  >
                    Option
                  </Tab>
                  <Tab
                    key="Future"
                    value="Future"
                    onClick={() => setActiveTab("Future")}
                    className={`${
                      activeTab === "Future"
                        ? "text-black dark:text-white font-proxima-semibold"
                        : "text-greyText dark:text-lightGreyText font-proxima-regular"
                    }  text-sm w-[10vh] px-5 py-3`}
                  >
                    Future
                  </Tab>
                  <Tab
                    key="Future-Option"
                    value="Future-Option"
                    onClick={() => setActiveTab("Future-Option")}
                    className={`${
                      activeTab === "Future-Option"
                        ? "text-black dark:text-white font-proxima-semibold"
                        : "text-greyText dark:text-lightGreyText font-proxima-regular"
                    }  text-sm w-[15vh] px-5 py-3`}
                  >
                    Future Option
                  </Tab>
                </TabsHeader>
                <TabsBody className="p-0 bg-white dark:bg-darkSidebar rounded-b-lg overflow-visible">
                  {/* STOCK */}
                  <TabPanel
                    key="Stock"
                    value="Stock"
                    className="p-5 rounded-b-lg"
                  >
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Symbol <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                                value={payload?.tradingSymbol}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    tradingSymbol: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                                value={payload?.tradingSymbol}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    tradingSymbol: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Ticker Name <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={tickerOptions}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                                value={payload?.tickerName}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    tickerName: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={tickerOptions}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                                value={payload?.tickerName}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    tickerName: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Date <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="mt-1">
                              <div className="block dark:hidden">
                                <DateTime
                                  className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker"
                                  value={payload?.dateTime}
                                  onChange={handleSelect}
                                />
                              </div>
                              <div className="hidden dark:block">
                                <DateTime
                                  className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker dark"
                                  value={payload?.dateTime}
                                  onChange={handleSelect}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Type <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={typeOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Type"
                                value={payload?.transactionType}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    transactionType: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={typeOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Type"
                                value={payload?.transactionType}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    transactionType: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Exchange <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={exchangeOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.exchange}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    exchange: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={exchangeOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.exchange}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    exchange: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Instrument <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={instrumentOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.instrumentType}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    instrumentType: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={instrumentOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.instrumentType}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    instrumentType: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Product <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={productOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.product}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    product: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={productOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select Exchange"
                                value={payload?.product}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    product: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Price <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="number"
                              name="price"
                              id="price"
                              placeholder="Type your price"
                              value={payload?.price}
                              onChange={(e) => {
                                setPayload({
                                  ...payload,
                                  price: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              placeholder="Type your quantity"
                              value={payload?.quantity}
                              onChange={(e) => {
                                setPayload({
                                  ...payload,
                                  quantity: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Trade Id <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="tradeId"
                              id="tradeId"
                              placeholder="Type your Trade ID"
                              value={payload?.tradeId}
                              onChange={(e) => {
                                setPayload({
                                  ...payload,
                                  tradeId: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/* OPTIONS */}
                  <TabPanel
                    key="Option"
                    value="Option"
                    className="p-5 rounded-b-lg"
                  >
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Symbol <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              placeholder="Type your quantity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/* FUTURE */}
                  <TabPanel
                    key="Future"
                    value="Future"
                    className="p-5 rounded-b-lg"
                  >
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Symbol <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              placeholder="Type your quantity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/* FUTURE OPTION */}
                  <TabPanel
                    key="Future-Option"
                    value="Future-Option"
                    className="p-5 rounded-b-lg"
                  >
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Symbol <span className="text-red-500">*</span>
                          </label>
                          <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                            <div className="block dark:hidden w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                            <div className="hidden dark:block w-full">
                              <Select
                                options={symbolOptionns}
                                className="react-select dark upload-select font-proxima-semibold cursor-pointer"
                                classNamePrefix="account"
                                placeholder="Select symbol"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black dark:text-white">
                            Quantity <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              placeholder="Type your quantity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </div>

            {/* SAVE BUTTON */}
            <div className="flex items-center gap-2 justify-end mt-5">
              <Button
                onClick={handleTrade}
                disabled={loading}
                className="shadow-none hover:shadow-none bg-buttonColor text-sm font-proxima-semibold text-white py-2 normal-case"
              >
                {loading ? <Spinner className="h-[15px]" /> : "Save trade"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ManualTrades;
