/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Card,
  CardBody,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Dialog,
  DialogBody,
  Button,
} from "@material-tailwind/react";

/* SAMPLE DATA */
import { daySummary } from "../../../data/reports";
import { FaTimes, FaExpand, FaInfoCircle } from "react-icons/fa";

/* CHARTS */
import {
  CumulativeGraph,
  CumulativeGraphDark,
} from "../../../components/others/charts/cumulative-graph";
import {
  NetDailyGraph,
  NetDailyGraphDark,
} from "../../../components/others/charts/net-daily-graph";
import {
  PerformanceByDay,
  TradeDistributionDay,
  TradeDistributionWeek,
  PerformanceByWeek,
  TradeDistributionDayDark,
  PerformanceByDayDark,
  TradeDistributionWeekDark,
  PerformanceByWeekDark,
  TradeDistributionTradeTime,
  TradeDistributionTradeTImeDark,
  PerformanceByTradeTime,
  PerformanceByTradeTimeDark,
  PerformanceByTradeDuration,
  PerformanceByTradeDurationDark,
  TradeDistributionTradeDuration,
  TradeDistributionTradeDurationDark,
  PerformanceByTradeSetupDark,
  PerformanceByTradeSetup,
  TradeDistributionTradeSetup,
  TradeDistributionTradeSetupDark,
  PerformanceByTradeMistakeDark,
  PerformanceByTradeMistake,
  TradeDistributionTradeMistakeDark,
  TradeDistributionTradeMistake,
  TradeDistributionTradeRR,
  TradeDistributionTradeRRDark,
  PerformanceByTradeRR,
  PerformanceByTradeRRDark,
  PerformanceByWeekExpand,
  PerformanceByWeekDarkExpand,
  TradeDistributionWeekDarkExpand,
  TradeDistributionWeekExpand,
  PerformanceByTradeTimeExpand,
  PerformanceByTradeTimeDarkExpand,
  TradeDistributionTradeTimeExpand,
  TradeDistributionTradeTImeDarkExpand,
  CommissionByProfit,
  CommissionByProfitDark,
  CommissionByMonth,
  CommissionByMonthDark,
  TradeDistributionTradeCRR,
  TradeDistributionTradeCRRDark,
  PerformanceByTradeCRR,
  PerformanceByTradeCRRDark,
  TradeCRRDark,
  TradeCRR,
} from "../../../components/others/charts/trade-distribution";

/* APIS */
import {
  getDashboard,
  getByMonth,
  getByDay,
  getTradeByDayData,
  getTradeByWeek,
  getTradeByTradeTime,
  getTradeByTradeDuration,
  getTradeByTradeSetup,
  getTradeByTradeMistake,
  getTradeByTradeRR,
  getTradeByTradeRRCompare,
  getCommissionReport,
} from "../../../service/api";
import { Tooltip } from "react-tooltip";

/* TABS */
const dateTimeTabs = [
  {
    id: 1,
    value: "Days",
  },
  {
    id: 2,
    value: "Weeks",
  },
  {
    id: 3,
    value: "Trade Time",
  },
  {
    id: 4,
    value: "Trade Duration",
  },
];

/* TAGS */
const tags = [
  {
    id: 1,
    value: "Setups",
  },
  {
    id: 2,
    value: "Mistakes",
  },
];

/* RISK */
const risks = [
  {
    id: 1,
    value: "Risk Reward",
  },
  {
    id: 2,
    value: "Compare RR",
  },
];
/* COMMISSION */
const commissions = [
  {
    id: 1,
    value: "Monthly Commissions",
  },
];
const NewReports = () => {
  const [graphData, setGraphData] = useState();
  const [loading, setLoading1] = useState(false);
  const [loading1, setLoading] = useState(false);
  const { startDate, endDate, selectedExchange } = useSelector(
    (state) => state.user
  );
  const [monthData, setMonthData] = useState([]);
  const [daysData, setDaysData] = useState([]);
  const [dayData, setDayData] = useState([]);
  const [performanceByDay, setPerformanceByDay] = useState([]);
  const [weeksData, setWeeksData] = useState([]);
  const [tradeTimeData, setTradeTimeData] = useState([]);
  const [tradeDurationData, setTradeDurationData] = useState([]);
  const [tradeSetupData, setTradeSetupData] = useState([]);
  const [tradeMistakeData, setTradeMistakeData] = useState([]);
  const [tradeRRData, setTradeRRData] = useState([]);
  const [compareRRData, setCompareRRData] = useState([]);
  const [commissionData, setCommissionData] = useState([]);
  /* DARK MODE */
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "false"
      ? false
      : localStorage.getItem("darkMode") === "true"
      ? true
      : false
  );

  const [duration, setDuration] = useState({ value: 30, label: "30 Minutes" });

  /* GRAPH DATA */
  const handleData = () => {
    setLoading(false);
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getDashboard(params)
      .then((res) => {
        if (res.status) {
          setGraphData(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  let xyz = graphData?.map((res) => ({
    date: `${res?.month}/${res?.day}/${res?.year}`,
    profit:
      (res?.trade?.map((res) => res?.profit)?.length !== 0
        ? res?.trade
            ?.map((res) => (res?.profit ? res?.profit : 0))
            ?.reduce((acc, crr) => acc + +crr)
            .toFixed(3)
        : 0) -
      (res?.notebookData && res?.notebookData?.exchangeFee
        ? res?.notebookData.exchangeFee
        : 0),
  }));

  let profitLoss = xyz
    ? xyz.sort((a, b) => new Date(a.date) - new Date(b.date))
    : [];

  /* CUMULATIVE PROFIT */
  const calculateCumulativeProfit = () => {
    let cumulativeProfit = 0;
    const transactionsWithCumulativePL = profitLoss.map((transaction) => {
      cumulativeProfit += parseFloat(transaction.profit);

      return { ...transaction, cumulativeProfit };
    });
    return transactionsWithCumulativePL;
  };

  let abc =
    monthData &&
    monthData?.map((res) => ({
      month: res?.month,
      profit:
        (res?.trade?.map((res) => res?.profit)?.length !== 0
          ? res?.trade
              ?.map((res) => (res?.profit ? res.profit : 0))
              ?.reduce((acc, crr) => acc + +crr)
              ?.toFixed(3)
          : 0) - (res?.commission ? Number(res?.commission) : 0),
    }));

  let bestMonth = abc
    ? abc.sort((a, b) => (b.profit ? b.profit : 0) - (a.profit ? a.profit : 0))
    : [];

  /* TOTAL P AND L */
  let total =
    bestMonth?.length !== 0
      ? bestMonth
          ?.map((res) => (res?.profit ? res?.profit : 0))
          ?.reduce((acc, crr) => acc + +crr, 0)
          ?.toFixed(3)
      : 0;

  /* AVERGAE WINNING TRADE */
  let avgWinData = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "WIN")
    ?.reduce((acc, crr) => acc + +crr?.profit, 0)
    ?.toFixed(3);

  /* WIN VOLUME */
  let winVolume = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "WIN")?.length;
  let avgWin = (avgWinData / winVolume)?.toFixed(3);

  /* AVERAGE LOSING TRADE */
  let avgLossData = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "LOSS")
    ?.reduce((acc, crr) => acc + +crr?.profit, 0)
    ?.toFixed(3);

  /* LOSS VOLUME */
  let lossVolume = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "LOSS")?.length;
  let avgLoss = (avgLossData / lossVolume)?.toFixed(3);

  /* TOTAL TRADES */
  let totalTrade = monthData?.map((res) => res?.trade)?.flat()?.length;

  /* BREAK EVEN */
  let breakEven = monthData?.filter((res) => res?.win === res?.loss)?.length;

  /* LARGE LOSS */
  let largeLoss = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "LOSS")
    ?.sort((a, b) => a.profit - b.profit);

  /* LARGE WIN */
  let largeWin = monthData
    ?.map((res) => res?.trade)
    ?.flat()
    ?.filter((res) => res?.type === "WIN")
    ?.sort((a, b) => b.profit - a.profit);
  // Expectancy winrate
  let expectancy =
    (avgWin * ((winVolume * 100) / totalTrade) +
      avgLoss * ((lossVolume * 100) / totalTrade)) /
    100;
  /* GET MONTH */
  const getMonth = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);

    getByMonth(params)
      .then((res) => {
        setLoading(false);

        if (res.status) {
          setMonthData(res?.data);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* GET DAY */
  const getDay = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);

    getByDay(params)
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  const getTradeDataByDay = () => {
    let params = { startDate: startDate, endDate: endDate };

    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setDaysData([]);
    setPerformanceByDay([]);
    getTradeByDayData(params).then((res) => {
      if (res?.data) {
        setDayData(res?.data);
        let data = [0, 0, 0, 0, 0, 0, 0];
        let data2 = [0, 0, 0, 0, 0, 0, 0];
        res?.data?.map((obj) => {
          if (obj?.day === "Sunday") {
            data[0] = Number(obj?.total);
            data2[0] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Monday") {
            data[1] = Number(obj?.total);
            data2[1] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Tuesday") {
            data[2] = Number(obj?.total);
            data2[2] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Wednesday") {
            data[3] = Number(obj?.total);
            data2[3] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Thursday") {
            data[4] = Number(obj?.total);
            data2[4] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Friday") {
            data[5] = Number(obj?.total);
            data2[5] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          } else if (obj?.day === "Saturday") {
            data[5] = Number(obj?.total);
            data2[5] = parseFloat(
              obj?.trade
                ?.map((res) => (res?.profit ? res?.profit : 0))
                ?.reduce((acc, crr) => acc + +crr)
                .toFixed(3)
            )
              .toFixed(2)
              .toLocaleString("en-IN");
          }
        });
        setDaysData(data);

        setPerformanceByDay(data2);
      }
      setLoading(false);
    });
  };

  const getTradeDataByWeek = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setWeeksData([]);
    getTradeByWeek(params).then((res) => {
      setLoading(false);
      if (res?.data) {
        setWeeksData(res?.data);
      }
    });
  };

  const getTradeDataByTradeTime = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    params.duration = duration?.value;
    setLoading(true);
    setTradeTimeData([]);
    getTradeByTradeTime(params).then((res) => {
      setLoading(false);

      if (res?.data) {
        setTradeTimeData(res?.data);
      }
    });
  };

  const getTradeDataByTradeDuration = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setTradeDurationData([]);
    getTradeByTradeDuration(params).then((res) => {
      setLoading(false);

      if (res?.data) {
        setTradeDurationData(res?.data);
      }
    });
  };
  const getTradeDataByTradeSetup = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setTradeSetupData([]);
    getTradeByTradeSetup(params).then((res) => {
      setLoading(false);
      if (res?.data) {
        setTradeSetupData(res?.data);
      }
    });
  };
  const getTradeDataByTradeMistake = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setTradeMistakeData([]);
    getTradeByTradeMistake(params).then((res) => {
      setLoading(false);
      if (res?.data) {
        setTradeMistakeData(res?.data);
      }
    });
  };
  const getTradeDataByTradeRR = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    getTradeByTradeRR([]);
    getTradeByTradeRR(params).then((res) => {
      setLoading(false);
      if (res?.data) {
        setTradeRRData(res?.data);
      }
    });
  };
  const getTradeDataByEECompare = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    setLoading(true);
    setCompareRRData([]);
    getTradeByTradeRRCompare(params).then((res) => {
      setLoading(false);

      if (res?.data) {
        setCompareRRData(res?.data);
      }
    });
  };
  const getCommissionReportData = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    params.feeType = "MONTH";
    setLoading(true);
    setCommissionData([]);
    getCommissionReport(params).then((res) => {
      setLoading(false);

      if (res?.data) {
        setCommissionData(res?.data);
      }
    });
  };

  /* USE EFFECT */
  useEffect(() => {
    getMonth();
    getDay();
    handleData();
    getTradeDataByDay();
    getTradeDataByWeek();
    getTradeDataByTradeTime();
    getTradeDataByTradeDuration();
    getTradeDataByTradeSetup();
    getTradeDataByTradeMistake();
    getTradeDataByTradeRR();
    getTradeDataByEECompare();
    getCommissionReportData();
  }, [selectedExchange, startDate, endDate]);
  useEffect(() => {
    // getTradeDataByTradeTime();
  }, [duration]);
  const profitLossData = calculateCumulativeProfit();

  /* ANALYTICS CARD */
  const AnalyticsCard = (props) => {
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              {props.analyticsLabel}
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              {props.analyticsSublabel}{" "}
              <span className="text-black dark:text-white font-proxima-bold">
                {props.month}
              </span>
            </p>
            {/* <Skeleton className="w-32 h-8 mt-5" /> */}
            <h2
              className={`text-3xl font-proxima-bold leading-none pt-5 ${
                parseFloat(props.analyticsValue) < 0 && "text-red-500"
              } text-green-500`}
            >
              ₹
              {props.analyticsValue
                ? parseFloat(
                    parseFloat(props.analyticsValue).toFixed(2)
                  ).toLocaleString("en-IN")
                : "0.00"}
            </h2>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* CUMULATIVE PROFIT AND LOSS */
  const CumulativeProfitLoss = (props) => {
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white leading-none">
              Daily net cumulative P&L
            </h2>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              {/* <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
              /> */}
              <CumulativeGraph profitLoss={profitLossData} height="h-[25vh]" />
            </div>
            <div className="hidden dark:block">
              {/* <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
              /> */}
              <CumulativeGraphDark
                profitLoss={profitLossData}
                height="h-[25vh]"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* NET DAILY PROFIT AND LOSS */
  const NetDailyProfitLoss = (props) => {
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white leading-none">
              Net daily P&L
            </h2>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              <NetDailyGraph profitLoss={profitLossData} height="h-[25vh]" />
            </div>
            <div className="hidden dark:block">
              <NetDailyGraphDark
                profitLoss={profitLossData}
                height="h-[25vh]"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* STATISTIC TILE */
  const StatisticTile = (props) => {
    return (
      <div className="w-full flex items-center justify-between rounded px-5 py-3 even:bg-white dark:even:bg-darkSidebar odd:bg-[#F5F5F5] dark:odd:bg-bgDark">
        <p className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText leading-none">
          {props.title}
        </p>
        {loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-4"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-4"
              />
            </div>
          </>
        ) : (
          <p className="text-base font-proxima-bold text-black dark:text-white leading-none">
            {props.value}
          </p>
        )}
      </div>
    );
  };

  /* OVERVIEW COLUMNS */
  const statsColOne = [
    {
      id: 1,
      title: "Total P&L",
      value:
        "₹" + parseFloat(parseFloat(total).toFixed(2)).toLocaleString("en-IN"),
    },
    {
      id: 3,
      title: "Average Winning Trade",
      value: isNaN(avgWin)
        ? "0"
        : `₹${parseFloat(parseFloat(avgWin).toFixed(2)).toLocaleString(
            "en-IN"
          )}`,
    },
    {
      id: 4,
      title: "Average Losing Trade",
      value: isNaN(avgLoss)
        ? "0"
        : `₹${parseFloat(parseFloat(avgLoss).toFixed(2)).toLocaleString(
            "en-IN"
          )}`,
    },
    {
      id: 5,
      title: "Total Number of Trades",
      value: totalTrade ? parseFloat(totalTrade) : 0,
    },
    {
      id: 6,
      title: "Number of Winning Trades",
      value: winVolume ? parseFloat(winVolume) : 0,
    },
    {
      id: 7,
      title: "Number of Losing Trades",
      value: lossVolume ? parseFloat(lossVolume) : 0,
    },
    {
      id: 8,
      title: "Number of Break Even Trades",
      value: breakEven ? parseFloat(breakEven) : 0,
    },
    {
      id: 8,
      title: "Trade Expectancy",
      value: expectancy
        ? parseFloat(expectancy?.toFixed(2)).toLocaleString("en-IN")
        : 0,
    },
    {
      id: 14,
      title: "Largest Profit",
      value: largeWin
        ? largeWin?.length !== 0
          ? parseFloat(largeWin[0]?.profit?.toFixed(2)).toLocaleString("en-IN")
          : "-"
        : 0,
    },
    {
      id: 15,
      title: "Largest Loss",
      value: largeLoss
        ? largeLoss?.length !== 0
          ? parseFloat(largeLoss[0]?.profit?.toFixed(2)).toLocaleString("en-IN")
          : "-"
        : 0,
    },
    {
      id: 23,
      title: "Total trading days",
      value: profitLossData?.length,
    },
    {
      id: 24,
      title: "Winning days",
      value:
        profitLossData?.length !== 0
          ? profitLossData?.filter((res) => res?.profit > 0)?.length
          : 0,
    },
    {
      id: 25,
      title: "Losing days",
      value:
        profitLossData?.length !== 0
          ? profitLossData?.filter((res) => res?.profit < 0)?.length
          : 0,
    },
    {
      id: 26,
      title: "Breakeven days",
      value:
        profitLossData?.length !== 0
          ? profitLossData?.filter((res) => res?.profit === 0)?.length
          : 0,
    },
  ];

  /* DAY SUMMARY COLUMNS */
  const daySummaryColumns = [
    {
      name: "Day",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.day}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(Number(row.profit)) < 0
              ? "text-red-500"
              : parseFloat(Number(row.profit)) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(Number(row.profit)).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(Number(row.profit) >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(
                  (Number(row.profit) >= 0 ? row?.profit : 0)?.toFixed(3)
                ) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(Number(row.profit) >= 0 ? row?.profit : 0)
            .toFixed(2)
            .toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(Number(row.profit) <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(Number(row.profit) <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(Number(row.profit) <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* WEEK SUMMARY COLUMNS */
  const weekSummaryColumns = [
    {
      name: "Week",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.week}
        </span>
      ),
    },
    {
      name: "Date Range",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.dateRange}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADETIME SUMMARY COLUMNS */
  const TradeSummaryColumns = [
    {
      name: "Time Range",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.customTimeRange}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADEDURATION SUMMARY COLUMNS */
  const TradeDurationColumns = [
    {
      name: "Trade Duration",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row._id}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADE SETUP SUMMARY COLUMNS */
  const TradeSetupColumns = [
    {
      name: "Setup",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.setUpTag?.name}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADE SETUP SUMMARY COLUMNS */
  const TradeMistakeColumns = [
    {
      name: "Mistake",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.mistakeTag?.name}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADE SETUP SUMMARY COLUMNS */
  const TradeRRColumns = [
    {
      name: "Risk Reward",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row._id}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    {
      name: "Winning %",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {((row?.win * 100) / row.total)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* COMPARE RR COLUMNS */
  const CompareRRColumns = [
    {
      name: "Average Expected RR",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.averageExpectedRisk}
        </span>
      ),
    },
    {
      name: "Average Realized RR",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.averageRealizedRisk}
        </span>
      ),
    },
    {
      name: "Net Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit) < 0
              ? "text-red-500"
              : parseFloat(row.profit) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(parseFloat(row.profit).toFixed(2)).toLocaleString(
            "en-IN"
          )}
        </span>
      ),
    },
    // {
    //   name: "Winning %",
    //   selector: (row) => (
    //     <span className="text-sm font-proxima-semibold text-black dark:text-white">
    //       {((row?.win * 100) / row.total)?.toFixed(2)}
    //     </span>
    //   ),
    // },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  /* TRADE SETUP SUMMARY COLUMNS */
  const CommissionColumns = [
    {
      name: "Month",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row._id?.month} - {row._id?.year}
        </span>
      ),
    },

    {
      name: "Commission",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {Number(row?.commission)?.toFixed(2)}
        </span>
      ),
    },
    {
      name: "Total Profits",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit >= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit >= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit >= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.profit <= 0 ? row?.profit : 0) < 0
              ? "text-red-500"
              : parseFloat(row.profit <= 0 ? row?.profit : 0) === 0
              ? "text-black dark:text-white"
              : "text-green-500"
          }`}
        >
          ₹
          {parseFloat(
            parseFloat(row.profit <= 0 ? row?.profit : 0).toFixed(2)
          ).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Trades",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.total}
        </span>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black dark:text-white">
          {row.quantity}
        </span>
      ),
    },
  ];

  const Loading = () => {
    return (
      <>
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
          <div className="col-span-1">
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-0">
                <div className="p-5">
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-1/2 h-8"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-1/2 h-8"
                    />
                  </div>
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-1/2 h-12"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-1/2 h-12"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="mt-5 bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-0">
                <div className="p-5">
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-1/2 h-8"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-1/2 h-8"
                    />
                  </div>
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-1/2 h-12"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-1/2 h-12"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-span-1">
            <Card className="h-full bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-0">
                <div className="p-5">
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-full h-[28vh]"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-full h-[28vh]"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-span-1">
            <Card className="h-full bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-0">
                <div className="p-5">
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="mt-3 w-full h-[28vh]"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="mt-3 w-full h-[28vh]"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <Card className="mt-5 w-full bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
          <CardBody className="p-0">
            <div className="p-5">
              <div className="block dark:hidden">
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-full h-[30vh]"
                />
              </div>
              <div className="hidden dark:block">
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-full h-[30vh]"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    );
  };

  const [expandPWeek, setExpandPWeek] = useState(false);
  const [expandDWeek, setExpandDWeek] = useState(false);

  const [expandPTime, setExpandPTime] = useState(false);
  const [expandDTime, setExpandDTime] = useState(false);
  return (
    <section className="p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Reports | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        <Tabs
          value="Overview"
          orientation="vertical"
          className="w-full block overflow-visible"
        >
          <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-y-0 xl:gap-5 lg:gap-5">
            {/* SIDEBAR */}
            <div className="col-span-1 h-fit">
              <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-md">
                <CardBody className="p-5">
                  <TabsHeader
                    className="rounded-md bg-transparent"
                    indicatorProps={{
                      className:
                        "bg-logoOcre bg-opacity-10 dark:bg-opacity-20 text-logoOcre shadow-none border border-logoOcre border-opacity-20",
                    }}
                  >
                    <Tab
                      value="Overview"
                      key="Overview"
                      className="w-full font-proxima-semibold text-black dark:text-white py-2 px-4 justify-start"
                    >
                      <div className="w-full flex items-center justify-between gap-2">
                        Overview
                        <FaInfoCircle
                          className="w-4 h-4 text-greyText"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="All displayed reports are based on entry of the trades"
                        />
                        <Tooltip
                          id="my-tooltip"
                          className="text-sm font-proxima-regular text-white z-top"
                        />
                      </div>
                    </Tab>

                    {/* DATE AND TIME */}
                    <div className="mt-5">
                      <div className="py-2 px-3 rounded-md bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white">
                          Date and Time
                        </p>
                        <p className="text-greyText dark:text-lightGreyText text-[1.4vh] font-proxima-regular">
                          Reports according to date and time
                        </p>
                      </div>
                      <div className="mt-3">
                        {dateTimeTabs.map((data) => (
                          <React.Fragment key={data.id}>
                            <Tab
                              value={data.value}
                              key={data.value}
                              className="w-full font-proxima-semibold text-black dark:text-white py-2 px-4 justify-start first:mt-0 mt-2"
                            >
                              {data.value}
                            </Tab>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    {/* TAGS */}
                    <div className="mt-3">
                      <div className="py-2 px-3 rounded-md bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white">
                          Tags
                        </p>
                        <p className="text-greyText dark:text-lightGreyText text-[1.4vh] font-proxima-regular">
                          Reports according to tags analysis
                        </p>
                      </div>
                      <div className="mt-3">
                        {tags.map((data) => (
                          <React.Fragment key={data.id}>
                            <Tab
                              value={data.value}
                              key={data.value}
                              className="w-full font-proxima-semibold text-black dark:text-white py-2 px-4 justify-start first:mt-0 mt-2"
                            >
                              {data.value}
                            </Tab>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    {/* RISK */}
                    <div className="mt-3">
                      <div className="py-2 px-3 rounded-md bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white">
                          Risk
                        </p>
                        <p className="text-greyText dark:text-lightGreyText text-[1.4vh] font-proxima-regular">
                          Reports according to risk analysis
                        </p>
                      </div>
                      <div className="mt-3">
                        {risks.map((data) => (
                          <React.Fragment key={data.id}>
                            <Tab
                              value={data.value}
                              key={data.value}
                              className="w-full font-proxima-semibold text-black dark:text-white py-2 px-4 justify-start first:mt-0 mt-2"
                            >
                              {data.value}
                            </Tab>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    {/* COMMISSION */}
                    <div className="mt-3">
                      <div className="py-2 px-3 rounded-md bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white">
                          Commissions
                        </p>
                        <p className="text-greyText dark:text-lightGreyText text-[1.4vh] font-proxima-regular">
                          Reports according to commissions
                        </p>
                      </div>
                      <div className="mt-3">
                        {commissions.map((data) => (
                          <React.Fragment key={data.id}>
                            <Tab
                              value={data.value}
                              key={data.value}
                              className="w-full font-proxima-semibold text-black dark:text-white py-2 px-4 justify-start first:mt-0 mt-2"
                            >
                              {data.value}
                            </Tab>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </TabsHeader>
                </CardBody>
              </Card>
            </div>

            {/* TAB PANELS */}
            <div className="col-span-4 mt-5 xl:mt-0 lg:mt-0">
              <TabsBody className="pb-2">
                {/* OVERVIEW */}
                <TabPanel value="Overview" className="p-0">
                  {/* STATISTICS AND CHARTS */}
                  {!loading1 ? (
                    <>
                      {" "}
                      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 items-center">
                        <div className="col-span-1">
                          <div className="grid grid-cols-1 gap-5">
                            <div className="col-span-1">
                              <AnalyticsCard
                                analyticsLabel="Best month"
                                monthData={monthData}
                                analyticsSublabel="Your best month in the trading was"
                                month={bestMonth[0]?.month}
                                analyticsValue={bestMonth[0]?.profit}
                              />
                            </div>
                            <div className="col-span-1">
                              <AnalyticsCard
                                monthData={monthData}
                                analyticsLabel="Lowest month"
                                analyticsSublabel="Your lowest month in the trading was"
                                month={bestMonth[bestMonth?.length - 1]?.month}
                                analyticsValue={
                                  bestMonth[bestMonth?.length - 1]?.profit
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <CumulativeProfitLoss />
                        </div>
                        <div className="col-span-1">
                          <NetDailyProfitLoss />
                        </div>
                      </div>
                      {/* ANALYTICS COUNT */}
                      <div className="mt-5">
                        <Card className="bg-white dark:bg-darkSidebar rounded-lg border border-[#E5E5E5] dark:border-darkBorder">
                          <CardBody className="p-0">
                            <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3">
                              <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                Your Statistics
                              </h2>
                            </div>
                            <div className="p-5">
                              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5">
                                <div className="col-span-1">
                                  {statsColOne.slice(0, 5).map((stats) => (
                                    <React.Fragment key={stats.id}>
                                      <StatisticTile
                                        title={stats.title}
                                        value={stats.value}
                                      />
                                    </React.Fragment>
                                  ))}
                                </div>
                                <div className="col-span-1">
                                  {statsColOne.slice(5, 9).map((stats) => (
                                    <React.Fragment key={stats.id}>
                                      <StatisticTile
                                        title={stats.title}
                                        value={stats.value}
                                      />
                                    </React.Fragment>
                                  ))}
                                </div>
                                <div className="col-span-1">
                                  {statsColOne.slice(9, 13).map((stats) => (
                                    <React.Fragment key={stats.id}>
                                      <StatisticTile
                                        title={stats.title}
                                        value={stats.value}
                                      />
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>{" "}
                    </>
                  ) : (
                    <Loading />
                  )}
                </TabPanel>

                {/* DAYS REPORTS */}
                <TabPanel value="Days" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY DAY */}
                    <div className="col-span-1">
                      {!loading && (
                        <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                          <CardBody className="p-0">
                            <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                              <div className="flex items-center justify-between">
                                <div>
                                  <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                    Trade Distribution by day of the week
                                  </h2>
                                  <p className="text-sm font-proxima-regular text-lightGreyText">
                                    Displays trade count grouped by the day, the
                                    trade was closed.
                                  </p>
                                </div>
                                {/* <Button
                                  className="shadow-none hover:shadow-none bg-transparent flex items-center gap-2 dark:text-white text-black text-sm font-proxima-semibold normal-case p-3 leading-none hover:bg-logoOcre hover:bg-opacity-10 rounded-md"
                                  onClick={() => setExpand(!expand)}
                                >
                                  <FaExpand className="w-4 h-4" />
                                  Expand
                                </Button> */}
                              </div>
                            </div>
                            {
                              <div className="p-2">
                                <div className="block dark:hidden">
                                  {daysData?.length === 7 && !loading ? (
                                    <TradeDistributionDay
                                      daysData={daysData}
                                      height="h-[40vh]"
                                    />
                                  ) : (
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                    />
                                  )}
                                </div>
                                <div className="hidden dark:block">
                                  {daysData?.length === 7 && !loading ? (
                                    <TradeDistributionDayDark
                                      daysData={daysData}
                                      height="h-[40vh]"
                                    />
                                  ) : (
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                                    />
                                  )}
                                </div>
                              </div>
                            }
                          </CardBody>
                        </Card>
                      )}
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by day of the week
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your realized P&L amount grouped by
                              different days of week
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  /> */}
                                {performanceByDay?.length === 7 && !loading ? (
                                  <PerformanceByDay
                                    performanceByDay={performanceByDay}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {performanceByDay?.length === 7 && !loading ? (
                                  <PerformanceByDayDark
                                    performanceByDay={performanceByDay}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the days.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={daySummaryColumns}
                          data={dayData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={daySummaryColumns}
                          data={dayData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* WEEKS REPORTS */}
                <TabPanel value="Weeks" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <div className="flex items-center justify-between">
                              <div>
                                <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                  Trade Distribution by week
                                </h2>
                                <p className="text-sm font-proxima-regular text-lightGreyText">
                                  Displays the number of trade exits aggregated
                                  by the day of week.
                                </p>
                              </div>
                              {weeksData?.length > 0 && !loading && (
                                <Button
                                  className="shadow-none hover:shadow-none bg-transparent flex items-center gap-2 dark:text-white text-black text-sm font-proxima-semibold normal-case p-3 leading-none hover:bg-logoOcre hover:bg-opacity-10 rounded-md"
                                  onClick={() => setExpandDWeek(!expandDWeek)}
                                >
                                  <FaExpand className="w-4 h-4" />
                                  Expand
                                </Button>
                              )}
                            </div>
                          </div>

                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {weeksData?.length > 0 && !loading ? (
                                  <TradeDistributionWeek
                                    data={weeksData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/**/}
                                {weeksData?.length > 0 && !loading ? (
                                  <TradeDistributionWeekDark
                                    data={weeksData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <div className="flex items-center justify-between">
                              <div>
                                {" "}
                                <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                  Performance by the week
                                </h2>
                                <p className="text-sm font-proxima-regular text-lightGreyText">
                                  Reflects your total realized P&L aggregated by
                                  the week
                                </p>
                              </div>
                              {weeksData?.length > 0 && !loading && (
                                <Button
                                  className="shadow-none hover:shadow-none bg-transparent flex items-center gap-2 dark:text-white text-black text-sm font-proxima-semibold normal-case p-3 leading-none hover:bg-logoOcre hover:bg-opacity-10 rounded-md"
                                  onClick={() => setExpandPWeek(!expandPWeek)}
                                >
                                  <FaExpand className="w-4 h-4" />
                                  Expand
                                </Button>
                              )}
                            </div>
                          </div>
                          {
                            <div className="p-2">
                              <div className="block dark:hidden">
                                {weeksData?.length > 0 && !loading ? (
                                  <PerformanceByWeek
                                    data={weeksData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {weeksData?.length > 0 && !loading ? (
                                  <PerformanceByWeekDark
                                    data={weeksData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the date range.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={weekSummaryColumns}
                          data={weeksData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={weekSummaryColumns}
                          data={weeksData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* TRADE TIME REPORTS */}
                <TabPanel value="Trade Time" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <div className="flex items-center justify-between">
                              <div>
                                {" "}
                                <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                  Trade Distribution by trade time
                                </h2>
                                <p className="text-sm font-proxima-regular text-lightGreyText">
                                  Displays the number of trade exits aggregated
                                  by the day of trade time.
                                </p>
                              </div>
                              {/* {tradeTimeData?.length > 0 && !loading &&  */}
                              <Button
                                className="shadow-none hover:shadow-none bg-transparent flex items-center gap-2 dark:text-white text-black text-sm font-proxima-semibold normal-case p-3 leading-none hover:bg-logoOcre hover:bg-opacity-10 rounded-md"
                                onClick={() => setExpandDTime(!expandDTime)}
                              >
                                <FaExpand className="w-4 h-4" />
                                Expand
                              </Button>
                              {/* } */}
                            </div>
                          </div>
                          {
                            <div className="p-2">
                              <div className="block dark:hidden">
                                {/*  */}
                                {tradeTimeData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeTime
                                    data={tradeTimeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/**/}
                                {tradeTimeData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeTImeDark
                                    data={tradeTimeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <div className="flex items-center justify-between">
                              <div>
                                {" "}
                                <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                                  Performance by the trade time
                                </h2>
                                <p className="text-sm font-proxima-regular text-lightGreyText">
                                  Reflects your total realized P&L aggregated by
                                  the trade time
                                </p>
                              </div>
                              {/* {tradeTimeData?.length > 0 && !loading &&  */}
                              <Button
                                className="shadow-none hover:shadow-none bg-transparent flex items-center gap-2 dark:text-white text-black text-sm font-proxima-semibold normal-case p-3 leading-none hover:bg-logoOcre hover:bg-opacity-10 rounded-md"
                                onClick={() => setExpandPTime(!expandPTime)}
                              >
                                <FaExpand className="w-4 h-4" />
                                Expand
                              </Button>
                              {/* } */}
                            </div>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/*   */}
                                {tradeTimeData?.length > 0 && !loading ? (
                                  <PerformanceByTradeTime
                                    data={tradeTimeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {tradeTimeData?.length > 0 && !loading ? (
                                  <PerformanceByTradeTimeDark
                                    data={tradeTimeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the date range.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={TradeSummaryColumns}
                          data={tradeTimeData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={TradeSummaryColumns}
                          data={tradeTimeData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* TRADE DURATION REPORTS */}
                <TabPanel value="Trade Duration" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Trade Distribution by trade duration
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the number of trade exits aggregated by
                              the day of trade duration.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/**/}
                                {tradeDurationData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeDuration
                                    data={tradeDurationData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/**/}
                                {tradeDurationData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeDurationDark
                                    data={tradeDurationData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the trade Duration
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              trade Duration
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {tradeDurationData?.length > 0 && !loading ? (
                                  <PerformanceByTradeDuration
                                    data={tradeDurationData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {tradeDurationData?.length > 0 && !loading ? (
                                  <PerformanceByTradeDurationDark
                                    data={tradeDurationData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the date range.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={TradeDurationColumns}
                          data={tradeDurationData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={TradeDurationColumns}
                          data={tradeDurationData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* TRADE SETUP REPORTS */}
                <TabPanel value="Setups" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Trade Distribution by setup
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the number of trade exits aggregated by
                              the day of setup.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/*  */}
                                {tradeSetupData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeSetup
                                    data={tradeSetupData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/* */}
                                {tradeSetupData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeSetupDark
                                    data={tradeSetupData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the setup
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              setup
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/*  */}
                                {tradeSetupData?.length > 0 && !loading ? (
                                  <PerformanceByTradeSetup
                                    data={tradeSetupData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {tradeSetupData?.length > 0 && !loading ? (
                                  <PerformanceByTradeSetupDark
                                    data={tradeSetupData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the setup.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={TradeSetupColumns}
                          data={tradeSetupData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={TradeSetupColumns}
                          data={tradeSetupData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* TRADE MISTAKE REPORTS */}
                <TabPanel value="Mistakes" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Trade Distribution by mistakes
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the number of trade exits aggregated by
                              the day of mistakes.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {tradeMistakeData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeMistake
                                    data={tradeMistakeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/**/}
                                {tradeMistakeData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeMistakeDark
                                    data={tradeMistakeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the mistakes
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              mistakes
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/*  */}
                                {tradeMistakeData?.length > 0 && !loading ? (
                                  <PerformanceByTradeMistake
                                    data={tradeMistakeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/**/}
                                {tradeMistakeData?.length > 0 && !loading ? (
                                  <PerformanceByTradeMistakeDark
                                    data={tradeMistakeData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the mistakes.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={TradeMistakeColumns}
                          data={tradeMistakeData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={TradeMistakeColumns}
                          data={tradeMistakeData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* TRADE MISTAKE REPORTS */}
                <TabPanel value="Risk Reward" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Trade Distribution by RR
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the number of trade exits aggregated by
                              the day of RR.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {tradeRRData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeRR
                                    data={tradeRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {tradeRRData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeRRDark
                                    data={tradeRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the RR
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              RR
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {tradeRRData?.length > 0 && !loading ? (
                                  <PerformanceByTradeRR
                                    data={tradeRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {tradeRRData?.length > 0 && !loading ? (
                                  <PerformanceByTradeRRDark
                                    data={tradeRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the RR.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={TradeRRColumns}
                          data={tradeRRData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={TradeRRColumns}
                          data={tradeRRData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>
                <TabPanel value="Compare RR" className="p-0">
                  {/* CHARTS */}
                  {/* <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Trade Distribution by Compare RR
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the number of trade exits aggregated by
                              the day of Compare RR.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {compareRRData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeCRR
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {compareRRData?.length > 0 && !loading ? (
                                  <TradeDistributionTradeCRRDark
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the Compare RR
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              Compare RR
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {compareRRData?.length > 0 && !loading ? (
                                  <PerformanceByTradeCRR
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {compareRRData?.length > 0 && !loading ? (
                                  <PerformanceByTradeCRRDark
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div> */}
                  <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Compare RR
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText"></p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {compareRRData?.length > 0 && !loading ? (
                                  <TradeCRR
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block rr-chart">
                                {/*  */}
                                {compareRRData?.length > 0 && !loading ? (
                                  <TradeCRRDark
                                    data={compareRRData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the RR.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={CompareRRColumns}
                          data={compareRRData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={CompareRRColumns}
                          data={compareRRData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>
                {/* COMMISSION REPORTS */}
                <TabPanel value="Monthly Commissions" className="p-0">
                  {/* CHARTS */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    {/* DISTRIBUTION BY WEEK */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Monthly Commission
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Displays the commissions of trade exits aggregated
                              by the month.
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {commissionData?.length > 0 && !loading ? (
                                  <CommissionByMonth
                                    data={commissionData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {commissionData?.length > 0 && !loading ? (
                                  <CommissionByMonthDark
                                    data={commissionData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>

                    {/* PERFORMANCE BY DAY */}
                    <div className="col-span-1">
                      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
                        <CardBody className="p-0">
                          <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Performance by the Commissions
                            </h2>
                            <p className="text-sm font-proxima-regular text-lightGreyText">
                              Reflects your total realized P&L aggregated by the
                              Commissions
                            </p>
                          </div>
                          {
                            <div className="py-2 pl-2 pr-5">
                              <div className="block dark:hidden">
                                {/* */}
                                {commissionData?.length > 0 && !loading ? (
                                  <CommissionByProfit
                                    data={commissionData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#E5E5E5"
                                    highlightColor="#F5F5F5"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                              <div className="hidden dark:block">
                                {/*  */}
                                {commissionData?.length > 0 && !loading ? (
                                  <CommissionByProfitDark
                                    data={commissionData}
                                    height="h-[40vh]"
                                  />
                                ) : (
                                  <Skeleton
                                    baseColor="#141418"
                                    highlightColor="#232327"
                                    className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                                  />
                                )}
                              </div>
                            </div>
                          }
                        </CardBody>
                      </Card>
                    </div>
                  </div>

                  {/* SUMMARY TABLE */}
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                        <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                          Summary
                        </h2>
                        <p className="text-sm font-proxima-regular text-lightGreyText">
                          Displays the summary of your trade details according
                          to the RR.
                        </p>
                      </div>
                      <div className="p-5 block dark:hidden">
                        <DataTable
                          columns={CommissionColumns}
                          data={commissionData}
                          className="trades-table"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                      <div className="p-5 hidden dark:block">
                        <DataTable
                          columns={CommissionColumns}
                          data={commissionData}
                          className="trades-table dark"
                          responsive
                          noDataComponent={
                            <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                              <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                There are no trades to display. Please connect
                                your broker to see your previous trades.
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>
              </TabsBody>
            </div>
          </div>
        </Tabs>
      </div>

      {/* CHART EXPAND DIALOG */}
      <Dialog
        open={expandDWeek}
        handler={() => setExpandDWeek(!expandDWeek)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="md"
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Detailed chart
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => setExpandDWeek(!expandDWeek)}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <TradeDistributionWeekExpand data={weeksData} height="h-[40vh]" />
              {/* ) : (
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
            <div className="hidden dark:block">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <TradeDistributionWeekDarkExpand
                data={weeksData}
                height="h-[40vh]"
              />
              {/* ) : (
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
          </div>
        </DialogBody>
      </Dialog>
      {/* CHART EXPAND DIALOG */}
      <Dialog
        open={expandPWeek}
        handler={() => setExpandPWeek(!expandPWeek)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="md"
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Detailed chart
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => setExpandPWeek(!expandPWeek)}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <PerformanceByWeekExpand data={weeksData} height="h-[40vh]" />
              {/* ) : (
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
            <div className="hidden dark:block">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <PerformanceByWeekDarkExpand data={weeksData} height="h-[40vh]" />
              {/* ) : (
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
          </div>
        </DialogBody>
      </Dialog>
      {/* CHART EXPAND TIME DIALOG */}
      <Dialog
        open={expandPTime}
        handler={() => setExpandPTime(!expandPTime)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="md"
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Detailed chart
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => setExpandPTime(!expandPTime)}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <PerformanceByTradeTimeExpand
                data={tradeTimeData}
                height="h-[40vh]"
              />
              {/* ) : (
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
            <div className="hidden dark:block">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <PerformanceByTradeTimeDarkExpand
                data={tradeTimeData}
                height="h-[40vh]"
              />
              {/* ) : (
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
          </div>
        </DialogBody>
      </Dialog>
      {/* CHART EXPAND TIME DIALOG */}
      <Dialog
        open={expandDTime}
        handler={() => setExpandDTime(!expandDTime)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="md"
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Detailed chart
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => setExpandDTime(!expandDTime)}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="p-1">
            <div className="block dark:hidden">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <TradeDistributionTradeTimeExpand
                data={tradeTimeData}
                height="h-[40vh]"
              />
              {/* ) : (
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-[95%] h-[37vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
            <div className="hidden dark:block">
              {/* {weeksData?.length === 7 && !loading ? ( */}
              <TradeDistributionTradeTImeDarkExpand
                data={tradeTimeData}
                height="h-[40vh]"
              />
              {/* ) : (
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-[95%] h-[23vh] flex text-center mx-auto mt-2 -mb-2"
                />
              )} */}
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default NewReports;
