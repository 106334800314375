/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import Select from "react-select";
import actions from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

/* ICONS */
import { IoNotifications, IoWallet } from "react-icons/io5";
import { MdDarkMode, MdBrightnessHigh } from "react-icons/md";
import { FaCalendar } from "react-icons/fa6";

/* IMAGES */
import Profile from "../../assets/images/profile.jpg";
import { getProfile, getUserExchange, updateProfile } from "../../service/api";
import { defaultStaticRanges } from "../../components/others/date-ranges";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Header = () => {
  const { user, exchanges, startDate, endDate } = useSelector(
    (state) => state.user
  );
  const [exchange, setExchange] = useState([
    { label: "All accounts", value: "All accounts" },
  ]);
  const [accountOptions, setAccountOptions] = useState([
    { label: "All accounts", value: "All accounts" },
  ]);
  const d = new Date();
  const d1 = new Date();
  const minDate = d.setFullYear(d.getFullYear() - 7);
  const maxDate = d1.setDate(d1.getDate() + 1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    if (
      exchange &&
      exchange?.length > 0 &&
      exchange?.length !== exchanges?.length
    ) {
      let arr = [];
      exchange?.map((obj) => {
        if (exchanges?.filter((obj1) => obj1._id === obj.value)?.length > 0) {
          arr.push(exchanges?.filter((obj1) => obj1._id === obj.value)[0]);
        }
      });
      dispatch(actions.setSelectedExchange(arr));
    } else {
      dispatch(actions.setSelectedExchange(null));
    }
  }, [exchange]);

  useEffect(() => {
    if (exchanges?.length === 0) {
      let data = [{ label: "All accounts", value: "All accounts" }];
      let arr = [];
      exchanges?.map((obj) => {
        arr.push({
          label: obj?.exchange + " " + (obj?.accountId ? obj?.accountId : ""),
          value: obj?._id,
        });

        data.push({
          label: obj?.exchange + " " + (obj?.accountId ? obj?.accountId : ""),
          value: obj?._id,
        });
      });
      setExchange(arr);
      setAccountOptions(data);
    }
  }, [exchanges?.length]);

  var greetingText = "";
  const today = new Date();
  const currentHours = today.getHours();

  if (currentHours < 12) {
    greetingText = "Good morning";
  } else if (currentHours < 18) {
    greetingText = "Good afternoon";
  } else {
    greetingText = "Good evening";
  }

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(
      endDate?.split("/")[2],
      Number(endDate?.split("/")[1]) - 1,
      endDate?.split("/")[0]
    ),
    key: "selection",
  });

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("isLoggedIn", false);
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
    dispatch(actions.setToken(null));
    navigate("/");
    window.location.reload();
  };

  const [datepicker, setDatepicker] = useState(false);

  useEffect(() => {
    if (endDate) {
      setSelectionRange({
        ...selectionRange,
        endDate: new Date(
          endDate?.split("/")[2],
          Number(endDate?.split("/")[1]) - 1,
          endDate?.split("/")[0]
        ),
      });
    }
    if (startDate) {
      setSelectionRange({
        ...selectionRange,
        startDate: new Date(
          startDate?.split("/")[2],
          Number(startDate?.split("/")[1]) - 1,
          startDate?.split("/")[0]
        ),
      });
    }
  }, [datepicker]);

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* DARK MODE */
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "false"
      ? false
      : localStorage.getItem("darkMode") === "true"
        ? true
        : false
  );

  /* USE EFFECT */
  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);
  const profileData = () => {
    getProfile()
      .then((res) => {
        if (res?.data) {
          dispatch(actions.setUser(res?.data));
          if (location.pathname === "/trade-details") {
            window.location.reload()
          }
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
    localStorage.setItem("darkMode", !darkMode);
    updateProfile({
      mode: !darkMode !== true ? "LIGHT" : "DARK",
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      // userName: user?.userName,
      twoFactorAuthentication: true,
    }).then((res) => {
      if (res?.data) {
        profileData();
      }
    });
  };

  function capitalizeFirstLetter(data) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getUserExchanges = () => {
    getUserExchange().then((res) => {
      if (res?.data) {
        dispatch(actions.setConnectedExchange(res?.data));
        let data = [{ label: "All accounts", value: "All accounts" }];
        res?.data?.map((obj) => {
          data.push({
            label:
              capitalizeFirstLetter(obj?.exchange) +
              " " +
              (obj?.accountId ? obj?.accountId : ""),
            value: obj?._id,
          });
        });
        setAccountOptions(data);
      }
    });
  };

  useEffect(() => {
    getUserExchanges();
  }, ['']);

  return (
    <section className="h-header bg-white dark:bg-darkSidebar border border-[#E0E0E0] dark:border-darkBorder px-5 w-full flex items-center justify-between rounded-lg">
      <div className="flex items-center gap-5">
        <h2 className="text-lg font-proxima-bold text-black dark:text-white">
          Good to see you, {user?.firstName}
        </h2>
        <div className="border-l border-[#E5E5E5] dark:border-darkBorder pl-5 relative">
          <div className="flex items-center gap-5">
            {/* RANGE PICKER */}
            <div
              ref={domNode}
              className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4"
            >
              <FaCalendar className="w-4 h-4 text-[#C9C8C8]" />
              <input
                type="text"
                name="dateRange"
                placeholder="Select the date range"
                value={startDate && endDate ? `${startDate} - ${endDate}` : ""}
                onClick={() => setDatepicker(!datepicker)}
                className="w-[18vh] cursor-pointer bg-white dark:bg-darkSidebar outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-[#4B4B4B] dark:text-white"
              />
            </div>
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-10 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <div className="block dark:hidden">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    staticRanges={defaultStaticRanges}
                    onChange={(e) => {
                      if (e?.selection) {
                        dispatch(
                          actions.setEndDate(
                            moment(e.selection.endDate).format("DD/MM/YYYY")
                          )
                        );
                        dispatch(
                          actions.setStartDate(
                            moment(e.selection.startDate).format("DD/MM/YYYY")
                          )
                        );
                      }
                      setSelectionRange(e?.selection);
                    }}
                    inputRanges={[]}
                    className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                  />
                </div>
                <div className="hidden dark:block">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    staticRanges={defaultStaticRanges}
                    onChange={(e) => {
                      if (e?.selection) {
                        dispatch(
                          actions.setEndDate(
                            moment(e.selection.endDate).format("DD/MM/YYYY")
                          )
                        );
                        dispatch(
                          actions.setStartDate(
                            moment(e.selection.startDate).format("DD/MM/YYYY")
                          )
                        );
                      }
                      setSelectionRange(e?.selection);
                    }}
                    inputRanges={[]}
                    className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] dark:border-darkBorder cursor-pointer calendarDark"
                  />
                </div>
              </div>
            )}

            {/* ACCOUNT SELECTION */}
            <div className="h-[4vh] flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4">
              <IoWallet className="w-4 h-4 text-[#C9C8C8]" />
              <Select
                value={exchange}
                options={accountOptions}
                isMulti
                className={`${darkMode ? "react-select dark" : "react-select"
                  } font-proxima-semibold cursor-pointer`}
                classNamePrefix="account"
                placeholder="Select accounts"
                onChange={(e) => {
                  if (
                    e?.filter((obj) => obj.label === "All accounts")?.length > 0
                  ) {
                    setExchange(
                      accountOptions?.filter(
                        (obj) => obj.label !== "All accounts"
                      )
                    );
                  } else {
                    setExchange(e);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* RIGHT */}
      <div className="flex items-center">
        <div className="mr-3 flex items-center gap-1">
          {/* LIGHT AND DARK MODE */}
          <Button
            className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 p-3 rounded-full relative"
            onClick={toggleDarkMode}
          >
            {darkMode ? (
              <MdBrightnessHigh className="w-5 h-5 text-greyText dark:text-lightGreyText" />
            ) : (
              <MdDarkMode className="w-5 h-5 text-greyText dark:text-lightGreyText" />
            )}
          </Button>
        </div>

        {/* PROFILE */}
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
          allowHover
        >
          <MenuHandler>
            <div className="flex items-center gap-3 hover:outline-none cursor-pointer">
              <div className="bg-white border border-[#E5E5E5] rounded-full">
                <img
                  src={user?.profileImage ? user?.profileImage : Profile}
                  className="w-10 h-10 object-contain rounded-full"
                  alt="User profile"
                />
              </div>
              <div>
                <h2 className="text-base font-proxima-semibold text-black dark:text-white leading-none">
                  {user?.firstName}&nbsp;{user?.lastName}
                </h2>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText leading-none mt-1">
                  {user?.role}
                </p>
              </div>
            </div>
          </MenuHandler>
          <MenuList className="p-0 mt-1 bg-white dark:bg-darkSidebar border dark:border-darkBorder">
            <MenuItem className="hover:bg-buttonColor hover:bg-opacity-10 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 border-b border-[#E5E5E5] dark:border-darkBorder rounded-none py-3">
              <Link to="/profile">
                <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white">
                  Your Profile
                </p>
              </Link>
            </MenuItem>
            <MenuItem
              className="hover:bg-buttonColor hover:bg-opacity-20 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 rounded-none py-3"
              onClick={handleLogout}
            >
              <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white">
                Logout
              </p>
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </section>
  );
};

export default Header;
