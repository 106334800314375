import moment from "moment";
import actions from "./actions";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  exchanges: null,
  selectedExchange: null,
  startDate: moment().add(-1, "month").format("DD/MM/YYYY"),
  endDate: moment().format("DD/MM/YYYY"),
  selectedDay:null,
  selectedTrade:null
};

const {
  SET_USER,
  SET_TOKEN,
  SET_LOADING,
  SET_LOGGEDIN,
  SET_EXCHANGE_CONNECTED,
  SET_SELECTED_EXCHANGE,
  SET_START_DATE,
  SET_END_DATE,
  SELECTED_DAY,
  SET_SELECTED_TRADE
} = actions;
const UserReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_EXCHANGE_CONNECTED:
      return {
        ...state,
        exchanges: data,
      };
    case SELECTED_DAY:
      return {
        ...state,
        selectedDay: data,
      };
    case SET_SELECTED_EXCHANGE:
      return {
        ...state,
        selectedExchange: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: data,
      };
    case SET_END_DATE:
      return {
        ...state,
        endDate: data,
      };
    case SET_SELECTED_TRADE:
      return {
        ...state,
        selectedTrade: data,
      };
    default:
      return state;
  }
};

export default UserReducer;
