/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* ICONS */
import { FaFileUpload, FaFileCsv } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import Select from "react-select";

/* IMAGES */
import Zerodha from "../../../../assets/images/brokers/zerodha-full.webp";
import Upstox from "../../../../assets/images/brokers/upstox-full.webp";
import Dhan from "../../../../assets/images/brokers/dhan.png";
import Fyers from "../../../../assets/images/brokers/fyers.jpg";
import AngelOne from "../../../../assets/images/brokers/angel-one.png";
import { uploadTrade } from "../../../../service/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

/* EXCEL FILES */
import AngelTemplate from "../../../../assets/angel-broker.xlsx";
import UpstoxTemplate from "../../../../assets/upstox-broker.xlsx";
import ZerodhaBroker from "../../../../assets/zerodha-broker.csv";

/* BADGE */
const Badge = (props) => {
  return (
    <div
      className={`${props.color} text-[1.3vh] text-white font-proxima-regular rounded-full px-3 py-1`}
    >
      {props.badgeText}
    </div>
  );
};

const FileUpload = () => {
  /* STATE VARIABLES */
  const exchanges = [
    { exchange: "upstox" },
    // { exchange: "dhan" },
    // { exchange: "fyers" },
    { exchange: "kite" },
    { exchange: "angel" },
    // { exchange: "motilal" }
  ];
  const user = useSelector((state) => state.user);
  const [file, setFile] = useState();
  const [fileName, setFilename] = useState("");
  const [exchangesId, setExchangesId] = useState();
  const [exchangesId2, setExchangesId2] = useState();
  const [loading, setLoading] = useState(false);
  const [fileUploadDialog, setFileUploadDialog] = useState(false);

  /* INPUT TYPE FUNCTION */
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0]?.name);
  };

  /* FILE UPLOAD FUNCTION */
  const handleUpload = () => {
    if (!file) {
      toast.error("Please upload a file.");
      return;
    }
    const formData = new FormData();
    formData.append("txData", file);
    formData.append("exchange", exchangesId);
    let id = "without-exchange";
    if (exchangesId2?.value?.value && exchangesId === exchangesId2?.exchange) {
      id = exchangesId2?.value?.value;
    }
    setLoading(true);
    uploadTrade(formData, id)
      .then((res) => {
        setLoading(false);

        if (res.status) {
          setFile();
          setFilename("");
          setExchangesId2();
          setExchangesId();
          toast.success(
            "Your CSV file is uploaded, Your trades will be imported shortly"
          );
          document.getElementById("file").value = "";
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
        if (e.response?.data?.message) {
          setLoading(false);
          toast.error(e.response?.data?.message);
        }
      });
  };

  /* DARK MODE */
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "false"
      ? false
      : localStorage.getItem("darkMode") === "true"
      ? true
      : false
  );

  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Upload your CSV | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* CSV UPLOAD */}
        {exchanges && exchanges?.length > 0 ? (
          exchanges?.map((obj, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-y-5 gap-0 xl:gap-5 lg:gap-5 mt-5 first:mt-0"
              >
                <div className="col-span-3 h-full">
                  <Card className="dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg shadow-md h-full">
                    <CardBody className="p-0">
                      <div className="px-5 py-3 block xl:flex lg:flex items-center justify-between border-b border-[#E5E5E5] dark:border-darkBorder">
                        <div className="bg-white rounded-md px-3 py-2">
                          <img
                            src={
                              obj?.exchange === "fyers"
                                ? Fyers
                                : obj?.exchange === "dhan"
                                ? Dhan
                                : obj?.exchange === "upstox"
                                ? Upstox
                                : obj?.exchange === "angel"
                                ? AngelOne
                                : Zerodha
                            }
                            className="h-7 w-auto object-contain"
                            alt={obj?.exchange}
                          />
                        </div>
                        <div className="flex items-center gap-2 mt-5 xl:mt-0 lg:mt-0">
                          <Badge color="bg-[#397dd1]" badgeText="Stocks" />
                          <Badge color="bg-[#397dd1]" badgeText="Futures" />
                          <Badge color="bg-[#397dd1]" badgeText="Options" />
                        </div>
                      </div>
                      <div className="p-5">
                        {obj?.exchange === "kite" ? (
                          <>
                            <h2 className="text-lg font-proxima-bold text-black dark:text-white leading-relaxed">
                              How to import trades from Kite
                            </h2>
                            <ul className="ml-5 mt-2 list-decimal">
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-2">
                                Go to Zerodha and log in to your account using
                                your credentials.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Once logged in, navigate to the{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Reports'
                                </span>{" "}
                                section. You can usually find it in the main
                                menu or sidebar.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                In the{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  Reports
                                </span>{" "}
                                section, look for the option to generate a trade
                                book report. This report contains details of all
                                your trades.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Select the date range for which you want to
                                generate the trade book. You can choose a
                                specific date range or select from their
                                predefined options.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                After selecting the date range, click on the{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Generate Report'
                                </span>{" "}
                                or{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Export'
                                </span>{" "}
                                button. Zerodha will then generate the trade
                                book report in CSV format.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Once the report is generated, you'll see an
                                option to download it. Click on the download
                                button to save the trade book CSV file to your
                                computer.
                              </li>
                            </ul>
                          </>
                        ) : obj?.exchange === "upstox" ? (
                          <>
                            <h2 className="text-lg font-proxima-bold text-black dark:text-white leading-relaxed">
                              How to import trades from Upstox
                            </h2>
                            <ul className="ml-5 mt-2 list-decimal">
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-2">
                                Login to the {obj?.exchange} account using your
                                credentials.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Once logged in, navigate to the{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Reports'
                                </span>
                                section and look for the{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Trade Book'
                                </span>{" "}
                                option. Click on it to access your trade data.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Select the date range for which you want to
                                generate the trade book. You can choose a
                                specific date range or select from their
                                predefined options.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Find the option to export the trade data near
                                the table displaying your trades. It might be
                                labeled{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  'Export'
                                </span>{" "}
                                or
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  {" "}
                                  'Download'
                                </span>
                                .
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Choose{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  CSV (Comma-Separated Values)
                                </span>{" "}
                                format to initiate the download.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  Remove the user details from the CSV by
                                  deleting rows 1-8 and the last row with
                                  non-trade data
                                </span>
                                . Save the file and upload it to Trade Talez to
                                fetch your transaction data.
                              </li>
                            </ul>
                          </>
                        ) : obj?.exchange === "angel" ? (
                          <>
                            <h2 className="text-lg font-proxima-bold text-black dark:text-white leading-relaxed">
                              How to import trades from AngelOne
                            </h2>
                            <ul className="ml-5 mt-2 list-decimal">
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-2">
                                Login to the AngelOne account using your
                                credentials.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Once logged in, search for{" "}
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  ‘Trades and Charges’
                                </span>
                                section.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                In "Trade and charges", select the date range
                                and click on download trade history.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                <span className="text-black dark:text-white font-proxima-semibold">
                                  Remove the data from row 1-33.
                                </span>{" "}
                                Save the file and upload it to Trade Talez to
                                fetch your transaction data.
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <h2 className="text-lg font-proxima-bold text-black dark:text-white leading-relaxed">
                              How to import trades from{" "}
                              <span className="capitalize">
                                {obj?.exchange === "angel"
                                  ? "AngelOne"
                                  : obj?.exchange}
                              </span>
                            </h2>
                            <ul className="ml-5 mt-2 list-decimal">
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-2">
                                Login to the {obj?.exchange} account using your
                                credentials.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Once logged in, find the option for trade
                                reports. In {obj?.exchange}, it might be under
                                the "Reports" or "Backoffice" section.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Look for an option to generate a trade report in
                                CSV format.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Download the trade report. The report will
                                contain details of your trades.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                After importing, review the data to ensure all
                                trades and relevant information are correctly
                                captured.
                              </li>
                              <li className="text-base font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                                Remove the user details from the CSV file and
                                upload it here.
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-span-2 h-full">
                  <Card className="dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg shadow-md h-full">
                    <CardBody className="p-5 h-full flex items-center w-full">
                      <div className="w-full">
                        <div className="block xl:flex lg:flex items-center justify-between">
                          <div>
                            <h1 className="text-xl font-proxima-bold text-black dark:text-white">
                              Upload CSV
                            </h1>
                            <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                              (Select the trading account)
                            </p>
                          </div>

                          <div className="mt-3 xl:mt-0 lg:mt-0 flex items-center gap-2">
                            {user.exchanges?.length > 0 &&
                              user?.exchanges?.filter(
                                (obj1) => obj?.exchange === obj1?.exchange
                              )?.length > 0 && (
                                <div className="hidden dark:block border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-3">
                                  <Select
                                    isDisabled={loading}
                                    value={
                                      exchangesId2?.exchange ===
                                        obj?.exchange && exchangesId2?.value
                                    }
                                    options={user?.exchanges
                                      ?.filter(
                                        (obj1) =>
                                          obj?.exchange === obj1?.exchange
                                      )
                                      ?.map((obj2) => {
                                        return {
                                          value: obj2?._id,
                                          label:
                                            obj2?.exchange +
                                            " " +
                                            (obj2?.accountId
                                              ? obj2?.accountId
                                              : ""),
                                        };
                                      })}
                                    className="react-select dark font-proxima-semibold cursor-pointer"
                                    classNamePrefix="account"
                                    placeholder="Select accounts"
                                    onChange={(e) => {
                                      setExchangesId2({
                                        value: e,
                                        exchange: obj?.exchange,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            {user.exchanges?.length > 0 &&
                              user?.exchanges?.filter(
                                (obj1) => obj?.exchange === obj1?.exchange
                              )?.length > 0 && (
                                <div className="block dark:hidden border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-3">
                                  <Select
                                    isDisabled={loading}
                                    value={
                                      exchangesId2?.exchange ===
                                        obj?.exchange && exchangesId2?.value
                                    }
                                    options={user?.exchanges
                                      ?.filter(
                                        (obj1) =>
                                          obj?.exchange === obj1?.exchange
                                      )
                                      ?.map((obj2) => {
                                        return {
                                          value: obj2?._id,
                                          label:
                                            obj2?.exchange +
                                            " " +
                                            (obj2?.accountId
                                              ? obj2?.accountId
                                              : ""),
                                        };
                                      })}
                                    className="react-select font-proxima-semibold cursor-pointer"
                                    classNamePrefix="account"
                                    placeholder="Select accounts"
                                    onChange={(e) => {
                                      setExchangesId2({
                                        value: e,
                                        exchange: obj?.exchange,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            <Button
                              className="shadow-none hover:shadow-none font-proxima-semibold text-sm normal-case px-5 py-2 rounded-md bg-logoOcre hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                              onClick={() => handleUpload()}
                              disabled={
                                loading || obj?.exchange !== exchangesId
                              }
                            >
                              Upload
                            </Button>
                          </div>
                        </div>

                        {/* REFERENCE TEMPLATE */}
                        <div className="pt-4">
                          <p className="text-sm font-proxima-regular text-greyText dark:text-sidebarText">
                            Please download the provided template as a reference
                            to ensure that your files are uploaded in the
                            correct format.
                          </p>
                          <div className="mt-2">
                            <div className="w-full bg-white dark:bg-transparent border border-borderGrey dark:border-darkBorder rounded-md px-3 py-2 shadow">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="w-10 h-10 bg-green-300 bg-opacity-20 rounded flex items-center justify-center flex-shrink-0">
                                    <FaFileExcel className="w-5 h-5 text-green-500" />
                                  </div>
                                  <p className="text-sm font-proxima-regular text-black dark:text-white">
                                    <span className="capitalize">
                                      {obj?.exchange}
                                    </span>{" "}
                                    - Sample template
                                  </p>
                                </div>
                                <Link
                                  to={
                                    obj?.exchange === "kite"
                                      ? ZerodhaBroker
                                      : obj?.exchange === "upstox"
                                      ? UpstoxTemplate
                                      : obj?.exchange === "angel"
                                      ? AngelTemplate
                                      : ZerodhaBroker
                                  }
                                  className="block"
                                  target="_blank"
                                >
                                  <Button className="shadow-none hover:shadow-none bg-white dark:bg-black hover:bg-black hover:text-white border border-borderGrey dark:border-darkBorder rounded normal-case text-greyText dark:text-white font-proxima-regular px-4 py-2 transition-all duration-300 ease-in-out">
                                    Download
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* FILE UPLOAD */}
                        <form className="mt-0">
                          <div className="flex items-center gap-5"></div>
                          <div
                            className="form-group mt-5 relative"
                            onClick={() => setExchangesId(obj?.exchange)}
                          >
                            <input
                              disabled={loading}
                              type="file"
                              accept={".csv,.xlsx"}
                              className="opacity-0 w-full h-full absolute top-0 left-0 z-10 cursor-pointer"
                              onChange={handleChange}
                              id="file"
                            />
                            <div className="border border-dashed border-buttonColor border-opacity-25 dark:border-opacity-80 p-5 bg-buttonColor bg-opacity-5 dark:bg-opacity-20 rounded-md">
                              {file && obj?.exchange === exchangesId ? (
                                <div className="relative">
                                  {!loading ? (
                                    <div className="grid grid-cols-5">
                                      <div className="col-span-1">
                                        <div className="w-full bg-white dark:bg-bgDark rounded-md shadow-md p-3">
                                          <div className="h-[8vh] w-full bg-[#F5F5F5] dark:bg-darkSidebar border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center">
                                            <FaFileCsv className="w-8 h-8 text-[#4B4B4B] dark:text-lightGreyText mx-auto" />
                                          </div>
                                          <div className="mt-2 text-clip">
                                            <h5 className="text-sm font-proxima-semibold text-black dark:text-white text-center line-clamp-1">
                                              {fileName}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="absolute top-0 left-0  items-center w-full h-full">
                                      <div className="bg-white border border-[#E5E5E5] rounded-lg shadow-lg p-3 w-full">
                                        <p className="text-sm font-proxima-semibold text-black text-center">
                                          Please wait while we fetch the data
                                          from the CSV file...
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {/* MAKE HIDDEN AS FLEX WHILE INTEGRATING */}
                                </div>
                              ) : (
                                <div className="text-center">
                                  <FaFileUpload className="w-7 h-7 text-[#4B4B4B] dark:text-lightGreyText mx-auto" />
                                  <h2 className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white mt-2">
                                    Choose CSV file from your computer and click
                                    on
                                    <br />
                                    the upload button to fetch the data.
                                  </h2>
                                  <Button className="text-sm bg-buttonColor py-1 px-4 text-white font-proxima-regular mt-2 normal-case font-normal rounded">
                                    Choose file
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>

                        {/* NOTE */}
                        <div className="mt-3">
                          <p className="text-sm font-proxima-semibold text-greyText dark:text-sidebarText">
                            Note:
                          </p>
                          <ul className="ml-5 mt-1 list-decimal">
                            <li className="text-sm font-proxima-regular text-greyText dark:text-sidebarText">
                              Please remove the user details from the CSV file
                              before you upload here.
                            </li>
                            <li className="text-sm font-proxima-regular text-greyText dark:text-sidebarText mt-1">
                              Please upload all previous years' files first, and
                              ensure the latest file is uploaded last.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            );
          })
        ) : (
          <div className="bg-white dark:bg-darkSidebar w-full shadow-lg border border-[#E5E5E5] dark:border-darkBorder p-5 rounded-md">
            <p className="text-base font-proxima-bold text-black dark:text-white">
              No account connected. Connect your account to upload your CSV.
            </p>
          </div>
        )}
      </div>

      {/* FILE UPLOAD DIALOG */}
      <Dialog
        open={fileUploadDialog}
        handler={() => setFileUploadDialog(!fileUploadDialog)}
        size="lg"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-borderGrey flex items-center justify-between">
            <h5 className="text-base font-proxima-semibold text-black tracking-tight">
              Upload CSV file
            </h5>
            <Button
              className="p-2 shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 rounded-full transition-all duration-300 ease-in-out"
              onClick={() => setFileUploadDialog(!fileUploadDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-2 gap-5">
              <div className="col-span-1">
                <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-borderGrey rounded-md p-5">
                  <h2 className="text-base font-proxima-bold text-black dark:text-white leading-relaxed">
                    How to import trades from Upstox
                  </h2>
                  <ul className="ml-5 mt-2 list-decimal">
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-2">
                      Login to the upstox account using your credentials.
                    </li>
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                      Once logged in, find the option for trade reports. In
                      upstox, it might be under the "Reports" or "Backoffice"
                      section.
                    </li>
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                      Look for an option to generate a trade report in CSV
                      format.
                    </li>
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                      Download the trade report. The report will contain details
                      of your trades.
                    </li>
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                      After importing, review the data to ensure all trades and
                      relevant information are correctly captured.
                    </li>
                    <li className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText first:mt-0 mt-1">
                      Remove the user details from the CSV file and upload it
                      here.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-span-1">
                <div className="border border-borderGrey rounded-md shadow h-full p-5 flex items-center">
                  <div>
                    <h5 className="text-base font-proxima-bold text-black dark:text-white leading-relaxed">
                      Upload CSV
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      <span className="font-proxima-bold">Note:</span>
                    </p>
                    <ul className="ml-5 mt-3 list-decimal">
                      <li className="text-sm font-proxima-regular text-greyText">
                        Please remove the user details from the CSV file before
                        you upload here.
                      </li>
                    </ul>
                    <div className="mt-5 border border-dashed border-buttonColor border-opacity-25 dark:border-opacity-80 p-5 bg-buttonColor bg-opacity-5 dark:bg-opacity-20 rounded-md">
                      <div className="text-center">
                        <FaFileUpload className="w-7 h-7 text-[#4B4B4B] dark:text-lightGreyText mx-auto" />
                        <h2 className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white mt-2">
                          Upload CSV file from your computer
                        </h2>
                        <Button className="text-sm bg-buttonColor py-1 px-4 text-white font-proxima-regular mt-2 normal-case font-normal rounded">
                          Upload file
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default FileUpload;
