/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import Skeleton from "react-loading-skeleton";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Tab,
} from "@material-tailwind/react";

/* ICONS */
import { FiDownload } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";

/* ICONS */
import { getDashboard, getTrade, getTransaction } from "../../../service/api";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { Link } from "react-router-dom";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const TradeLog = () => {
  /* RANGEPICKER VARIABLES */
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [calendarData, setCalendarData] = useState([]);

  const { selectedExchange } = useSelector((state) => state.user);
  const date = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [datepicker, setDatepicker] = useState(false);
  const [tradeData, setTradeData] = useState();
  const [filterTradeData, setFilterTradeData] = useState();
  const [totalTrade, setTotalTrade] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    if (search !== "") {
      let searchField = search.toLowerCase();
      let arr = filterTradeData?.filter((obj) =>
        obj?.tickerName.toLowerCase().startsWith(searchField)
      );
      setTradeData(arr);
    } else {
      setTradeData(filterTradeData);
    }
  }, [search]);
  const handleData = () => {
    setLoading(true);
    let params = { startDate: date?.startDate, endDate: date?.endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getDashboard(params)
      .then((res) => {
        if (res.status) {
          setCalendarData(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => {});
  };

  const getTradeData = () => {
    setLoading(true);
    let params = {
      page: 1,
      sizePerPage: 1000000,
      startDate: date.startDate,
      endDate: date.endDate,
    };
    if (search) {
      params.search = search;
    }
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getTrade(params)
      .then((res) => {
        if (res?.status) {
          setTradeData(res?.data?.docs);
          setFilterTradeData(res?.data?.docs);
          setTotalTrade(res?.data?.totalDocs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
  const handleSearch = () => {
    getTradeData();
    getTransactionData();
  };
  /* SEARCH FIELD */
  const SearchField = (props) => {
    return (
      <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
        <IoSearch
          className="w-4 h-4 text-[#777] dark:text-lightGreyText"
          onClick={handleSearch}
        />
        <input
          type="text"
          placeholder={props.placeholder}
          // name={props.varName}
          // id={"props.varID"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          // value={props.value}
          // onChange={props.onChange}
          className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-bgDark dark:text-white"
        />
      </div>
    );
  };

  /* TRANSACTION DATA */
  const getTransactionData = () => {
    setLoading(true);
    let params = {
      page: 1,
      isOpen: true,
      sizePerPage: 1000000,
      startDate: date.startDate,
      endDate: date.endDate,
    };
    if (search) {
      params.search = search;
    }
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getTrade(params)
      .then((res) => {
        if (res?.status) {
          setTransactionData(res?.data?.docs);
          setTotalTrade(res?.data?.totalDocs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    getTradeData();
    handleData();
    // getTransactionData();
  }, [selectedExchange, date.startDate, date.endDate]);

  // const caseInsensitiveSort = (rowA, rowB) => {
  //   const a = rowA.profit
  //   const b = rowB.profit

  //   if (a > b) {
  //     return 1
  //   }

  //   if (b > a) {
  //     return -1
  //   }

  //   return 0
  // }

  /* COLUMNS */
  const columns = [
    {
      name: "Open Date",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.date}
          </span>
        ),
    },
    {
      name: "Symbol",
      grow: 2,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-[15vh] h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-[15vh] h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.tickerName}
          </span>
        ),
    },
    {
      name: "Side",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row?.side}
          </span>
        ),
    },
    {
      name: "Status",
      grow: 1,
      selector: (row) =>
        row.profit > 0 ? (
          loading ? (
            <>
              <div className="block dark:hidden">
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-20 h-5"
                />
              </div>
              <div className="hidden dark:block">
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-20 h-5"
                />
              </div>
            </>
          ) : (
            <div className="bg-green-500 bg-opacity-20 rounded-md py-2 px-4 flex items-center justify-center">
              <p className="text-[1.4vh] font-proxima-semibold text-green-500 leading-none">
                WIN
              </p>
            </div>
          )
        ) : loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <div className="bg-red-500 bg-opacity-20 rounded-md py-2 px-4 flex items-center justify-center">
            <p className="text-[1.4vh] font-proxima-semibold text-red-500 leading-none">
              LOSS
            </p>
          </div>
        ),
    },
    {
      name: "Close Date",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.date}
          </span>
        ),
    },
    {
      name: "Entry Price (₹)",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row?.side === "LONG"
              ? parseFloat(parseFloat(row?.buyPrice).toFixed(2)).toLocaleString(
                  "en-IN"
                )
              : parseFloat(
                  parseFloat(row?.sellPrice).toFixed(2)
                ).toLocaleString("en-IN")}
          </span>
        ),
    },
    {
      name: "Exit Price (₹)",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row?.side === "LONG"
              ? parseFloat(
                  parseFloat(row?.sellPrice).toFixed(2)
                ).toLocaleString("en-IN")
              : parseFloat(parseFloat(row?.buyPrice).toFixed(2)).toLocaleString(
                  "en-IN"
                )}
          </span>
        ),
    },
    {
      name: "Net Profit (₹)",
      grow: 1,
      sortable: true,
      sortFunction: (a, b) => a.profit - b.profit,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span
            className={`text-sm font-proxima-bold ${
              parseFloat(row.profit) < 0 ? "text-red-500" : "text-green-500"
            } `}
          >
            {parseFloat(row.profit).toLocaleString("en-IN")}
          </span>
        ),
    },
    {
      name: "Trade ROI (%)",
      grow: 1,
      sortable: true,
      sortFunction: (a, b) => {
        const roiA =
          (a.profit * 100) / (a.side === "LONG" ? a.buyAmount : a.sellAmount);
        const roiB =
          (b.profit * 100) / (b.side === "LONG" ? b.buyAmount : b.sellAmount);
        return roiA - roiB;
      },
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span
            className={`text-sm font-proxima-bold ${
              parseFloat(
                (
                  (row?.profit * 100) /
                  (row?.side === "LONG" ? row?.buyAmount : row?.sellAmount)
                )
                  .toFixed(2)
                  .toLocaleString("en-IN") * 100
              ) < 0
                ? "text-red-500"
                : "text-green-500"
            } text-black`}
          >
            {parseFloat(
              parseFloat(
                (row?.profit * 100) /
                  (row?.side === "LONG" ? row?.buyAmount : row?.sellAmount)
              ).toFixed(2)
            ).toLocaleString("en-IN")}
          </span>
        ),
    },
    {
      name: "Duration",
      grow: 1,
      width: "15vh",
      sortable: true,
      sortFunction: (a, b) => {
        const timeToSeconds = (timeString) => {
          const [hours, minutes, seconds] = timeString.split(":").map(Number);
          return hours * 3600 + minutes * 60 + seconds;
        };
        return timeToSeconds(a.time) - timeToSeconds(b.time);
      },
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.time}
          </span>
        ),
    },
    {
      name: "Actions",
      grow: 1,
      selector: (row) => (
        <Link
          to="/trade-details"
          className="block font-proxima-semibold text-buttonColor text-sm"
          onClick={() => dispatch(actions.setSelectedTrade(row))}
        >
          View details
        </Link>
      ),
    },
  ];

  const columns2 = [
    {
      name: "Open Date",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.date}
          </span>
        ),
    },
    {
      name: "Symbol",
      grow: 2,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-[15vh] h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-[15vh] h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row.tickerName}
          </span>
        ),
    },
    {
      name: "Entry Price",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {parseFloat(row?.price).toLocaleString("en-IN")}
          </span>
        ),
    },
    {
      name: "Volume",
      grow: 1,
      selector: (row) =>
        loading ? (
          <>
            <div className="block dark:hidden">
              <Skeleton
                baseColor="#E5E5E5"
                highlightColor="#F5F5F5"
                className="w-20 h-5"
              />
            </div>
            <div className="hidden dark:block">
              <Skeleton
                baseColor="#141418"
                highlightColor="#232327"
                className="w-20 h-5"
              />
            </div>
          </>
        ) : (
          <span className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
            {row?.buyQuantity}
          </span>
        ),
    },
  ];

  /* ALL TRADES */
  const AllTrades = () => {
    return (
      <>
        <div className="block xl:flex lg:flex items-center justify-end">
          {/* <SearchField
            placeholder="Search for trades"
            name="tradeSearch"
            id="tradeSearch"
          /> */}
          {/* <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
            <IoSearch
              className="w-4 h-4 text-[#777] dark:text-lightGreyText"
              onClick={handleSearch}
            />
            <input
              type="text"
              placeholder={"Search for trades"}
              // name="tradeSearch"
              // id="tradeSearch"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-bgDark dark:text-white"
            />
          </div> */}
          {/* RANGE PICKER */}
          <div className="relative mt-5 xl:mt-0 lg:mt-0">
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-12 right-0 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  dragSelectionEnabled
                  showPreview
                  inputRanges={[]}
                  onChange={(e) => {
                    if (e?.selection) {
                      setStartDate(
                        moment(e.selection.startDate).format("MM/DD/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("MM/DD/YYYY")
                      );
                    }
                    setSelectionRange(e?.selection);
                  }}
                  className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        {/* TRADES TABLE */}
        <div className="mt-5 block dark:hidden light-pagination">
          <DataTable
            columns={columns}
            data={tradeData ? tradeData : []}
            className="trades-table"
            responsive
            pagination
            paginationTotalRows={tradeData?.length}
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
        <div className="mt-5 hidden dark:block dark-pagination">
          <DataTable
            columns={columns}
            data={tradeData ? tradeData : []}
            className="trades-table dark"
            responsive
            pagination
            paginationTotalRows={tradeData?.length}
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
      </>
    );
  };

  /* OPEN TRADES */
  const OpenTrades = () => {
    return (
      <>
        <div className="block xl:flex lg:flex items-center justify-end">
          <SearchField
            placeholder="Search for trades"
            name="tradeSearch"
            id="tradeSearch"
          />
          {/* RANGE PICKER */}
          <div className="relative mt-5 xl:mt-0 lg:mt-0">
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-12 right-0 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  dragSelectionEnabled
                  showPreview
                  inputRanges={[]}
                  onChange={(e) => {
                    if (e?.selection) {
                      setStartDate(
                        moment(e.selection.startDate).format("MM/DD/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("MM/DD/YYYY")
                      );
                    }
                    setSelectionRange(e?.selection);
                  }}
                  className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        {/* TRADES TABLE */}
        <div className="mt-5 block dark:hidden">
          <DataTable
            columns={columns2}
            data={transactionData ? transactionData : []}
            className="trades-table"
            responsive
            pagination
            paginationTotalRows={transactionData?.length}
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-[#E5E5E5] py-5">
                <p className="text-sm font-proxima-semibold text-black text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
        <div className="mt-5 hidden dark:block dark-pagination">
          <DataTable
            columns={columns}
            data={tradeData ? tradeData : []}
            className="trades-table dark"
            responsive
            pagination
            paginationTotalRows={tradeData?.length}
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
      </>
    );
  };

  /* BUY TRADES */
  const WinTrades = () => {
    return (
      <>
        <div className="block xl:flex lg:flex items-center justify-end">
          {/* <SearchField
            placeholder="Search for trades"
            name="tradeSearch"
            id="tradeSearch"
          /> */}
          {/* RANGE PICKER */}
          <div className="relative mt-5 xl:mt-0 lg:mt-0">
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-12 right-0 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  dragSelectionEnabled
                  showPreview
                  inputRanges={[]}
                  onChange={(e) => {
                    if (e?.selection) {
                      setStartDate(
                        moment(e.selection.startDate).format("MM/DD/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("MM/DD/YYYY")
                      );
                    }
                    setSelectionRange(e?.selection);
                  }}
                  className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        {/* TRADES TABLE */}
        <div className="mt-5 block dark:hidden">
          <DataTable
            columns={columns}
            data={
              tradeData ? tradeData?.filter((res) => res.type === "WIN") : []
            }
            className="trades-table"
            responsive
            pagination
            paginationTotalRows={
              tradeData?.filter((res) => res.type === "WIN")?.length
            }
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-[#E5E5E5] py-5">
                <p className="text-sm font-proxima-semibold text-black text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
        <div className="mt-5 hidden dark:block dark-pagination">
          <DataTable
            columns={columns}
            data={
              tradeData ? tradeData?.filter((res) => res.type === "WIN") : []
            }
            className="trades-table dark"
            responsive
            pagination
            paginationTotalRows={
              tradeData?.filter((res) => res.type === "WIN")?.length
            }
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
      </>
    );
  };

  /* SELL TRADES */
  const LossTrades = () => {
    return (
      <>
        <div className="block xl:flex lg:flex items-center justify-end">
          {/* <SearchField
            placeholder="Search for trades"
            name="tradeSearch"
            id="tradeSearch"
          /> */}
          {/* RANGE PICKER */}
          <div className="relative mt-5 xl:mt-0 lg:mt-0">
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-12 right-0 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  dragSelectionEnabled
                  inputRanges={[]}
                  showPreview
                  onChange={(e) => {
                    if (e?.selection) {
                      setStartDate(
                        moment(e.selection.startDate).format("MM/DD/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("MM/DD/YYYY")
                      );
                    }
                    setSelectionRange(e?.selection);
                  }}
                  className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        {/* TRADES TABLE */}
        <div className="mt-5 block dark:hidden">
          <DataTable
            columns={columns}
            data={
              tradeData ? tradeData?.filter((res) => res.type === "LOSS") : []
            }
            className="trades-table"
            responsive
            pagination
            paginationTotalRows={
              tradeData?.filter((res) => res.type === "LOSS")?.length
            }
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-[#E5E5E5] py-5">
                <p className="text-sm font-proxima-semibold text-black text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
        <div className="mt-5 hidden dark:block dark-pagination">
          <DataTable
            columns={columns}
            data={
              tradeData ? tradeData?.filter((res) => res.type === "LOSS") : []
            }
            className="trades-table dark"
            responsive
            pagination
            paginationTotalRows={
              tradeData?.filter((res) => res.type === "LOSS")?.length
            }
            noDataComponent={
              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  There are no trades to display. Please connect your broker to
                  see your previous trades.
                </p>
              </div>
            }
          />
        </div>
      </>
    );
  };

  /* TRADE WIN */
  const TradeWin = (props) => {
    let trade = props?.tradeData;
    let win =
      trade?.filter((res) => res?.profit > 0)?.length !== 0
        ? trade?.filter((res) => res?.profit > 0)?.length
        : 0;
    let loss =
      trade?.filter((res) => res?.profit < 0)?.length !== 0
        ? trade?.filter((res) => res?.profit < 0)?.length
        : 0;
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full block">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Trade win
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Winning trade percentage in total trades
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN((win / (win + loss)) * 100)
                  ? "0.00"
                  : ((win / (win + loss)) * 100)?.toFixed(2)}
                <span className="text-xl">%</span>
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Winning trades
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        {isNaN(win) ? "0.00" : win}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Losing trades
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        {isNaN(loss) ? "0.00" : loss}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* PROFIT FACTOR */
  const ProfitFactor = (props) => {
    let trade = props?.tradeData;
    let win =
      trade?.filter((res) => res?.profit > 0)?.length !== 0
        ? trade
            ?.map((res) => res?.profit)
            ?.filter((res) => res > 0)
            ?.reduce((acc, crr) => acc + +crr)
            ?.toFixed(3)
        : 0;
    let loss =
      trade?.filter((res) => res?.profit < 0)?.length !== 0
        ? trade
            ?.map((res) => res?.profit)
            ?.filter((res) => res < 0)
            ?.reduce((acc, crr) => acc + +crr)
            ?.toFixed(3)
        : 0;

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full block">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Profit factor
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Total profits divided by total losses
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN(win / Math.abs(loss !== 0 ? loss : 1))
                  ? "0.00"
                  : (win / Math.abs(loss !== 0 ? loss : 1))?.toFixed(2)}
              </h2>
            )}
          </div>
          <div className="p-1 py-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Total profit
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-10 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-10 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        ₹
                        {isNaN(win)
                          ? "0.00"
                          : parseFloat(win).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Total loss
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-10 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-10 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        ₹
                        {isNaN(loss)
                          ? "0.00"
                          : parseFloat(loss).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* DAY WIN */
  const DayWin = (props) => {
    let win = props.calendarData?.filter((res) => res?.win > res?.loss);
    let loss = props.calendarData?.filter((res) => res?.win < res?.loss);
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full block">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Days win
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Winning days percentage in total days
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN(
                  ((win?.length / (win?.length + loss?.length)) * 100).toFixed(
                    2
                  )
                )
                  ? "0.00"
                  : (
                      (win?.length / (win?.length + loss?.length)) *
                      100
                    ).toFixed(2)}{" "}
                <span className="text-xl">%</span>
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Winning days
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        {win?.length}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Losing days
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        {loss?.length}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* AVERAGE WIN LOSS TRADE */
  const AvgWinLoss = (props) => {
    let trade = props?.tradeData;
    let cal = props?.calendarData;
    let win =
      cal?.filter((res) => res?.profit > 0)?.length !== 0
        ? cal
            ?.map((res) => res?.profit)
            ?.filter((res) => res > 0)
            ?.reduce((acc, crr) => acc + +crr)
            ?.toFixed(3)
        : 0;
    let loss =
      cal?.filter((res) => res?.profit < 0)?.length !== 0
        ? cal
            ?.map((res) => res?.profit)
            ?.filter((res) => res < 0)
            ?.reduce((acc, crr) => acc + +crr)
            ?.toFixed(3)
        : 0;
    let total = (+win + +loss)?.toFixed(3);
    let totalTrade =
      cal?.filter((res) => res?.total > 0)?.length !== 0
        ? cal
            ?.map((res) => res?.total)
            ?.filter((res) => res > 0)
            ?.reduce((acc, crr) => acc + +crr)
            ?.toFixed(3)
        : 0;

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full block">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Win/Loss Ratio
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Profit on all winning and loosing trades
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-5 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN(
                  trade?.filter((res) => res?.profit > 0)?.length /
                    (trade?.filter((res) => res?.profit < 0)?.length === 0
                      ? 1
                      : trade?.filter((res) => res?.profit < 0)?.length)
                )
                  ? "0.00"
                  : parseFloat(
                      parseFloat(
                        trade?.filter((res) => res?.profit > 0)?.length /
                          (trade?.filter((res) => res?.profit < 0)?.length === 0
                            ? 1
                            : trade?.filter((res) => res?.profit < 0)?.length)
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Total profit
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-10 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-10 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        ₹
                        {isNaN(win)
                          ? "0.00"
                          : parseFloat(win).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Total loss
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-10 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-10 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        ₹
                        {isNaN(loss)
                          ? "0.00"
                          : parseFloat(loss).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Trade Logs | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* PAGE HEADING */}
        <div className="flex items-center justify-between">
          <h1 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-bold text-black dark:text-white tracking-tight leading-none">
            Trade logs
          </h1>
        </div>

        {/* STATS CARD */}
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 mt-5">
          <div className="col-span-1">
            <TradeWin tradeData={tradeData} />
          </div>
          <div className="col-span-1">
            <DayWin calendarData={calendarData} />
          </div>
          <div className="col-span-1">
            <ProfitFactor tradeData={calendarData} />
          </div>
          <div className="col-span-1">
            <AvgWinLoss tradeData={tradeData} calendarData={calendarData} />
          </div>
        </div>

        {/* TABS */}
        <div className="mt-5">
          <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
            <Tabs value="All trades" className="overflow-visible">
              <CardBody className="p-0">
                <div className="border-b border-[#E5E5E5] dark:border-darkBorder">
                  <TabsHeader
                    className="w-auto xl:w-[50vh] lg:w-[50vh] rounded-none p-0 bg-transparent"
                    indicatorProps={{
                      className:
                        "bg-transparent border-b-2 border-buttonColor shadow-none rounded-none text-buttonColor",
                    }}
                  >
                    <Tab
                      value="All trades"
                      key="All trades"
                      className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                      activeClassName="trades-active"
                    >
                      All trades
                    </Tab>
                    {/* <Tab
                      value="Open trades"
                      key="Open trades"
                      className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                      activeClassName="trades-active"
                    >
                      Open trades
                    </Tab> */}
                    <Tab
                      value="Win trades"
                      key="Win trades"
                      className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                      activeClassName="trades-active"
                    >
                      Win trades
                    </Tab>
                    <Tab
                      value="Loss trades"
                      key="Loss trades"
                      className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                      activeClassName="trades-active"
                    >
                      Loss trades
                    </Tab>
                  </TabsHeader>
                </div>
                <div className="p-5">
                  <TabsBody className="p-0 overflow-visible">
                    <TabPanel
                      key="All trades"
                      value="All trades"
                      className="p-0"
                    >
                      <div className="block xl:flex lg:flex items-center justify-end">
                        <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
                          <IoSearch
                            className="w-4 h-4 text-[#777] dark:text-lightGreyText"
                            onClick={handleSearch}
                          />
                          <input
                            type="text"
                            placeholder={"Search for trades"}
                            // name="tradeSearch"
                            // id="tradeSearch"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-bgDark dark:text-white"
                          />
                        </div>
                      </div>
                      <AllTrades />
                    </TabPanel>
                    {/* <TabPanel
                      key="Open trades"
                      value="Open trades"
                      className="p-0"
                    >
                      <OpenTrades />
                    </TabPanel> */}
                    <TabPanel
                      key="Win trades"
                      value="Win trades"
                      className="p-0"
                    >
                      <div className="block xl:flex lg:flex items-center justify-end">
                        <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
                          <IoSearch
                            className="w-4 h-4 text-[#777] dark:text-lightGreyText"
                            onClick={handleSearch}
                          />
                          <input
                            type="text"
                            placeholder={"Search for trades"}
                            // name="tradeSearch"
                            // id="tradeSearch"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-bgDark dark:text-white"
                          />
                        </div>
                      </div>
                      <WinTrades />
                    </TabPanel>
                    <TabPanel
                      key="Loss trades"
                      value="Loss trades"
                      className="p-0"
                    >
                      <div className="block xl:flex lg:flex items-center justify-end">
                        <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
                          <IoSearch
                            className="w-4 h-4 text-[#777] dark:text-lightGreyText"
                            onClick={handleSearch}
                          />
                          <input
                            type="text"
                            placeholder={"Search for trades"}
                            // name="tradeSearch"
                            // id="tradeSearch"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-bgDark dark:text-white"
                          />
                        </div>
                      </div>
                      <LossTrades />
                    </TabPanel>
                  </TabsBody>
                </div>
              </CardBody>
            </Tabs>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default TradeLog;
