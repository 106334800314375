import {
  isSameDay,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  startOfYear,
  endOfYear,
  addYears,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addWeeks,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addWeeks(new Date(), -1)),
  endOfLastWeek: endOfWeek(addWeeks(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
  endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
  startOfFinYear: addMonths(startOfYear(new Date()), 3),
  endOfFinYear: addMonths(endOfYear(new Date()), 3),
  startOfLastFinYear: addMonths(startOfYear(addYears(new Date(), -1)), 3),
  endOfLastFinYear: addMonths(endOfYear(addYears(new Date(), -1)), 3),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "This Quarter",
    range: () => ({
      startDate: defineds.startOfQuarter,
      endDate: defineds.endOfQuarter,
    }),
  },
  {
    label: "Last Quarter",
    range: () => ({
      startDate: defineds.startOfLastQuarter,
      endDate: defineds.endOfLastQuarter,
    }),
  },
  {
    label: "This Year",
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
  {
    label: "This Financial Year",
    range: () => ({
      startDate: defineds.startOfFinYear,
      endDate: defineds.endOfFinYear,
    }),
  },
  {
    label: "Last Financial Year",
    range: () => ({
      startDate: defineds.startOfLastFinYear,
      endDate: defineds.endOfLastFinYear,
    }),
  },
]);
