/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useEffect } from "react";
import { createChart, LineStyle } from "lightweight-charts";

const TradingViewChartDark = ({ candleData, ticker }) => {
  /* CHART CONTAINER USEREF */
  const chartContainerRef = useRef();

  /* USE EFFECT */
  useEffect(() => {
    if (candleData?.length > 0) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        lineStyle: LineStyle.Solid,
        layout: {
          background: {
            color: "#232327",
          },
          textColor: "#FFFFFF",
        },
        grid: {
          horzLines: {
            color: "#424242",
          },
          vertLines: {
            color: "#424242",
          },
        },
      });

      const series = chart.addCandlestickSeries({
        upColor: "green",
        borderUpColor: "green",
        wickUpColor: "green",
        color: "red",
        borderDownColor: "red",
        wickDownColor: "red",
      });

      series.setData(candleData);

      return () => {
        // window.removeEventListener('resize', handleResize);
      };
    }
  }, [candleData]);

  let ele = document.getElementsByClassName("tv-lightweight-charts");
  console.log('ele: ', ele);

  /* IF CONDITION */
  if (ele) {
    for (let i = 0; i < ele.length; i++) {
      if (i === ele.length -1) {
        ele[i].style.display = "none";
      }
    }
  }

  return (
    <div id="container1" className="p-3 h-full">
      {/* TICKER NAME */}
      <h5 className="text-black dark:text-white font-proxima-semibold">
        {ticker}
      </h5>

      {/* CHART CONTAINER */}
      <div
        ref={chartContainerRef}
        className="w-full h-[35vh] xl:h-[58vh] lg:h-[58vh] rounded-lg mt-5"
      />
    </div>
  );
};

const TradingViewChartLight = ({ candleData, ticker }) => {
  /* CHART CONTAINER USEREF */
  const chartContainerRef = useRef();

  /* USE EFFECT */
  useEffect(() => {
    if (candleData?.length > 0) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        lineStyle: LineStyle.Solid,
        grid: {
          horzLines: {
            color: "#E5E5E5",
          },
          vertLines: {
            color: "#E5E5E5",
          },
        },
      });

      const series = chart.addCandlestickSeries({
        upColor: "green",
        borderUpColor: "green",
        wickUpColor: "green",
        color: "red",
        borderDownColor: "red",
        wickDownColor: "red",
      });

      series.setData(candleData);

      return () => {
        // window.removeEventListener('resize', handleResize);
      };
    }
  }, [candleData]);

  let ele = document.getElementsByClassName("tv-lightweight-charts");

  /* IF CONDITION */
  if (ele) {
    for (let i = 0; i < ele.length; i++) {
      if (i !== ele.length - 1) {
        ele[i].style.display = "none";
      }
    }
  }

  return (
    <div id="container1" className="p-3 h-full">
      {/* TICKER NAME */}
      <h5 className="text-black dark:text-white font-proxima-semibold">
        {ticker}
      </h5>

      {/* CHART CONTAINER */}
      <div
        ref={chartContainerRef}
        className="w-full h-[35vh] xl:h-[58vh] lg:h-[58vh] rounded-lg mt-5"
      />
    </div>
  );
};

export { TradingViewChartDark, TradingViewChartLight };
