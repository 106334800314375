/* ICONS */
import {
  MdSpaceDashboard,
  MdBook,
  MdCollectionsBookmark,
  MdInsights,
} from "react-icons/md";
import { BiSolidNotepad } from "react-icons/bi";
import { IoMdPricetags } from "react-icons/io";

const navigationMenu = [
  {
    id: 1,
    link: "/dashboard",
    icon: MdSpaceDashboard,
    name: "Dashboard",
  },
  {
    id: 2,
    link: "/daily-journal",
    icon: MdBook,
    name: "Daily Journal",
  },
  {
    id: 3,
    link: "/trade-logs",
    icon: BiSolidNotepad,
    name: "Trade Log",
  },
  {
    id: 4,
    link: "/reports",
    icon: MdCollectionsBookmark,
    name: "Reports",
  },
  {
    id: 5,
    link: "/setup-management",
    icon: IoMdPricetags,
    name: "Setup Management",
  },
  {
    id: 6,
    link: "/insights",
    icon: MdInsights,
    name: "TT Insights",
  },
];

export default navigationMenu;
