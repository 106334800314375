import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const TradeDistributionDay = (props) => {
  const [state] = useState({
    series: [{ name: "Total Trades", data: props.daysData }],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "80%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        labels: {
          showDuplicates: false,
          hideOverlappingLabels: false,
          formatter: function (value) {
            return Math.ceil(value);
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionDayDark = (props) => {
  const [state] = useState({
    series: [{ name: "Total Trades", data: props.daysData }],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "80%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          showDuplicates: false,
          hideOverlappingLabels: false,
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByDay = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props.performanceByDay,
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "80%",
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        labels: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      // yaxis: {
      //   min: -10000000,
      //   max: 10000000,
      // },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByDayDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props.performanceByDay,
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "80%",
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        labels: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        //   min: -10000000,
        //   max: 10000000,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionWeek = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        // categories: props?.data?.map((obj) => obj?.week),
      },
      colors: ["#d59a4a"],
      tooltip: {},
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionWeekDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        // categories: props?.data?.map((obj) => obj?.week),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByWeek = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        // categories: props?.data?.map((obj) => obj?.week),
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return parseFloat(parseFloat(val).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByWeekDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        // categories: props?.data?.map((obj) => obj?.week),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (val) {
            return parseFloat(parseFloat(val).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeTime = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeTImeDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),

        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
        // stepSize:5,
        // range:5,
        // axisTicks:{show:false},
        labels: {
          // trim:true,
          style: {
            colors: "#FFFFFF",
          },
          // offsetX:3,
        },
        // tickPlacement: 'between',
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeTime = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeTimeDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeDuration = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeDurationDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),

        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeDuration = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeDurationDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toLocaleString("en-IN");
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeSetup = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.setUpTag?.name),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeSetupDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.setUpTag?.name),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeSetup = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.setUpTag?.name),
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeSetupDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.setUpTag?.name),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toLocaleString("en-IN");
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeMistake = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.mistakeTag?.name),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeMistakeDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.mistakeTag?.name),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeMistake = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.mistakeTag?.name),
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeMistakeDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.mistakeTag?.name),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toLocaleString("en-IN");
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeRR = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeRRDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeRR = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeRRDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByWeekExpand = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.week),
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return parseFloat(parseFloat(val).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByWeekDarkExpand = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.week),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (val) {
            return parseFloat(parseFloat(val).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};
const TradeDistributionWeekExpand = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.week),
      },
      colors: ["#d59a4a"],
      tooltip: {},
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionWeekDarkExpand = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.week),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeTimeExpand = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeTImeDarkExpand = (props) => {
  const [state] = useState({
    series: [
      { name: "Total Trades", data: props?.data?.map((obj) => obj?.total) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: Math.max(...props?.data?.map((obj) => obj?.total)),

        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(value).toFixed(0);
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
        // stepSize:5,
        // range:5,
        // axisTicks:{show:false},
        labels: {
          // trim:true,
          style: {
            colors: "#FFFFFF",
          },
          // offsetX:3,
        },
        // tickPlacement: 'between',
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeTimeExpand = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeTimeDarkExpand = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.customTimeRange),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const CommissionByMonth = (props) => {
  const [state] = useState({
    series: [
      { name: "Commissions", data: props?.data?.map((obj) => obj?.commission) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map(
          (obj) => obj?._id?.month + "-" + obj?._id?.year
        ),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const CommissionByMonthDark = (props) => {
  const [state] = useState({
    series: [
      { name: "Commisions", data: props?.data?.map((obj) => obj?.commission) },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map(
          (obj) => obj?._id?.month + "-" + obj?._id?.year
        ),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const CommissionByProfit = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.commission),
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const CommissionByProfitDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.commission),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(0)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const TradeDistributionTradeCRR = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Average Expected RR",
        data: props?.data?.map((obj) => obj?.averageExpectedRisk),
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};
const TradeCRRDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Average Expected RR",
        data: props?.data?.map((obj) => obj?.averageExpectedRisk),
      },
      {
        name: "Average Realized RR",
        data: props?.data?.map((obj) => obj?.averageRealizedRisk),
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      // plotOptions: {
      //   bar: {
      //     borderRadius: 4,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a", "#77B6EA"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};
const TradeCRR = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Average Expected RR",
        data: props?.data?.map((obj) => obj?.averageExpectedRisk),
      },
      {
        name: "Average Realized RR",
        data: props?.data?.map((obj) => obj?.averageRealizedRisk),
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      // plotOptions: {
      //   bar: {
      //     borderRadius: 4,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a", "#77B6EA"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};
const TradeDistributionTradeCRRDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Average Expected RR",
        data: props?.data?.map((obj) => obj?.averageExpectedRisk),
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        // max: props?.data?.map((obj) => obj?.total)?.length + 1,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?._id),
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      colors: ["#d59a4a"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeCRR = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.averageExpectedRisk),
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};

const PerformanceByTradeCRRDark = (props) => {
  const [state] = useState({
    series: [
      {
        name: "Amount",
        data: props?.data?.map((obj) => obj?.profit),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "ProximaNova-Semibold",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          colors: {
            ranges: [
              {
                from: -100000000,
                to: 0,
                color: "#F44336",
              },
              {
                from: 0,
                to: 100000000,
                color: "#4CAF50",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹" + parseFloat(val).toLocaleString("en-IN");
          },
        },
      },
      xaxis: {
        categories: props?.data?.map((obj) => obj?.averageExpectedRisk),
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(1)).toLocaleString(
              "en-IN"
            );
          },
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        // min: -10000000,
        // max: 10000000,
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="bar"
      />
    </div>
  );
};
export {
  TradeDistributionDay,
  TradeDistributionDayDark,
  PerformanceByDay,
  PerformanceByDayDark,
  TradeDistributionWeek,
  TradeDistributionWeekDark,
  PerformanceByWeek,
  PerformanceByWeekDark,
  PerformanceByTradeTimeDark,
  PerformanceByTradeTime,
  TradeDistributionTradeTime,
  TradeDistributionTradeTImeDark,
  PerformanceByTradeDurationDark,
  PerformanceByTradeDuration,
  TradeDistributionTradeDuration,
  TradeDistributionTradeDurationDark,
  PerformanceByTradeRRDark,
  PerformanceByTradeRR,
  TradeDistributionTradeRR,
  TradeDistributionTradeRRDark,
  PerformanceByTradeSetupDark,
  PerformanceByTradeSetup,
  TradeDistributionTradeSetup,
  TradeDistributionTradeSetupDark,
  PerformanceByTradeMistakeDark,
  PerformanceByTradeMistake,
  TradeDistributionTradeMistake,
  TradeDistributionTradeMistakeDark,
  TradeDistributionWeekExpand,
  TradeDistributionWeekDarkExpand,
  PerformanceByWeekExpand,
  PerformanceByWeekDarkExpand,
  PerformanceByTradeTimeDarkExpand,
  PerformanceByTradeTimeExpand,
  TradeDistributionTradeTimeExpand,
  TradeDistributionTradeTImeDarkExpand,
  CommissionByMonth,
  CommissionByMonthDark,
  CommissionByProfit,
  CommissionByProfitDark,
  PerformanceByTradeCRRDark,
  PerformanceByTradeCRR,
  TradeDistributionTradeCRR,
  TradeDistributionTradeCRRDark,
  TradeCRRDark,
  TradeCRR,
};
