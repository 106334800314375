/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* ICONS */
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

/* GRAPHS */
import {
  CumulativeGraph,
  CumulativeGraphDark,
} from "../../components/others/charts/cumulative-graph";
import {
  NetDailyGraph,
  NetDailyGraphDark,
} from "../../components/others/charts/net-daily-graph";

/* SAMPLE EVENTS */
import { getDashboard, getTrade, getTransaction } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/user/actions";
import { Link, useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const Dashboard = () => {
  const { selectedExchange, startDate, endDate, user } = useSelector(
    (state) => state.user
  );

  const [calendarData, setCalendarData] = useState([]);
  const [calendarData2, setCalendarData2] = useState([]);
  const tileData = calendarData2?.map((res) => ({
    start: `${res?.month}/${res?.day}/${res?.year}`,
    end: `${res?.month}/${res?.day}/${res?.year}`,
    data: res,
  }));
  const [tradeData, setTradeData] = useState();
  const [transactionData, setTransactionData] = useState();
  const [loading, setLoading] = useState(false);
  let abc = calendarData?.map((res) => ({
    date: `${res?.month}/${res?.day}/${res?.year}`,
    profit:
      (res?.trade?.map((res) => res?.profit)?.length !== 0
        ? res?.trade
            ?.map((res) => (res?.profit ? res?.profit : 0))
            ?.reduce((acc, crr) => acc + +crr)
            .toFixed(3)
        : 0) -
      (res?.notebookData && res?.notebookData?.exchangeFee
        ? res?.notebookData.exchangeFee
        : 0),
  }));

  let profitLoss = abc
    ? abc.sort((a, b) => new Date(a.date) - new Date(b.date))
    : [];

  const calculateCumulativeProfit = () => {
    let cumulativeProfit = 0;
    const transactionsWithCumulativePL = profitLoss.map((transaction) => {
      cumulativeProfit += parseFloat(transaction.profit);

      return { ...transaction, cumulativeProfit: cumulativeProfit };
    });
    return transactionsWithCumulativePL;
  };

  const profitLossData = calculateCumulativeProfit();

  /* GET TRADING DATA */
  const getTradeData = () => {
    setLoading(true);
    let params = {
      page: 1,
      sizePerPage: 20,
      startDate: startDate,
      endDate: endDate,
    };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getTrade(params)
      .then((res) => {
        if (res?.status) {
          setTradeData(res?.data?.docs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  /* TRANSACTION DATA */
  const getTransactionData = () => {
    let params = {
      isOpen: true,
      page: 1,
      sizePerPage: 20,
    };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getTransaction(params)
      .then((res) => {
        if (res?.status) {
          setTransactionData(res?.data?.docs);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  /* DATA HANDLING */
  const handleData = () => {
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getDashboard(params)
      .then((res) => {
        if (res.status) {
          setCalendarData(res?.data);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  const handleData2 = () => {
    let params = {};
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getDashboard(params)
      .then((res) => {
        if (res.status) {
          setCalendarData2(res?.data);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  useEffect(() => {
    handleData();
    getTradeData();
    getTransactionData();
    handleData2();
  }, [selectedExchange, startDate, endDate]);

  /* NET PROFIT AND LOSS */
  const NetProfitLoss = (props) => {
    let b = props.calendarData?.map((res) => res?.trade)?.flat();
    // let win =
    //   b?.filter((res) => res?.type === "WIN")?.length !== 0
    //     ? b
    //         ?.filter((res) => res?.type === "WIN")
    //         ?.map((res) => (res?.profit ? res?.profit : 0))
    //         ?.reduce((acc, current) => acc + +current)
    //     : 0;
    // let loss =
    //   b?.filter((res) => res?.type === "LOSS")?.length !== 0
    //     ? b
    //         ?.filter((res) => res?.type === "LOSS")
    //         ?.map((res) => (res?.profit ? res?.profit : 0))
    //         ?.reduce((acc, current) => acc + +current)
    //     : 0;
    let commision =
      calendarData?.length > 0
        ? calendarData
            ?.map((obj) =>
              obj?.notebookData && obj?.notebookData?.exchangeFee
                ? Number(obj?.notebookData?.exchangeFee)
                : 0
            )
            ?.reduce((acc, current) => acc + +current)
        : 0;
    let total =
      b?.length > 0
        ? b
            ?.map((res) => (res?.profit ? Number(res?.profit) : 0))
            ?.reduce((acc, current) => Number(acc) + +Number(current))
            .toFixed(2) - commision
        : 0;
    let win = b?.filter((res) => res?.type === "WIN");
    let loss = b?.filter((res) => res?.type === "LOSS");
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Net P&L (₹)
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Total profit and loss for all closed trades
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-3 w-1/2 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-3 w-1/2 h-8"
                  />
                </div>
              </>
            ) : (
              <h2
                className={`text-3xl font-proxima-bold ${
                  parseFloat(total) < 0 ? "text-red-500" : "text-green-500"
                } leading-none pt-5`}
              >
                {isNaN(total)
                  ? "0.00"
                  : parseFloat(parseFloat(total).toFixed(2)).toLocaleString(
                      "en-IN"
                    )}
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-transparent w-full rounded-lg flex items-center justify-between">
              <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                Total trades closed
              </p>
              {loading ? (
                <>
                  <div className="block dark:hidden">
                    <Skeleton
                      baseColor="#E5E5E5"
                      highlightColor="#F5F5F5"
                      className="w-5 h-4"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Skeleton
                      baseColor="#141418"
                      highlightColor="#232327"
                      className="w-5 h-4"
                    />
                  </div>
                </>
              ) : (
                <p className="text-sm font-proxima-bold text-black dark:text-white">
                  {win?.length + loss?.length}
                </p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* TRADE WIN */
  const TradeWin = (props) => {
    let b = props.calendarData?.map((res) => res?.trade)?.flat();
    let win = b?.filter((res) => res?.type === "WIN");
    let loss = b?.filter((res) => res?.type === "LOSS");

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Trade win
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Winning trade percentage in total trades
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN(
                  ((win?.length / (win?.length + loss?.length)) * 100).toFixed(
                    2
                  )
                )
                  ? "0.00"
                  : (
                      (win?.length / (win?.length + loss?.length)) *
                      100
                    ).toFixed(2)}
                <span className="text-xl">%</span>
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-transparent w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Winning trades
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        {win?.length}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        Losing trades
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        {loss?.length}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* TRADE WIN */
  const DayWin = (props) => {
    let win = profitLossData?.filter((res) => res?.profit > 0);
    let loss = profitLossData?.filter((res) => res?.profit < 0);
    let winTotal =
      profitLossData?.length > 0 &&
      profitLossData
        ?.filter((res) => res?.profit > 0)
        ?.map((obj) => obj?.profit)?.length !== 0
        ? profitLossData
            ?.filter((res) => res?.profit > 0)
            ?.map((obj) => obj?.profit)
            ?.reduce((acc, crr) => acc + +crr)
        : 0;

    let lossTotal =
      profitLossData?.length > 0 &&
      profitLossData
        ?.filter((res) => res?.profit < 0)
        ?.map((obj) => obj?.profit)?.length !== 0
        ? profitLossData
            ?.filter((res) => res?.profit < 0)
            ?.map((obj) => obj?.profit)
            ?.reduce((acc, crr) => acc + +crr)
        : 0;

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
        <CardBody className="p-0">
          <div className="p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              {/* Days win */}
              Profit factor
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              {/* Winning days percentage in total days */}
              Total profits divided by total losses
            </p>
            {loading ? (
              <>
                <div className="block dark:hidden">
                  <Skeleton
                    baseColor="#E5E5E5"
                    highlightColor="#F5F5F5"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
                <div className="hidden dark:block">
                  <Skeleton
                    baseColor="#141418"
                    highlightColor="#232327"
                    className="mt-3 w-1/3 h-8"
                  />
                </div>
              </>
            ) : (
              <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none pt-5">
                {isNaN(winTotal / Math.abs(lossTotal !== 0 ? lossTotal : 1))
                  ? "0.00"
                  : (
                      winTotal / Math.abs(lossTotal !== 0 ? lossTotal : 1)
                    )?.toFixed(2)}
              </h2>
            )}
          </div>
          <div className="p-1 pt-0">
            <div className="px-5 py-2 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-transparent w-full rounded-lg">
              <div className="grid grid-cols-2 gap-8">
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        {/* Winning days */}
                        Total profit
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-green-500">
                        {/* {win?.length} */}₹
                        {isNaN(winTotal)
                          ? "0.00"
                          : parseFloat(winTotal).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p className="text-[1.4vh] font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText leading-none">
                        {/* Losing days */}
                        Total loss
                      </p>
                    </div>
                    {loading ? (
                      <>
                        <div className="block dark:hidden">
                          <Skeleton
                            baseColor="#E5E5E5"
                            highlightColor="#F5F5F5"
                            className="w-5 h-4"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <Skeleton
                            baseColor="#141418"
                            highlightColor="#232327"
                            className="w-5 h-4"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-sm font-proxima-bold text-red-500">
                        {/* {loss?.length} */}₹
                        {isNaN(lossTotal)
                          ? "0.00"
                          : parseFloat(lossTotal).toLocaleString("en-IN", {
                              notation: "compact",
                            })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* CUMULATIVE PROFIT AND LOSS */
  const CumulativeProfitLoss = () => {
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Daily net cumulative P&L
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Your total account P&L accumulated over the course of each trading
              day
            </p>
          </div>
          <div className="p-0 block dark:hidden">
            <CumulativeGraph profitLoss={profitLossData} height="h-[40vh]" />
          </div>
          <div className="p-0 hidden dark:block">
            <CumulativeGraphDark
              profitLoss={profitLossData}
              height="h-[40vh]"
            />
          </div>
        </CardBody>
      </Card>
    );
  };

  /* NET DAILY PROFIT AND LOSS */
  const NetDailyProfitLoss = () => {
    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5">
            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
              Net daily P&L
            </h2>
            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
              Total net P&L for each trading day on the day it is realized
            </p>
          </div>
          <div className="p-0 block dark:hidden">
            <NetDailyGraph profitLoss={profitLossData} height="h-[40vh]" />
          </div>
          <div className="p-0 hidden dark:block">
            <NetDailyGraphDark profitLoss={profitLossData} height="h-[40vh]" />
          </div>
        </CardBody>
      </Card>
    );
  };

  /* TRADES HISTORY */
  const TradesHistory = (props) => {
    const [activeTab, setActiveTab] = useState("Recent Trades");
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
      if (activeTab === "Recent Trades") {
        if (props.tradeData) {
          setHistoryData(props.tradeData);
        }
      } else {
        if (props.transactionData) {
          setHistoryData(props.transactionData);
        }
      }
    }, [activeTab]);

    const columns = [
      {
        name: "Close date",
        width: "15vh",
        selector: (row) =>
          loading ? (
            <>
              <div className="block dark:hidden">
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-20 h-5"
                />
              </div>
              <div className="hidden dark:block">
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-20 h-5"
                />
              </div>
            </>
          ) : (
            <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText">
              {row?.date}
            </span>
          ),
      },
      {
        name: "Symbol",
        selector: (row) =>
          loading ? (
            <>
              <div className="block dark:hidden">
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-[15vh] h-5"
                />
              </div>
              <div className="hidden dark:block">
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-[15vh] h-5"
                />
              </div>
            </>
          ) : (
            <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-lightGreyText">
              {row?.tickerName}
            </span>
          ),
      },
      {
        name: activeTab === "Recent Trades" ? "Net P&L (₹)" : "Quantity",
        width: "15vh",
        selector: (row) =>
          loading ? (
            <>
              <div className="block dark:hidden">
                <Skeleton
                  baseColor="#E5E5E5"
                  highlightColor="#F5F5F5"
                  className="w-20 h-5"
                />
              </div>
              <div className="hidden dark:block">
                <Skeleton
                  baseColor="#141418"
                  highlightColor="#232327"
                  className="w-20 h-5"
                />
              </div>
            </>
          ) : (
            <span
              className={`text-sm font-proxima-semibold ${
                parseFloat(
                  activeTab === "Recent Trades"
                    ? row?.profit
                      ? row?.profit
                      : "-"
                    : row?.quantity
                ) < 0
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              {activeTab === "Recent Trades"
                ? parseFloat(parseFloat(row?.profit).toFixed(2)).toLocaleString(
                    "en-IN"
                  )
                : parseFloat(
                    parseFloat(row?.quantity).toFixed(2)
                  ).toLocaleString("en-IN")}
            </span>
          ),
      },
    ];

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 flex items-center justify-between">
            <div>
              <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                Trades History
              </h2>
              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                All your trades and open positions
              </p>
            </div>
            <div className="flex items-center">
              <Button
                className={`text-sm font-proxima-semibold ${
                  activeTab === "Recent Trades"
                    ? "bg-buttonColor bg-opacity-10 dark:bg-opacity-100"
                    : "bg-white dark:bg-darkSidebar"
                }  py-2 px-4 normal-case text-black dark:text-white shadow-none hover:shadow-none transition-all duration-300 ease-linear`}
                onClick={() => setActiveTab("Recent Trades")}
              >
                Recent Trades
              </Button>
              <Button
                className={`text-sm font-proxima-semibold ${
                  activeTab === "Open Positions"
                    ? "bg-buttonColor bg-opacity-10 dark:bg-opacity-100"
                    : "bg-white dark:bg-darkSidebar"
                }  py-2 px-4 normal-case text-black dark:text-white shadow-none hover:shadow-none transition-all duration-300 ease-linear`}
                onClick={() => setActiveTab("Open Positions")}
              >
                Open Positions
              </Button>
            </div>
          </div>
          <div className="p-5 max-h-[38vh] overflow-auto overflow-x-auto hide-scrollbar">
            <div className="block dark:hidden">
              <DataTable
                columns={columns}
                data={historyData}
                responsive
                noDataComponent={
                  <div className="py-5 font-proxima-semibold rounded-lg text-sm text-black dark:text-white bg-[#F5F5F5] w-full text-center">
                    There are no trades to display.
                  </div>
                }
                className={`react-datatable rounded-lg ${
                  historyData.length === 0 && "no-data"
                }`}
              />
            </div>
            <div className="hidden dark:block">
              <DataTable
                columns={columns}
                data={historyData}
                responsive
                noDataComponent={
                  <div className="py-5 font-proxima-semibold rounded-lg text-sm text-black dark:text-white bg-bgDark w-full text-center">
                    There are no trades to display.
                  </div>
                }
                className={`react-datatable dark rounded-lg ${
                  historyData.length === 0 && "no-data"
                }`}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* CALENDAR */
  const TradesCalendar = () => {
    const { startDate, endDate } = useSelector((state) => state.user);
    const [date, setDate] = useState(moment().format("MM/DD/YYYY"));

    useEffect(() => {
      setDate(
        moment(
          `${endDate?.split("/")[1]}/${endDate?.split("/")[0]}/${
            endDate?.split("/")[2]
          }`
        )
      );
    }, [endDate]);

    /* EVENT TILE */
    const EventTile = (props) => {
      const dispatch = useDispatch();
      const navigate = useNavigate();
      // let amountWin =
      //   props.events.event.data.trade?.filter((res) => res?.type === "WIN")
      //     .length !== 0
      //     ? props.events.event.data.trade
      //         ?.filter((res) => res?.type === "WIN")
      //         ?.map((res) => res?.profit)
      //         ?.reduce((acc, current) => acc + +current)
      //     : 0;
      // let amountLoss =
      //   props.events.event.data.trade?.filter((res) => res?.type === "LOSS")
      //     .length !== 0
      //     ? props.events.event.data.trade
      //         ?.filter((res) => res?.type === "LOSS")
      //         ?.map((res) => res?.profit)
      //         ?.reduce((acc, current) => acc + +current)
      //     : 0;
      // let total = (amountWin + amountLoss).toFixed(2);
      let total =
        (props.events.event.data.trade.length !== 0
          ? props.events.event.data.trade
              ?.map((res) => (res?.profit ? res.profit : 0))
              ?.reduce((acc, current) => acc + +current)
          : 0) -
        (props?.events?.event?.data?.notebookData &&
        props?.events?.event?.data?.notebookData?.exchangeFee
          ? props?.events?.event?.data?.notebookData?.exchangeFee
          : 0);

      return loading ? (
        <>
          <div className="block dark:hidden">
            <Skeleton
              baseColor="#E5E5E5"
              highlightColor="#F5F5F5"
              className="w-full h-12 rounded"
            />
          </div>
          <div className="hidden dark:block">
            <Skeleton
              baseColor="#141418"
              highlightColor="#232327"
              className="w-full h-12 rounded"
            />
          </div>
        </>
      ) : (
        <div
          onClick={() => {
            // dispatch(
            //   actions.setSelectedDay(
            //     moment(props?.events?.event?.start)?.format("DD/MM/YYYY")
            //   )
            // );
            navigate("/daily-journal");
          }}
          className={`bg-green-100 ${
            total < 0
              ? "bg-red-100 dark:bg-red-500 border-red-500"
              : "bg-green-100 dark:bg-green-500 border-green-500"
          } bg-opacity-30 w-full rounded shadow-lg border text-black px-3 py-2`}
        >
          <div className="flex items-center justify-between">
            {total < 0 ? (
              <FaArrowTrendDown className="w-7 h-7 text-red-500 dark:text-white" />
            ) : (
              <FaArrowTrendUp className="w-7 h-7 text-green-500 dark:text-white" />
            )}

            <div className="text-right hidden xl:block lg:block">
              <h5
                className={`text-base font-proxima-bold ${
                  total < 0
                    ? "text-red-500 dark:text-white"
                    : "text-green-500 dark:text-white"
                } leading-none`}
              >
                {parseFloat(parseFloat(total).toFixed(2)).toLocaleString(
                  "en-IN"
                )}
              </h5>
              <p className="text-[1.4vh] text-[#777777] dark:text-white font-proxima-regular leading-none mt-1">
                {props?.events?.event?.data?.total} Trade
                {props?.events?.event?.data?.total > 1 ? "s" : ""}
              </p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full block">
        <CardBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 flex items-center justify-between">
            <div>
              <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                Trading month
              </h2>
              <p className="text-sm font-proxima-regular hidden xl:block lg:block dark:text-lightGreyText">
                All your trades and open positions
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Button
                className="p-1 rounded-full shadow-none hover:shadow-none bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder flex items-center"
                onClick={() => {
                  setDate(moment(date).add(-1, "month"));
                }}
              >
                <IoIosArrowBack className="w-4 h-4 text-black dark:text-white" />
              </Button>
              <div className="text-center">
                <h5 className="text-base font-proxima-semibold text-black dark:text-white leading-none">
                  {loading ? (
                    <>
                      {" "}
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-1/2 h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-1/2 h-8"
                        />
                      </div>
                    </>
                  ) : (
                    moment(date).format("MMMM YY")
                  )}
                </h5>
                <p
                  onClick={() => setDate(moment(new Date()))}
                  className="text-sm font-proxima-regular mt-1 leading-none dark:text-lightGreyText"
                >
                  {moment(new Date()).format("DD MMMM YYYY")}
                </p>
              </div>
              <Button
                className="p-1 rounded-full shadow-none hover:shadow-none bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder flex items-center"
                onClick={() => {
                  setDate(moment(date).add(1, "month"));
                }}
              >
                <IoIosArrowForward className="w-4 h-4 text-black dark:text-white" />
              </Button>
            </div>
          </div>
          <div className="block dark:hidden h-full">
            <Calendar
              localizer={localizer}
              toolbar={false}
              events={tileData?.length > 0 ? tileData : []}
              date={date}
              selectable
              components={{
                event: (event) => <EventTile events={event} />,
              }}
              className="trades-calendar h-[52vh] rounded-lg"
            />
          </div>
          <div className="hidden dark:block h-full">
            <Calendar
              localizer={localizer}
              toolbar={false}
              events={tileData?.length > 0 ? tileData : []}
              date={date}
              components={{
                event: (event) => <EventTile events={event} />,
              }}
              className="trades-calendar-dark h-[52vh] rounded-lg"
            />
          </div>
        </CardBody>
      </Card>
    );
  };

  const [trialBox, setTrialBox] = useState(false);
  useEffect(() => {
    if (user?.subscription?.paymentEndDate) {
      if (moment()?.unix() * 1000 > user?.subscription?.paymentEndDate) {
        setTrialBox(true);
      }
    }
  }, [user]);
  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Dashboard | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* CALENDAR AND IMPORTANT STATS */}
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-0 gap-y-5 xl:gap-5 lg:gap-5">
          <div className="col-span-1">
            <div className="mt-0">
              <NetProfitLoss calendarData={calendarData} />
            </div>
            <div className="mt-5">
              <TradeWin calendarData={calendarData} />
            </div>
            <div className="mt-5">
              <DayWin calendarData={calendarData} />
            </div>
          </div>
          <div className="col-span-3">
            <TradesCalendar />
          </div>
        </div>

        {/* CHARTS */}
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0 gap-y-5 xl:gap-5 lg:gap-5 mt-5">
          {/* <div className="col-span-1">
            <TradesHistory
              tradeData={tradeData}
              transactionData={transactionData}
            />
          </div> */}
          <div className="col-span-1">
            <CumulativeProfitLoss />
          </div>
          <div className="col-span-1">
            <NetDailyProfitLoss />
          </div>
        </div>
      </div>

      {/* TRIAL EXPIRED */}
      <Dialog
        open={trialBox}
        handler={() => setTrialBox(!trialBox)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        dismiss={{
          enabled: false,
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-5">
          <img
            src={require("../../assets/images/free-trial.jpg")}
            className="w-[18vh] h-auto mx-auto"
            alt="30 days free trial"
          />
          <div className="mt-3">
            <h5 className="text-[2.4vh] font-proxima-bold text-black tracking-tight text-center">
              Your free trial has just ended :-(
            </h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText text-center">
              The 30-day trial for your account has just ended. We know everyone
              gets busy, so if you've just forgotten to subscribe to any pricing
              plan, then you can still do it here.
            </p>
          </div>
          <div className="w-fit mx-auto">
            <Link to="/profile#subscription">
              <Button className="mt-5 shadow-none hover:shadow-none bg-logoOcre rounded-md text-white font-proxima-semibold px-5 py-2 normal-case text-base">
                Subscribe now
              </Button>
            </Link>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Dashboard;
