import request from "./request";

// AUTH
export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const register = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/auth/forgot/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const sendOtp = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/auth/send-otp", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const otpVerify = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/auth/forgot/password/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const emailVerify = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/auth/email/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// PROFILE
export const getProfile = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateProfile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updatePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/auth/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getConnectUpstox = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/upstox/url/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectUpstoxLogin = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/upstox/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectUpstox = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/upstox", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//kite
export const getConnectKite = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/kite/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectKite = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/kite", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectFyers = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/fyers/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectFyersLogin = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/fyers/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectKiteLogin = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/kite/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectAOLogin = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/angel-one/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectFyers = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/fyers", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectDhan = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/dhan", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateTokenDhan = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/exchange/dhan/" + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Angle one
export const getConnectconnectAngelOne = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/angel-one/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectAngelOne = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/angel-one", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Motilal
export const getConnectMotilal = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/motilal/url/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const connectMotilal = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/exchange/motilal", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getUserExchange = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Notebook
export const getNotebook = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/notebook", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getNotebookById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/notebook/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addNotebook = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/notebook", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateNotebook = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/notebook/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteNotebook = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/notebook/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Notefolder
export const getNoteFolder = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/notefolder")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addNoteFolder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/notefolder", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateNoteFolder = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/notefolder/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteNoteFolder = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/notefolder/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Transaction
export const getTransaction = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/transaction", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTrade = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addTransaction = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/transaction/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const uploadTrade = (data, id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/trade/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const clearTrade = (id, params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/trade/" + id, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Notefolder
export const getTag = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/tag", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTagById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/tag/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addTag = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/tag", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const UpdateTag = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/tag/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteTag = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/tag/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// DashBoard
export const getDashboard = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/dashboard", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// notes
export const getNotesByDay = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/dashboard-notes", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Reports
export const getByDay = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/day", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getByMonth = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/month", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getByTime = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/tradetime", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getByDuration = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/trade-duration", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getByInstrument = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/instrument", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getByTag = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/tag", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// export const getUserExchanges = () => {
//   return new Promise(async (resolve, reject) => {
//     await request
//       .get("/user/exchange")
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });
// };
export const deleteExchane = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/exchange/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTradeByDay = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/date", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateTrade = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/trade/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const uploadAttByTrade = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/trade/upload/" + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByDayData = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/day", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByWeek = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/week", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeTime = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/tradetime", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeDuration = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/trade-duration", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeSetup = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/setup", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeMistake = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/mistake", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addManualTrade = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/trade/manual", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeRR = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/risk-reward", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradeByTradeRRCompare = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/risk-reward/compare", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCommissionReport = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/report/commission", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const closeTrade = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/trade/close/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getInsights = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/insights", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addAOExchange = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/angel/add", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addDhanExchange = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/dhan/add", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConnectDhanLogin = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/exchange/dhan/connect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const subscriptionPlan = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/subscription", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const subscriptionPlanCF = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/subscription/cashfree", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const unsubscriptionPlan = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/subscription/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const checkSubscription = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/subscription/subscription-details", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("e: ", e);
        reject(e);
      });
  });
};
export const getTradeViewList = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/trade/candle/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};