const Input = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      min={props?.min}
      // pattern="[1-9]{1}[0-9]{9}"
      className="mt-1 w-full block border border-borderGrey dark:border-darkBorder rounded-md text-base font-proxima-semibold px-4 py-2 placeholder:font-proxima-regular placeholder:text-sm focus:outline-none dark:bg-darkSidebar dark:text-white"
    />
  );
};

export default Input;
