import { useState } from "react";

/* ICONS */
import { FaCircleCheck, FaRocket } from "react-icons/fa6";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

import { IoClose } from "react-icons/io5";

const PricingBox = (props) => {
  const [promocodePopup, setPromocodePopup] = useState(false);
  const [showDesc, setShowDesc] = useState(false);

  return (
    <div
      className={`w-full h-full bg-white dark:bg-bgDark ${
        props.selectedPlanName === props?.pId
          ? "border-2 border-logoOcre border-b-8 border-b-logoOcre"
          : "border border-borderGrey dark:border-darkBorder border-b-8 border-b-borderGrey dark:border-b-darkBorder"
      } rounded-xl py-0 pb-5 shadow-md relative`}
    >
      {props.isMonthly &&
        (props.name === "Basic Plan" ||
          props.name === "Professional Plan" ||
          props.name === "Personal Plan") && (
          <div className="w-full bg-black py-1.5 rounded-t-xl">
            <p className="text-sm font-proxima-semibold text-white text-center">
              ✨ Special Launch Offer
            </p>
          </div>
        )}

      {/* PLAN DETAILS */}
      <div className="p-5 border-b border-borderGrey dark:border-darkBorder">
        {/* PLAN PRICE AND NAME */}
        <div className="p-0">
          {(props.name === "Professional Plan" ||
            props.name === "Personal Plan") &&
            props.isMonthly && (
              <div className="p-0">
                <div className="w-fit block dark:hidden badge-gradient rounded-full px-3 py-1">
                  <p className="text-xs font-proxima-semibold text-white">
                    Get 30% off on your first month
                  </p>
                </div>
                <div className="w-fit hidden dark:block bg-logoOcre rounded-full px-3 py-1">
                  <p className="text-xs font-proxima-semibold text-white">
                    Get 30% off on your first month
                  </p>
                </div>
              </div>
            )}

          {props.isMonthly && props.name === "Basic Plan" && (
            <div className="p-0">
              <div className="w-fit block dark:hidden badge-gradient rounded-full px-3 py-1">
                <p className="text-xs font-proxima-semibold text-white">
                  15 days free trial
                </p>
              </div>
              <div className="w-fit hidden dark:block bg-logoOcre rounded-full px-3 py-1">
                <p className="text-xs font-proxima-semibold text-white">
                  15 days free trial
                </p>
              </div>
            </div>
          )}

          {!props.isMonthly && (
            <div className="flex items-center gap-2">
              <div className="block dark:hidden badge-gradient rounded-full px-3 py-1">
                <p className="text-xs font-proxima-semibold text-white">
                  Save {props.annualDiscount}%
                </p>
              </div>

              <div className="hidden dark:block bg-logoOcre rounded-full px-3 py-1">
                <p className="text-xs font-proxima-semibold text-white">
                  Save {props.annualDiscount}%
                </p>
              </div>

              {props.name === "Personal Plan" && (
                <>
                  <div className="flex dark:hidden w-fit badge-gradient rounded-full px-2 py-1 text-white font-proxima-semibold items-center gap-2 text-xs">
                    <FaRocket className="w-3 h-3 text-white" />
                    Recommended
                  </div>
                  <div className="hidden dark:flex w-fit bg-logoOcre rounded-full px-2 py-1 text-white font-proxima-semibold items-center gap-2 text-xs">
                    <FaRocket className="w-3 h-3 text-white" />
                    Recommended
                  </div>
                </>
              )}
            </div>
          )}

          <h5 className="mt-3 text-base font-proxima-semibold text-black dark:text-white">
            {props.name}
          </h5>

          {/* PRICE */}
          <div className="p-0">
            {props.isMonthly ? (
              props.name === "Personal Plan" ||
              props.name === "Professional Plan" ? (
                <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black dark:text-white leading-none">
                  ₹{parseFloat(props.monthlyPrice).toLocaleString("en-IN")}
                  {/* SHOW CUTOFF PRICES */}
                  <span className="text-xl font-proxima-semibold text-red-500 line-through ml-2">
                    ₹
                    {parseFloat(props?.originalMonthlyPrice).toLocaleString(
                      "en-IN"
                    )}
                  </span>
                </h2>
              ) : (
                <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black dark:text-white leading-none">
                  ₹{parseFloat(props.monthlyPrice).toLocaleString("en-IN")}
                </h2>
              )
            ) : (
              <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black dark:text-white leading-none">
                ₹{parseFloat(props.annualPrice).toLocaleString("en-IN")}
                {/* SHOW CUTOFF PRICES */}
                <span className="text-xl font-proxima-semibold text-red-500 line-through ml-2">
                  ₹
                  {parseFloat(props.originalAnnualPrice).toLocaleString(
                    "en-IN"
                  )}
                </span>
              </h2>
            )}
          </div>

          {props.isMonthly ? (
            <p className="text-sm font-proxima-regular text-grey dark:text-sidebarText mt-2">
              * billed monthly
            </p>
          ) : (
            <p className="text-sm font-proxima-regular text-grey dark:text-sidebarText mt-2">
              * billed annually
            </p>
          )}
        </div>

        {/* PLAN DESCRIPTION */}
        <div className="h-[7vh] mt-3">
          <p className="text-sm font-proxima-regular text-grey dark:text-sidebarText">
            {props.description}
          </p>
        </div>
      </div>

      {/* FEATURES */}
      <div className="h-[35vh] p-5">
        <h4 className="text-lg font-proxima-bold text-black dark:text-white">
          This plan includes:
        </h4>

        {props.features.map((item) => (
          <div
            className="flex items-center gap-3 mt-3 first:mt-0"
            key={item.id}
          >
            <FaCircleCheck className="w-5 h-5 text-logoOcre" />
            <p className="text-sm font-proxima-regular text-grey dark:text-sidebarText">
              {item.title}
            </p>
          </div>
        ))}
      </div>

      {/* WAITLIST BUTTON */}
      <div className="px-5">
        {props?.myPlan === props?.pId ? (
          <Button
            disabled={props.planLoader}
            onClick={() => props.unsubscriptionMyPlan(props?.sId)}
            // disabled
            className="w-full shadow-none hover:shadow-none bg-logoOcre bg-opacity-10 hover:bg-opacity-20 transition-all duration-300 ease-in-out rounded-md text-logoOcre text-sm font-proxima-semibold normal-case"
          >
            Cancel Subscription
          </Button>
        ) : (
          <Button
            disabled={props?.myPlan === props?.pId || props?.planLoader}
            // disabled
            onClick={() => props.upgradePlan(props?.pId)}
            className="w-full shadow-none hover:shadow-none bg-logoOcre hover:bg-opacity-80 transition-all duration-300 ease-in-out rounded-md text-white text-sm font-proxima-semibold normal-case"
          >
            {props?.myPlan === props?.pId
              ? "My Plan"
              : props?.validity !== 0
              ? "Change Plan"
              : "Subscribe Plan"}
            {/* Coming Soon */}
          </Button>
        )}
      </div>

      {props?.myPlan !== props?.pId && (
        <p className="mt-2 text-sm font-proxima-regular text-black dark:text-white text-center">
          Have a promocode?{" "}
          <Button
            onClick={() => {
              setPromocodePopup(true);
            }}
            className="p-0 bg-transparent text-logoOcre shadow-none text-sm hover:shadow-none normal-case font-proxima-semibold"
          >
            Apply promocode
          </Button>
        </p>
      )}

      {/* RECOMMENDED */}
      {/* {props.name === "Personal Plan" && props.isMonthly && (
        <div className="flex flex-col items-end gap-2 absolute top-3 right-3">
          <div className="block dark:hidden badge-gradient rounded-full px-3 py-1">
            <p className="text-xs font-proxima-semibold text-white">
              Get 30% off on your first month
            </p>
          </div>
          <div className="hidden dark:block bg-logoOcre rounded-full px-3 py-1">
            <p className="text-xs font-proxima-semibold text-white">
              Get 30% off on your first month
            </p>
          </div>

          {props.name === "Personal Plan" && (
            <>
              <div className="flex dark:hidden w-fit badge-gradient rounded-full px-2 py-1 text-white font-proxima-semibold items-center gap-2 text-xs">
                <FaRocket className="w-3 h-3 text-white" />
                Recommended
              </div>
              <div className="hidden dark:flex w-fit bg-logoOcre rounded-full px-2 py-1 text-white font-proxima-semibold items-center gap-2 text-xs">
                <FaRocket className="w-3 h-3 text-white" />
                Recommended
              </div>
            </>
          )}
        </div>
      )} */}

      <p className="mt-2 text-sm font-proxima-semibold text-black dark:text-white text-center">
        {props?.validity !== 0 &&
          props?.myPlan === props?.pId &&
          "Your plan ends on " + props?.validity}
      </p>

      {/* PROMOCODE */}
      <Dialog
        open={promocodePopup}
        handler={() => {
          setPromocodePopup(!promocodePopup);
          setShowDesc(false);
        }}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-2 border-b border-borderGrey">
            <h5 className="text-base font-proxima-semibold text-black">
              Apply promocode
            </h5>
            <Button
              className="shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-black hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => {
                setPromocodePopup(!promocodePopup);
                setShowDesc(false);
              }}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <p className="text-sm font-proxima-regular text-greyText">
              Please enter your promo code below to apply it to your pricing
              plan and enjoy exclusive discounts.
            </p>
            <div className="mt-3">
              <div className="flex items-center gap-3">
                <input
                  type="text"
                  name="promocode"
                  id="promocode"
                  value={props.promocode}
                  onChange={(e) => props.setPromocode(e.target.value)}
                  placeholder="Enter the promocode"
                  className="px-5 py-2 block w-full outline-none focus:outline-none bg-white border border-borderGrey rounded-md text-base placeholder:text-sm font-proxima-semibold placeholder:font-proxima-regular"
                />

                <Button
                  onClick={() => setShowDesc(true)}
                  className="shadow-none hover:shadow-none bg-black font-proxima-semibold text-white text-base normal-case px-5 py-2"
                >
                  Apply
                </Button>
              </div>
              {showDesc && (
                <p className="text-sm font-proxima-regular text-success">
                  {props.promocode === "LAUNCHPARTY" &&
                  props?.name === "Basic Plan" &&
                  props?.isMonthly
                    ? "You will get one month free trial on basic monthly plan."
                    : props?.promocode === "FREEBIE4U" &&
                      props?.name === "Personal Plan" &&
                      props?.isMonthly
                    ? "You will get one month free trial on personal monthly plan."
                    : "Invalid Promocode"}
                </p>
              )}
            </div>
          </div>
          <div className="px-5 py-3 border-t border-borderGrey">
            <div className="flex items-center justify-end">
              <Button
                disabled={
                  !(
                    showDesc &&
                    props.promocode === "LAUNCHPARTY" &&
                    props?.name === "Basic Plan" &&
                    props?.isMonthly
                  ) &&
                  !(
                    showDesc &&
                    props?.promocode === "FREEBIE4U" &&
                    props?.name === "Personal Plan" &&
                    props?.isMonthly
                  )
                }
                onClick={() => {
                  setPromocodePopup(!promocodePopup);
                  props.upgradePlan(props?.pId);
                }}
                className="shadow-none hover:shadow-none bg-logoOcre hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md text-sm font-proxima-semibold text-white normal-case px-4 py-2"
              >
                Continue with purchase
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default PricingBox;
