/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import {
  UpdateTag,
  addTag,
  deleteTag,
  getTag,
  getTagById,
} from "../../../service/api";
import { toast } from "react-toastify";
import Select from "react-select";
import { HexColorPicker } from "react-colorful";
import { Colorful } from "@uiw/react-color";
/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Popover,
  PopoverContent,
  PopoverHandler,
  Dialog,
  DialogBody,
  Spinner,
  Textarea,
} from "@material-tailwind/react";

/* ICONS */
import { FaTimes } from "react-icons/fa";
import { FaPlus, FaTrash } from "react-icons/fa6";
import { IoSearch, IoOptionsSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/design/input";
import useCheckLimit, { checkSetupLimit } from "../../../service/utils";
import { useSelector } from "react-redux";

/* TYPE SELECT OPTIONS */
const typeSelectOptions = [
  { label: "SETUP", value: "SETUP" },
  { label: "MISTAKE", value: "MISTAKE" },
  // { label: "CUSTOM", value: "CUSTOM" },
];
/* LABEL COLORS */
const labelColors = [
  {
    id: 1,
    color: "#3498DB",
  },
  {
    id: 2,
    color: "#2ECC71",
  },
  {
    id: 3,
    color: "#E74C3C",
  },
  {
    id: 5,
    color: "#9B59B6",
  },
  {
    id: 6,
    color: "#2C3E50",
  },
  {
    id: 7,
    color: "#27AE60",
  },
  {
    id: 8,
    color: "#C0392B",
  },
  {
    id: 9,
    color: "#8E44AD",
  },
];
const typeSelectOptions2 = [
  { label: "ALL", value: "ALL" },
  { label: "SETUP", value: "SETUP" },
  { label: "MISTAKE", value: "MISTAKE" },
];

const SetupManagement = () => {
  const [addLabel, setAddLabel] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    name: "",
    description: "",
    type: "",
    color: "",
  });
  console.log('data: ', data);
  const [tagData, setTagData] = useState();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState({ label: "ALL", value: "ALL" });
  const [check] = useCheckLimit("setup", tagData?.length);

  const { user } = useSelector((state) => state?.user);

  const [setupColor, setSetupColor] = useState("#000000");

  /* GET TAG DATA FUNCTION */
  const getTagData = () => {
    let body = {};
    if (search && search !== "") {
      body.search = search;
    }
    if (type.value !== "ALL") body.tag = type.value;
    getTag(body)
      .then((res) => {
        if (res?.status) {
          setTagData(res?.data);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  const getTagDataById = (id) => {
    setLoading(true);
    getTagById(id)
      .then((res) => {
        setLoading(false);

        if (res?.status) {
          // setData(res?.data);
          setData({
            ...res.data,
            type: typeSelectOptions.filter(
              (obj) => obj.value === res?.data?.type
            )[0],
          });
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /*CLEAR DATA & POPUP CLOSE OPEN */
  const handleClear = () => {
    setAddLabel(!addLabel);
    setData({ name: "", type: "", description: "" });
    setEdit(false);
    setLoading(false);
  };

  useEffect(() => {
    getTagData();
  }, [search, type]);

  /* ADD & UPDATE TAG DATA FUNCTION */
  const handleTagData = (id) => {
    if (!edit) {
      if (data.name === "") {
        return toast.error("Name is mandatory");
      }
      if (data.type === "") {
        return toast.error("Type is mandatory");
      }
      if (data.description === "") {
        return toast.error("Description is mandatory");
      }
      let body = {
        name: data.name,
        type: data.type.value,
        description: data.description,
        color: data.color,
      };
      setLoading(true);
      addTag(body)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getTagData();
            setAddLabel(false);
            setLoading(false);
            setData({ name: "", type: "", description: "" });
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          if (e?.response?.data?.message)
            toast.error(e?.response?.data?.message);
          setLoading(false);
        });
    } else {
      let body = {
        name: data.name,
        type: data.type.value,
        description: data.description,
        color: data.color,
      };
      if (data.name === "") {
        return toast.error("Name is mandatory");
      }
      if (data.color === "") {
        return toast.error("Color is mandatory");
      }
      if (data.description === "") {
        return toast.error("Description is mandatory");
      }
      setLoading(true);
      UpdateTag(data?._id, body)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getTagData();
            setEdit(false);
            setLoading(false);
            setAddLabel(false);
            setData({ name: "", type: "", description: "" });
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          if (e?.response?.data?.message)
            toast.error(e?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  /* DELETE TAG DATA FUNCTION */
  const handleDelete = (id) => {
    setLoading(true);
    deleteTag(id)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          getTagData();
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
        if (e?.response?.data?.message) {
          toast.error(e?.response?.data?.message);
        }
        setLoading(false);
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Tag Name",
      grow: 1,
      selector: (row) => (
        <>
          <div
            className="!bg-opacity-10 rounded-md px-4 p-2"
            style={{
              backgroundColor: row.color !== undefined ? row.color : "#D59A4A",
            }}
          >
            <p className="text-sm font-proxima-semibold leading-none capitalize text-white">
              {row.name}
            </p>
          </div>
        </>
      ),
    },
    {
      name: "Type",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-[#777] dark:text-white">
          {row.type}
        </span>
      ),
    },
    {
      name: "Description",
      grow: 3,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText leading-normal pr-5">
          {row.description}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              setEdit(true);
              getTagDataById(row?._id);
              setAddLabel(true);
            }}
            className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-black hover:bg-opacity-5"
          >
            <MdEdit className="w-4 h-4 text-[#c4c3c3]" />
          </Button>
          <Button
            onClick={() => handleDelete(row?._id)}
            disabled={loading}
            className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-black hover:bg-opacity-5"
          >
            <FaTrash className="w-4 h-4 text-[#C4C3C3]" />
          </Button>
        </div>
      ),
    },
  ];

  /* COLUMNS */
  const mobileViewColumns = [
    {
      name: "Tag Name",
      width: "20vh",
      selector: (row) => (
        <>
          <div
            className="!bg-opacity-10 rounded-md px-4 p-2"
            style={{
              backgroundColor: row.color !== undefined ? row.color : "#D59A4A",
            }}
          >
            <p className="text-sm font-proxima-semibold leading-none capitalize text-white">
              {row.name}
            </p>
          </div>
        </>
      ),
    },
    {
      name: "Type",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-[#777] dark:text-white">
          {row.type}
        </span>
      ),
    },
    {
      name: "Description",
      width: "40vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText leading-snug capitalize">
          {row.description}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              setEdit(true);
              getTagDataById(row?._id);
              setAddLabel(true);
            }}
            className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-black hover:bg-opacity-5"
          >
            <MdEdit className="w-4 h-4 text-[#c4c3c3]" />
          </Button>
          <Button
            onClick={() => handleDelete(row?._id)}
            disabled={loading}
            className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-black hover:bg-opacity-5"
          >
            <FaTrash className="w-4 h-4 text-[#C4C3C3]" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <section className="p-3 xl:p-5 lg:p-5 h-mobileHeight xl:h-auto lg:h-auto">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Setup Management | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* PAGE HEADING */}
        <div className="flex items-center justify-between">
          <h1 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-bold text-black dark:text-white tracking-tight leading-none">
            Setup Management
          </h1>

          <Button
            className="px-4 py-2 shadow-none hover:shadow-none bg-buttonColor hover:bg-opacity-80 border border-buttonColor rounded-md text-sm text-white font-proxima-semibold normal-case flex items-center gap-2 transition-all duration-300 ease-in-out"
            onClick={() => {
              if (check) {
                handleClear();
              } else {
                if (!user?.subscription) {
                  toast.error("Please subscribe the plan.");
                } else {
                  toast.error(
                    "Setup/Mistake limit is reached. Please upgrade your plan."
                  );
                }
              }
            }}
          >
            <FaPlus className="w-4 h-4" />
            Add setup/mistake
          </Button>
        </div>

        {/* SETUP TABLE */}
        <Card className="w-full h-auto xl:h-setupManagementCard lg:h-setupManagementCard bg-white dark:bg-darkSidebar shadow-md rounded-lg border border-borderGrey dark:border-darkBorder mt-5">
          <CardBody className="p-5 overflow-auto overflow-x-hidden hide-scrollbar">
            {/* TABLE HEAD */}
            <div className="block xl:flex lg:flex items-center justify-between">
              <div className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center gap-3 px-3 py-[1vh]">
                <IoSearch className="w-4 h-4 text-[#777]" />
                <input
                  type="text"
                  placeholder={"Search for trades"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-[30vh] text-sm font-proxima-semibold p-0 focus:outline-none leading-none dark:bg-transparent dark:text-white"
                />
              </div>
            </div>

            {/* TABLE BODY */}
            <div className="pt-5">
              <div className="hidden xl:block lg:block">
                <div className="block dark:hidden">
                  <DataTable
                    columns={columns}
                    data={tagData}
                    className="trades-table"
                    pagination
                    paginationTotalRows={tagData?.length}
                    noDataComponent={
                      <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                          There are no setups to display. Please click on the
                          add setup to create your own setup.
                        </p>
                      </div>
                    }
                  />
                </div>
                <div className="hidden dark-pagination dark:block">
                  <DataTable
                    columns={columns}
                    data={tagData}
                    className="trades-table dark"
                    responsive
                    pagination
                    paginationTotalRows={tagData?.length}
                    noDataComponent={
                      <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                          There are no trades to display. Please connect your
                          broker to see your previous trades.
                        </p>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="block xl:hidden lg:hidden">
                <div className="block dark:hidden">
                  <DataTable
                    columns={mobileViewColumns}
                    data={tagData}
                    className="trades-table"
                    pagination
                    paginationTotalRows={tagData?.length}
                    noDataComponent={
                      <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                          There are no setups to display. Please click on the
                          add setup to create your own setup.
                        </p>
                      </div>
                    }
                  />
                </div>
                <div className="hidden dark-pagination dark:block">
                  <DataTable
                    columns={mobileViewColumns}
                    data={tagData}
                    className="trades-table dark"
                    responsive
                    pagination
                    paginationTotalRows={tagData?.length}
                    noDataComponent={
                      <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                        <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                          There are no trades to display. Please connect your
                          broker to see your previous trades.
                        </p>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      {/* ADD LABEL DIALOG */}
      <Dialog
        open={addLabel}
        handler={() => setAddLabel(!addLabel)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
        className="rounded-lg dark:bg-darkSidebar"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              {!edit ? "Add" : "Edit"} {data?.type?.label === "MISTAKE" ? "Mistake" :"Setup"}
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={handleClear}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          {edit && loading ? (
            <div className="w-full p-5">
              <div className="bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder py-3 rounded-lg">
                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                  Please wait will we fetch the details for you...
                </p>
              </div>
            </div>
          ) : (
            <div className="px-5 py-3">
              <div className="form-group">
                <label
                  htmlFor="labelName"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Setup Name <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="labelName"
                  id="labelName"
                  placeholder="Type your label name"
                  value={data?.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="labelName"
                  className="text-sm block font-proxima-semibold text-black dark:text-white mb-1"
                >
                  Type <span className="text-red-500">*</span>
                </label>{" "}
                <div className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-3 mt-5 xl:mt-0 lg:mt-0">
                  <div className="block dark:hidden w-full">
                    <Select
                      options={typeSelectOptions}
                      className="react-select upload-select font-proxima-semibold"
                      classNamePrefix="account"
                      placeholder="Click here to add type"
                      value={data?.type}
                      onChange={(e) => {
                        setData({ ...data, type: e, color: e?.label !== "SETUP" ? "#d59a4a" : data?.color })
                      }}
                    />
                  </div>
                  <div className="hidden dark:block w-full">
                    <Select
                      options={typeSelectOptions}
                      className="react-select dark upload-select font-proxima-semibold"
                      classNamePrefix="account"
                      placeholder="Click here to add type"
                      value={data?.type}
                      onChange={(e) => setData({ ...data, type: e })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="labelName"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Description
                  <span className="text-red-500">*</span>
                </label>
                <textarea
                  rows={4}
                  name="labelName"
                  id="labelName"
                  placeholder="Type your description name"
                  className="mt-1 w-full block border border-borderGrey dark:border-darkBorder bg-transparent text-black dark:text-white text-sm font-proxima-semibold rounded-md placeholder:font-proxima-regular p-3 focus:outline-none"
                  value={data?.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </div>
              {data?.type?.label === "SETUP" && (
                <div className="form-group mt-5">
                  <label
                    htmlFor="labelColor"
                    className="text-sm font-proxima-semibold text-black dark:text-white"
                  >
                    Setup Color <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center gap-2 flex-wrap mt-2">
                    {/* {labelColors.map((color) => (
                      <div
                        className={`w-10 h-10 rounded-full flex-shrink-0 border cursor-pointer flex items-center justify-center`}
                        style={{
                          borderColor:
                            data.color === color.color
                              ? color.color
                              : "#E5E5E5",
                        }}
                        onClick={() => setData({ ...data, color: color.color })}
                        key={color.id}
                      >
                        <div
                          className="w-8 h-8 rounded-full"
                          style={{ backgroundColor: color.color }}
                        />
                      </div>
                    ))} */}

                    {/* COLOR PICKER */}
                    <Colorful
                      color={data?.color ? data?.color : "#d59a4a"}
                      disableAlpha
                      onChange={(color) => {
                        setData({ ...data, color: color?.hex });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end px-5 py-3">
            <Button
              onClick={handleTagData}
              disabled={loading}
              className="shadow-none hover:shadow-none bg-buttonColor text-sm font-proxima-semibold text-white py-2 normal-case"
            >
              {loading ? <Spinner className="h-full" /> : " Submit"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default SetupManagement;
