/* eslint-disable no-useless-escape */
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";

/* THIRD PARTY COMPONENTS */
import { Button, Spinner } from "@material-tailwind/react";

/* ASSETS */
import Logo from "../../assets/images/logo-light.png";
import { forgotPassword, otpVerify, sendOtp } from "../../service/api";
import { toast } from "react-toastify";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";

const Input = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      className="mt-1 w-full block border border-borderGrey rounded-md text-base font-proxima-semibold px-4 py-2 placeholder:font-proxima-regular placeholder:text-sm focus:outline-none"
    />
  );
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [OTP, setOTP] = useState();
  const [loading, setLoading] = useState(false);
  const active = {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
  };
  const [tab, setTab] = useState(active.FORGOT_PASSWORD);

  const handleOtp = () => {
    if (email === "") {
      return toast.warning("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return toast.warning("Please enter a valid email");
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);

    sendOtp(formData)
      .then((res) => {
        if (res?.status) {
          setTab(active.SEND_OTP);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerify = () => {
    if (!OTP) {
      return toast.warning("Please enter OTP");
    } else if (OTP.length !== 6) {
      return toast.warning("Please enter a valid OTP");
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", OTP);
    setLoading(true);
    otpVerify(formData)
      .then((res) => {
        if (res?.status) {
          setTab(active.CHANGE_PASSWORD);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePassword = () => {
    if (password === "") {
      return toast.warning("Please enter password");
    } else if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/i.test(
        password
      )
    ) {
      return toast.warning(
        "Password must contain minimum eight characters, at least one letter, one special character and one number."
      );
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    forgotPassword(formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          setEmail("");
          setPassword("");
          setTab(active.FORGOT_PASSWORD);
          setLoading(false);
          navigate("/");
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="bg-white">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Forgot Password | Trade Talez</title>
      </Helmet>

      <div className="container-full-width px-2 xl:px-0 lg:px-0">
        {/* FORM SECTION */}
        <div className="h-authSMHeight xl:h-authHeight lg:h-authHeight flex items-center justify-center">
          <div className="w-full xl:w-[30%] lg:w-[30%] mx-auto">
            <div className="bg-white border border-borderGrey p-5 xl:p-10 lg:p-10 rounded-lg shadow-lg">
              <img
                src={Logo}
                className="h-auto w-[16vh] object-cover"
                alt="Trade Talez"
              />

              <div className="mt-8">
                {/* HEADING */}
                <div className="p-0">
                  <h1 className="text-3xl font-proxima-extrabold text-black tracking-tight leading-none">
                    Forgot Password
                  </h1>
                  <h2 className="text-base font-proxima-regular text-greyText mt-2">
                    Please enter the email address you'd like your password
                    reset verification code to be sent.
                  </h2>
                </div>

                {/* FORGOT PASSWORD */}
                {tab === active.FORGOT_PASSWORD ? (
                  <>
                    <form className="mt-8">
                      <div className="form-group">
                        <label
                          htmlFor="loginEmail"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          Email address <span className="text-red-500">*</span>
                        </label>
                        <Input
                          type="email"
                          name="resetEmail"
                          id="resetEmail"
                          placeholder="Type your email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <Button
                        onClick={handleOtp}
                        disabled={loading}
                        className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                      >
                        {loading ? (
                          <div className="flex center justify-center">
                            <Spinner className="h-full" />
                          </div>
                        ) : (
                          " Submit"
                        )}
                      </Button>
                      <div className="mt-5">
                        <Link
                          to="/"
                          className="block text-sm font-proxima-semibold text-buttonColor text-center"
                        >
                          Back to login
                        </Link>
                      </div>
                    </form>
                  </>
                ) : (
                  ""
                )}

                {/* VERIFICATION */}
                {tab === active.SEND_OTP ? (
                  <>
                    {/* VERIFICATION */}
                    <form className="mt-8 ">
                      <div className="form-group">
                        <label
                          htmlFor="verificationCode"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          Verification code{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <OTPInput
                            numInputs={6}
                            value={OTP}
                            onChange={(e) => setOTP(e)}
                            renderSeparator={
                              <span className="text-greyText">-</span>
                            }
                            renderInput={(props) => <input {...props} />}
                            inputStyle="!w-12 h-12 rounded-md border border-[#E5E5E5] outline-none focus:outline-none text-black font-proxima-semibold"
                            containerStyle="gap-2"
                            className="mt-1"
                          />
                        </div>
                        <Button
                          onClick={handleVerify}
                          disabled={loading}
                          className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                        >
                          {loading ? (
                            <div className="flex center justify-center">
                              <Spinner className="h-full" />
                            </div>
                          ) : (
                            " Submit"
                          )}
                        </Button>
                        <div className="mt-5">
                          <Link
                            // to="/"
                            onClick={handleOtp}
                            disabled={loading}
                            className="block text-sm font-proxima-semibold text-buttonColor text-center"
                          >
                            Resend code
                          </Link>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  ""
                )}

                {/* CHANGE PASSWORD */}
                {tab === active.CHANGE_PASSWORD ? (
                  <>
                    <form className="mt-8">
                      <div className="form-group">
                        <label
                          htmlFor="loginEmail"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          Email address <span className="text-red-500">*</span>
                        </label>
                        <Input
                          type="email"
                          name="resetEmail"
                          id="resetEmail"
                          placeholder="Type your email address"
                          value={email}
                          readOnly
                          // onChange={(e) => setEmail(e.target.value)}
                        />
                        <label
                          htmlFor="loginPassword"
                          className="text-sm font-proxima-semibold text-black"
                        >
                          Password <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                          <Input
                            type={passwordToggle ? "text" : "password"}
                            name="loginPassword"
                            id="loginPassword"
                            placeholder="Enter your new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Button
                            className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                            onClick={() => setPasswordToggle(!passwordToggle)}
                          >
                            {passwordToggle ? (
                              <IoMdEyeOff className="w-5 h-5 text-greyText" />
                            ) : (
                              <IoMdEye className="w-5 h-5 text-greyText" />
                            )}
                          </Button>
                        </div>
                      </div>

                      <Button
                        onClick={handlePassword}
                        disabled={loading}
                        className="mt-5 w-full shadow-none hover:shadow-none text-base font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 normal-case px-8 py-[1vh] rounded-md"
                      >
                        {loading ? (
                          <div className="flex center justify-center">
                            <Spinner className="h-full" />
                          </div>
                        ) : (
                          " Change Password"
                        )}
                      </Button>
                      {/* <div className="mt-5">
                        <Link
                          to="/"
                          className="block text-sm font-proxima-semibold text-buttonColor text-center">
                          Back to login
                        </Link>
                      </div> */}
                    </form>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className="border-t border-borderGrey py-5">
          <div className="container">
            <div className="block xl:flex lg:flex items-center justify-between">
              <p className="text-sm font-proxima-regular text-greyText text-center">
                Copyright © 2024 TradeTalez. A product by{" "}
                <span className="text-black font-proxima-semibold">
                  Tactical Trading Technologies LLP
                </span>
                . Powered by{" "}
                <a
                  href="https://www.roundtechsquare.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 font-proxima-semibold"
                >
                  RoundTechSquare
                </a>
              </p>
              <p className="text-sm font-proxima-regular text-black text-center">
                Charts powered by{" "}
                <a
                  href="https://www.tradingview.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline font-proxima-semibold"
                >
                  TradingView
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
