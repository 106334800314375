/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import Select from "react-select";
import DataTable from "react-data-table-component";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addNoteFolder,
  addNotebook,
  deleteNoteFolder,
  deleteNotebook,
  getNoteFolder,
  getNotebook,
  getNotebookById,
  getTag,
  updateNotebook,
} from "../../../service/api";
import { toast } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Tab,
  Tabs,
  TabsHeader,
  Dialog,
  DialogBody,
  TabsBody,
  TabPanel,
  Accordion,
  AccordionBody,
  AccordionHeader,
  Spinner,
} from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/design/input";
import {
  NetProfitLossGraph,
  NetProfitLossGraphDark,
} from "../../../components/others/charts/net-profit-loss-graph";

/* ICONS */
import { FaFolder, FaCheck, FaPlus } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { IoMdAddCircle, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdLabel } from "react-icons/md";
import { useSelector } from "react-redux";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

/* FOLDERS LIST */
const activeFolders = [
  {
    id: 1,
    name: "All Notes",
  },
  {
    id: 2,
    name: "Trade Notes",
  },
  {
    id: 3,
    name: "Daily Journal",
  },
  // {
  //   id: 4,
  //   name: "Sessions Recap",
  // },
];

/* LABELS */
const activeLabels = [
  {
    id: 1,
    color: "#3498DB",
    name: "Market Analysis",
  },
  {
    id: 2,
    color: "#27AE60",
    name: "Asset Classes",
  },
  {
    id: 3,
    color: "#9B59B6",
    name: "Trading Strategies",
  },
  {
    id: 4,
    color: "#C0392B",
    name: "Risk Management",
  },
  {
    id: 5,
    color: "#F39C12",
    name: "Trade Execution",
  },
];

/* LABEL COLORS */
const labelColors = [
  {
    id: 1,
    color: "#3498DB",
  },
  {
    id: 2,
    color: "#2ECC71",
  },
  {
    id: 3,
    color: "#E74C3C",
  },
  {
    id: 4,
    color: "#F39C12",
  },
  {
    id: 5,
    color: "#9B59B6",
  },
  {
    id: 6,
    color: "#2C3E50",
  },
  {
    id: 7,
    color: "#27AE60",
  },
  {
    id: 8,
    color: "#C0392B",
  },
  {
    id: 9,
    color: "#8E44AD",
  },
];

/* LABEL SELECT OPTIONS */
const labelSelectOptions = [
  { label: "Market Analysis", value: "Market Analysis" },
  { label: "Asset Classes", value: "Asset Classes" },
  { label: "Trading Strategies", value: "Trading Strategies" },
  { label: "Risk Management", value: "Risk Management" },
];

const Notebook = () => {
  /* FOLDER STATES */
  const [currentTab, setCurrentTab] = useState("All Notes");
  const [currentTabId, setCurrentTabId] = useState(1);
  const [singleNoteId, setsingleNoteId] = useState("");
  const [heading, setHeading] = useState(
    moment(new Date()).format("ddd, MMM DD, YYYY")
  );
  const [noteData, setNoteData] = useState([]);
  const [singleNoteData, setSingleNoteData] = useState();
  const [quillValue, setQuillValue] = useState("");
  const [addFolder, setAddFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderList, setFolderList] = useState(activeFolders);

  const [addLabel, setAddLabel] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelColorSelected, setLabelColorSelected] = useState("");
  const [labelsList, setLabelsList] = useState(activeLabels);
  const [tradesAccordion, setTradesAccordion] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tagData, setTagData] = useState();
  const [tag, setTag] = useState();
  const { selectedExchange } = useSelector((state) => state.user);

  /* GET TAG DATA FUNCTION */
  const getTagData = () => {
    getTag()
      .then((res) => {
        if (res?.status) {
          let arr = [];
          res?.data?.map((obj) => {
            arr.push({ value: obj?._id, label: obj?.name, type: obj?.type });
          });
          setTagData(arr);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* ADD FOLDERS FUNCTION */
  const addFolderFn = () => {
    // setFolderList([
    //   ...folderList,
    //   {
    //     id: folderList.length + 1,
    //     name: folderName,
    //   },
    // ]);
    addNoteFolder({ name: folderName })
      .then((res) => {
        if (res.status) {
          noteFolderData();
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
    setAddFolder(false);
    setFolderName("");
  };

  /* CLEAR FUNCTION */
  const handleClear = () => {
    setSingleNoteData();
    setQuillValue("");
    setEdit(false);
    setsingleNoteId("");
    setHeading(moment(new Date()).format("ddd, MMM DD, YYYY"));
    setTag();
  };

  /* GET FOLDER FUNCTION */
  const noteFolderData = () => {
    getNoteFolder()
      .then((res) => {
        if (res.status) {
          let arr = [];
          res?.data?.map((obj) => {
            arr.push({ id: obj?._id, name: obj?.name });
          });
          let obj = folderList.slice(0, 4);
          setFolderList([...obj, ...arr]);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  useEffect(() => {
    noteFolderData();
    getNote();
    getTagData();
  }, []);

  /* DELETE FOLDER FUNCTION */
  const deleteFolder = (id) => {
    deleteNoteFolder(id)
      .then((res) => {
        if (res.status) {
          noteFolderData();
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* ADD LABELS FUNCTION */
  const addLabelFn = () => {
    setLabelsList([
      ...labelsList,
      {
        id: labelsList.length + 1,
        color: labelColorSelected,
        name: labelName,
      },
    ]);
    setAddLabel(false);
  };

  /* GET NOTEBOOK FUNCTION */
  const getNote = () => {
    getNotebook({ page: 1, sizePerPage: 100 })
      .then((res) => {
        if (res.status) {
          setNoteData(res?.data?.docs);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* GET NOTEBOOK BY ID FUNCTION */
  const getNoteById = (id) => {
    getNotebookById(id)
      .then((res) => {
        if (res.status) {
          setSingleNoteData(res?.data);
          setQuillValue(res?.data?.text);
          if (tagData?.length !== 0) {
            let arr = tagData?.filter((obj) => obj?.value === res?.data?.tag);
            setTag(arr);
          }
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* ADD NOTEBOOK FUNCTION */
  const addNote = () => {
    if (!edit) {
      let body = {
        exchange: selectedExchange ? selectedExchange?._id : "",
        tag: tag?.value,
        text: quillValue,
        folder: String(currentTabId),
        title: heading,
        templete: "100 trades",
      };
      if (
        currentTab === "All Notes" ||
        currentTab === "Trade Notes" ||
        currentTab === "Daily Journal"
      ) {
        return;
      }
      setLoading(true);
      addNotebook(body)
        .then((res) => {
          if (res.status) {
            getNote();
            toast.success(res?.message);
            setQuillValue("");
            setHeading(moment(new Date()).format("ddd, MMM DD, YYYY"));
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
          setLoading(false);
        });
    } else {
      let body = {
        exchange: "6579530fa3bbc1468cfefba8",
        tag: tag?.value,
        text: quillValue,
      };
      if (
        currentTab === "All Notes" ||
        currentTab === "Trade Notes" ||
        currentTab === "Daily Journal"
      ) {
        return;
      }
      updateNotebook(singleNoteData?._id, body)
        .then((res) => {
          if (res.staus) {
            getNote();
            toast.success(res?.message);
            setQuillValue("");
            setTag();
            setLoading(false);

            if (currentTabId !== "") {
              getNoteById(currentTabId);
            }
          } else {
            toast.error(res?.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
          setLoading(false);
        });
    }
  };

  /* NOTE TILE */
  const NoteTile = (props) => {
    let data = props.value;
    return (
      <div
        onClick={() => {
          getNoteById(data?._id);
          setsingleNoteId(data?._id);
          setEdit(true);
        }}
        className={`${
          singleNoteData && singleNoteData?._id === data?._id
            ? "bg-buttonColor dark:bg-bgDark bg-opacity-5"
            : "bg-white hover:bg-[#F5F5F5] dark:bg-darkSidebar bg-opacity-5"
        } border-b last:border-b-0 border-[#E5E5E5] dark:border-darkBorder p-5 cursor-pointer`}
      >
        {props.netProfitLoss !== null && (
          <p className="text-sm font-proxima-bold text-red-500 leading-none">
            Net P&L: ₹31,350
            {}
          </p>
        )}

        <div className="mt-1">
          <h5 className="text-base font-proxima-bold text-black dark:text-white">
            {data?.title}
          </h5>
          <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText leading-none mt-1">
            {data?.templete}
          </p>
        </div>
      </div>
    );
  };

  /* ANALYTICS CARD */
  const AnalyticsCard = (props) => {
    return (
      <div className="bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 border border-[#E5E5E5] dark:border-darkBorder rounded-lg p-5">
        <h5 className="text-sm font-proxima-regular text-black dark:text-lightGreyText leading-none">
          {props.analyticsName}
        </h5>
        <h5 className="text-lg font-proxima-bold text-black dark:text-white leading-none mt-3">
          {props.analyticsCount}
        </h5>
      </div>
    );
  };

  /* STATISTICS */
  const TradeStatistics = () => {
    return (
      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 items-center py-5">
        <div className="col-span-1 px-5 border-r border-[#E5E5E5] dark:border-darkBorder">
          <div className="p-0">
            <h2 className="text-base font-proxima-regular text-black dark:text-lightGreyText">
              Net P&L (₹)
            </h2>
            <h2 className="text-3xl font-proxima-bold text-black dark:text-white leading-none mt-1">
              ₹72,837.77
            </h2>
            <div className="-mt-16 -mr-3">
              <div className="block dark:hidden">
                <NetProfitLossGraph />
              </div>
              <div className="hidden dark:block">
                <NetProfitLossGraphDark />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 px-5 mt-5 xl:mt-0 lg:mt-0">
          <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
            <div className="col-span-1">
              <AnalyticsCard analyticsName="Total trades" analyticsCount="20" />
            </div>
            <div className="col-span-1">
              <AnalyticsCard analyticsName="Winners" analyticsCount="5" />
            </div>
            <div className="col-span-1">
              <AnalyticsCard
                analyticsName="Gross P&L"
                analyticsCount="-₹69,087.00"
              />
            </div>
            <div className="col-span-1">
              <AnalyticsCard analyticsName="Losers" analyticsCount="15" />
            </div>
            <div className="col-span-1">
              <AnalyticsCard analyticsName="Volume" analyticsCount="100" />
            </div>
            <div className="col-span-1">
              <AnalyticsCard
                analyticsName="Profit Factor"
                analyticsCount="4.50"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* TRADES TABLE */
  const TradesTable = () => {
    return (
      <Accordion
        className="rounded-lg"
        open={tradesAccordion}
        animate={CUSTOM_ANIMATION}
        icon={
          tradesAccordion ? (
            <IoIosArrowUp className="w-5 h-5 text-black dark:text-white" />
          ) : (
            <IoIosArrowDown className="w-5 h-5 text-black dark:text-white" />
          )
        }
      >
        <AccordionHeader
          className={`bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder ${
            tradesAccordion ? "rounded-t-md" : "rounded-md"
          } p-5 text-lg font-proxima-bold text-black dark:text-white leading-none`}
          onClick={() => setTradesAccordion(!tradesAccordion)}
        >
          Trade details
        </AccordionHeader>
        <AccordionBody className="bg-white dark:bg-darkSidebar p-5 border border-[#E5E5E5] dark:border-darkBorder border-t-0 rounded-b-lg">
          <div className="block dark:hidden">
            <DataTable
              columns={columns}
              data={data}
              responsive
              noDataComponent={
                <div className="py-5 rounded-lg font-proxima-semibold text-sm text-black">
                  There are no trades to display.
                </div>
              }
              className="trades-table rounded-lg"
            />
          </div>
          <div className="hidden dark:block">
            <DataTable
              columns={columns}
              data={data}
              responsive
              noDataComponent={
                <div className="py-5 rounded-lg font-proxima-semibold text-sm text-black">
                  There are no trades to display.
                </div>
              }
              className="trades-table dark rounded-lg"
            />
          </div>
        </AccordionBody>
      </Accordion>
    );
  };

  const columns = [
    {
      name: "Open Date",
      selector: (row) => (
        <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-white dark:text-opacity-60">
          {row.openDate}
        </span>
      ),
    },
    {
      name: "Symbol",
      selector: (row) => (
        <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-white dark:text-opacity-60">
          {row.symbol}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "WIN" ? (
          <div className="bg-green-500 bg-opacity-10 rounded px-3 py-2 text-[1.3vh] font-proxima-bold text-green-500">
            {row.status}
          </div>
        ) : (
          <div className="bg-red-500 bg-opacity-10 rounded px-3 py-2 text-[1.3vh] font-proxima-bold text-red-500">
            {row.status}
          </div>
        ),
    },
    {
      name: "Entry Price",
      selector: (row) => (
        <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-white dark:text-opacity-60">
          {row.entryPrice}
        </span>
      ),
    },
    {
      name: "Net P&L",
      selector: (row) => (
        <span
          className={`text-sm font-proxima-semibold ${
            parseFloat(row.netProfitLoss) < 0
              ? "text-red-500"
              : "text-green-500"
          }`}
        >
          ₹ {row.netProfitLoss}
        </span>
      ),
    },
    {
      name: "Trade ROI",
      selector: (row) => (
        <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-white dark:text-opacity-60">
          {row.netReturn}
        </span>
      ),
    },
    {
      name: "Duration",
      selector: (row) => (
        <span className="text-sm font-proxima-regular text-[#7B7B7B] dark:text-white dark:text-opacity-60">
          {row.duration}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-buttonColor">
          View details
        </span>
      ),
    },
  ];

  const data = [
    {
      openDate: "10/11/2023",
      symbol: "BANKNIFTY",
      status: "WIN",
      entryPrice: "43,839.07",
      netProfitLoss: "1,539,000.00",
      netReturn: "0.56%",
      duration: "19h 21m",
    },
    {
      openDate: "10/11/2023",
      symbol: "FINNIFTY",
      status: "LOSS",
      entryPrice: "43,839.07",
      netProfitLoss: "-15,300.00",
      netReturn: "0.16%",
      duration: "19h 21m",
    },
  ];

  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Notebook | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        <Tabs value={currentTab}>
          <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 gap-0 gap-y-5 xl:gap-5 lg:gap-5">
            {/* FOLDERS AND TAGS LIST */}
            <div className="col-span-4 xl:col-span-1 lg:col-span-1">
              <Card className="h-auto xl:h-cardHeight lg:h-cardHeight dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg shadow-none">
                <CardBody className="p-5">
                  {/* FOLDERS */}
                  <div className="add-folder">
                    <p className="text-[1.3vh] text-gray-400 font-proxima-semibold">
                      FOLDERS
                    </p>
                    <TabsHeader
                      className="p-0 bg-transparent shadow-none w-full block mt-2"
                      indicatorProps={{
                        className:
                          "bg-buttonColor bg-opacity-5 dark:bg-opacity-20 shadow-none text-buttonColor",
                      }}
                    >
                      {folderList.map((folder) => (
                        <Tab
                          key={folder.id}
                          value={folder.name}
                          className="text-sm font-proxima-semibold px-4 py-2 justify-start text-[#8995AE] dark:text-lightGreyText mt-2"
                          activeClassName="tab-active"
                          onClick={() => {
                            setCurrentTab(folder.name);
                            setCurrentTabId(folder.id);
                          }}
                        >
                          <div className="flex items-center gap-3">
                            <FaFolder className="w-4 h-4 text-[#C3CAD9] dark:text-lightGreyText transition-all duration-300 ease-in-out" />
                            {folder.name}
                          </div>
                        </Tab>
                      ))}
                    </TabsHeader>

                    {/* ADD FOLDER */}
                    {addFolder && (
                      <div className="w-full flex items-center gap-3 px-4 pr-0 py-2 mt-2">
                        <FaFolder className="w-4 h-4 text-[#C3CAD9] flex-shrink-0" />
                        <div className="w-full rounded-md flex items-center gap-2">
                          <input
                            className="w-full text-sm font-proxima-semibold text-[#8995AE] p-0 focus:outline-none placeholder:font-proxima-regular leading-none rounded-none"
                            name="folderName"
                            id="folderName"
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            placeholder="Folder name"
                          />
                          <div className="flex items-center gap-1">
                            <Button
                              className="bg-transparent p-1 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                              onClick={() => setAddFolder(false)}
                            >
                              <FaTimes className="w-4 h-4 text-red-500" />
                            </Button>
                            <Button
                              className="bg-transparent p-1 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                              onClick={addFolderFn}
                            >
                              <FaCheck className="w-4 h-4 text-green-500" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ADD FOLDER BUTTON */}
                    <Button
                      className="group w-full bg-transparent normal-case shadow-none hover:shadow-none hover:text-buttonColor hover:bg-buttonColor hover:bg-opacity-5 flex items-center gap-3 px-4 py-2 mt-2 text-sm font-proxima-semibold text-[#8995AE] rounded-md"
                      onClick={() => setAddFolder(!addFolder)}
                    >
                      <IoMdAddCircle className="w-5 h-5 text-[#C3CAD9] group-hover:text-buttonColor transition-all duration-300 ease-in-out" />
                      Add folder
                    </Button>
                  </div>

                  {/* LABELS */}
                  <div className="labels mt-10">
                    <p className="text-[1.3vh] text-gray-400 font-proxima-semibold">
                      LABELS
                    </p>
                    <div className="flex flex-col gap-1 mt-3">
                      {labelsList.map((label) => (
                        <Button
                          key={label.id}
                          className="w-full normal-case bg-transparent hover:bg-buttonColor hover:bg-opacity-5 rounded-md px-4 py-2 shadow-none hover:shadow-none flex items-center gap-3 text-sm font-proxima-semibold text-[#8995AE]"
                        >
                          <MdLabel className="w-5 h-5" color={label.color} />
                          {label.name}
                        </Button>
                      ))}

                      {/* ADD LABEL BUTTON */}
                      <Button
                        className="group w-full bg-transparent normal-case shadow-none hover:shadow-none hover:text-buttonColor hover:bg-buttonColor hover:bg-opacity-5 flex items-center gap-3 px-4 py-2 mt-1 text-sm font-proxima-semibold text-[#8995AE] rounded-md"
                        onClick={() => setAddLabel(!addLabel)}
                      >
                        <IoMdAddCircle className="w-5 h-5 text-[#C3CAD9] group-hover:text-buttonColor transition-all duration-300 ease-in-out" />
                        Add label
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            {/* NOTES LIST */}
            <div className="col-span-4 xl:col-span-1 lg:col-span-1">
              <Card className="h-auto xl:h-cardHeight lg:h-cardHeight dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg shadow-none">
                <CardBody className="p-0">
                  <TabsBody className="p-0">
                    {/* ALL NOTES */}
                    <TabPanel
                      key={currentTab}
                      value={currentTab}
                      className="p-0"
                    >
                      {" "}
                      {currentTab === "All Notes" ||
                      currentTab === "Trade Notes" ||
                      currentTab === "Daily Journal" ? (
                        ""
                      ) : (
                        <div className="p-5 border-b border-[#E5E5E5] dark:border-darkBorder">
                          <Button
                            onClick={handleClear}
                            className="w-full shadow-none hover:shadow-none bg-buttonColor rounded-md bg-opacity-10 dark:bg-opacity-100 text-sm font-proxima-bold text-buttonColor dark:text-white normal-case flex items-center justify-center leading-none gap-2 hover:bg-buttonColor hover:text-white transition-all duration-300 ease-in-out"
                          >
                            <FaPlus className="w-3 h-3" />
                            Add new note
                          </Button>
                        </div>
                      )}
                      {/* NOTES LIST */}
                      <div className="h-auto xl:h-[81vh] lg:h-[81vh] overflow-auto overflow-x-hidden hide-scrollbar">
                        {(noteData.length !== 0 &&
                          currentTab === "All Notes") ||
                        currentTab === "Daily Journal" ||
                        currentTab === "Trade Notes"
                          ? noteData?.map((obj) => <NoteTile value={obj} />)
                          : noteData
                              ?.filter((obj) => obj?.folder === currentTabId)
                              ?.map((obj) => <NoteTile value={obj} />)}
                      </div>
                    </TabPanel>
                  </TabsBody>
                </CardBody>
              </Card>
            </div>

            {/* NOTE DESCRIPTION */}
            <div className="col-span-4">
              <div className="h-auto xl:h-cardHeight lg:h-cardHeight overflow-auto overflow-x-hidden overflow-y-auto rounded-lg hide-scrollbar">
                {/* TRADE DETAILS */}
                <TradesTable />

                {/* DETAILS */}
                <div className="pt-5">
                  <Card className="block border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-none">
                    <CardBody className="p-0">
                      {/* HEADING */}
                      <div className="p-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder block xl:flex lg:flex items-center justify-between">
                        <h5 className="text-lg font-proxima-bold text-black dark:text-white leading-none">
                          {!singleNoteData ? (
                            <input
                              value={heading}
                              className="w-full text-sm font-proxima-semibold text-[#8995AE] p-0 focus:outline-none placeholder:font-proxima-regular leading-none rounded-none"
                              onChange={(e) => setHeading(e.target.value)}
                              readOnly={
                                currentTab === "Daily Journal" ||
                                singleNoteId !== ""
                              }
                            />
                          ) : (
                            singleNoteData?.title
                          )}
                        </h5>
                        <div className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-3 mt-5 xl:mt-0 lg:mt-0">
                          <MdLabel className="w-4 h-4 text-[#C9C8C8] dark:text-lightGreyText" />
                          <div className="block dark:hidden">
                            <Select
                              options={tagData}
                              className="react-select label-select font-proxima-semibold cursor-pointer"
                              classNamePrefix="account"
                              onChange={(e) => setTag(e)}
                              value={tag}
                              showClearButton
                              placeholder="Click here to add tags"
                            />
                          </div>
                          <div className="hidden dark:block">
                            <Select
                              options={tagData}
                              className="react-select dark label-select font-proxima-semibold cursor-pointer"
                              classNamePrefix="account"
                              onChange={(e) => setTag(e)}
                              value={tag}
                              showClearButton
                              placeholder="Click here to add tags"
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        {/* STATS */}
                        <TradeStatistics />

                        {/* EDITOR */}
                        <div className="px-5 pb-5 pt-2">
                          <div className="block dark:hidden">
                            <ReactQuill
                              theme="snow"
                              className="rounded-lg text-base dark:text-white text-black"
                              placeholder="Add your note here"
                              value={quillValue}
                              onChange={setQuillValue}
                            />
                          </div>
                          <div className="hidden dark-editor dark:block">
                            <ReactQuill
                              theme="snow"
                              className="rounded-lg text-base dark:text-white text-black"
                              placeholder="Add your note here"
                              value={quillValue}
                              onChange={setQuillValue}
                            />
                          </div>
                        </div>
                      </div>

                      {/* ACTION BUTTONS */}
                      {currentTab === "All Notes" ||
                      currentTab === "Trade Notes" ||
                      currentTab === "Daily Journal" ? (
                        ""
                      ) : (
                        <div className="p-5 pt-0">
                          <div className="flex items-center justify-end gap-2">
                            <Button
                              onClick={handleClear}
                              className="text-sm font-proxima-semibold text-black py-2 rounded-md normal-case bg-[#f5f5f5] hover:bg-opacity-80 border border-[#E5E5E5] shadow-none hover:shadow-none"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={addNote}
                              disabled={loading}
                              className="text-sm font-proxima-semibold text-white py-2 rounded-md normal-case bg-buttonColor hover:bg-opacity-80 shadow-none hover:shadow-none"
                            >
                              {loading ? (
                                <Spinner className="h-full" />
                              ) : edit ? (
                                "Update details"
                              ) : (
                                "Save details"
                              )}
                            </Button>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>

      {/* ADD LABEL DIALOG */}
      <Dialog
        open={addLabel}
        handler={() => setAddLabel(!addLabel)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black leading-none">
              Add Label
            </h5>
            <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2">
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="px-5 py-3">
            <div className="form-group">
              <label
                htmlFor="labelName"
                className="text-sm font-proxima-semibold text-black"
              >
                Label Name <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                name="labelName"
                id="labelName"
                placeholder="Type your label name"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
              />
            </div>
            <div className="form-group mt-5">
              <label
                htmlFor="labelColor"
                className="text-sm font-proxima-semibold text-black"
              >
                Label Color <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center gap-2 flex-wrap mt-2">
                {labelColors.map((color) => (
                  <div
                    className={`w-10 h-10 rounded-full flex-shrink-0 border cursor-pointer flex items-center justify-center`}
                    style={{
                      borderColor:
                        labelColorSelected === color.color
                          ? color.color
                          : "#E5E5E5",
                    }}
                    onClick={() => setLabelColorSelected(color.color)}
                    key={color.id}
                  >
                    <div
                      className="w-8 h-8 rounded-full"
                      style={{ backgroundColor: color.color }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="border-t border-[#E5E5E5] flex items-center justify-end px-5 py-3">
            <Button
              className="shadow-none hover:shadow-none bg-buttonColor text-sm font-proxima-semibold text-white py-2 normal-case"
              onClick={addLabelFn}
            >
              Submit
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Notebook;
