/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Drawer,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

/* ICONS */
import { HiBars2 } from "react-icons/hi2";
import { MdDarkMode } from "react-icons/md";
import { FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { IoWallet } from "react-icons/io5";
import { FaFileUpload, FaSync } from "react-icons/fa";
import { RiLogoutBoxFill } from "react-icons/ri";

/* IMAGES */
import Profile from "../../../assets/images/profile.jpg";

/* DATA */
import navigationMenu from "../../../data/navigation";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../assets/images/logo-dark.png";
import actions from "../../../redux/user/actions";

import { defaultStaticRanges } from "../../../components/others/date-ranges";
import { getUserExchange } from "../../../service/api";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const ResponsiveHeader = () => {
  const { user, exchanges } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [accountOptions, setAccountOptions] = useState([
    { label: "All accounts", value: "All accounts" },
  ]);
  const [exchange, setExchange] = useState({
    label: "All accounts",
    value: "All accounts",
  });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  useEffect(() => {
    if (exchange && exchange?.value !== "All accounts") {
      dispatch(
        actions.setSelectedExchange(
          exchanges?.filter((obj) => obj._id === exchange.value)[0]
        )
      );
    } else {
      dispatch(actions.setSelectedExchange(null));
    }
  }, [exchange]);

  useEffect(() => {
    if (exchanges?.length === 0) {
      let data = [{ label: "All accounts", value: "All accounts" }];
      exchanges?.map((obj) => {
        data.push({
          label: obj?.exchange + " " + (obj?.accountId ? obj?.accountId : ""),
          value: obj?._id,
        });
      });

      setAccountOptions(data);
    }
  }, [exchanges?.length]);

  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* DARK MODE */
  const darkModeState =
    localStorage.getItem("darkMode") === "false"
      ? false
      : localStorage.getItem("darkMode") === "true"
      ? true
      : false;
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "false"
      ? false
      : localStorage.getItem("darkMode") === "true"
      ? true
      : false
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  useEffect(() => {
    setDarkMode(
      localStorage.getItem("darkMode") === "false"
        ? false
        : localStorage.getItem("darkMode") === "true"
        ? true
        : false
    );
  }, [darkModeState]);

  function capitalizeFirstLetter(data) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getUserExchanges = () => {
    getUserExchange().then((res) => {
      if (res?.data) {
        dispatch(actions.setConnectedExchange(res?.data));
        let data = [{ label: "All accounts", value: "All accounts" }];
        res?.data?.map((obj) => {
          data.push({
            label:
              capitalizeFirstLetter(obj?.exchange) +
              " " +
              (obj?.accountId ? obj?.accountId : ""),
            value: obj?._id,
          });
        });
        setAccountOptions(data);
      }
    });
  };

  useEffect(() => {
    getUserExchanges();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("isLoggedIn", false);
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
    dispatch(actions.setToken(null));
    navigate("/");
    window.location.reload();
  };

  return (
    <header className="bg-white dark:bg-darkSidebar border-b border-[#E0E0E0] dark:border-darkBorder p-3 w-full">
      <div className="container-full-width">
        {/* TOP HEADER */}
        <div className="flex items-center justify-between">
          <Button
            className="bg-transparent dark:bg-bgDark shadow-none hover:shadow-none border border-[#E5E5E5] dark:border-darkBorder p-2 rounded-full hover:bg-black hover:bg-opacity-10 block xl:hidden lg:hidden"
            onClick={() => setMobileDrawer(!mobileDrawer)}
          >
            <HiBars2 className="w-5 h-5 text-black dark:text-white" />
          </Button>

          <div className="flex items-center gap-1">
            {/* DARK MODE */}
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 p-3 rounded-full relative"
              onClick={() => setDarkMode(!darkMode)}
            >
              <MdDarkMode className="w-5 h-5 text-greyText dark:text-lightGreyText" />
            </Button>
            <Menu
              animate={{
                mount: { y: 0 },
                unmount: { y: 25 },
              }}
              allowHover
            >
              <MenuHandler>
                <div className="flex items-center gap-3 hover:outline-none cursor-pointer">
                  <div className="bg-white border border-[#E5E5E5] rounded-full">
                    <img
                      src={Profile}
                      className="w-10 h-10 object-contain rounded-full"
                      alt="User profile"
                    />
                  </div>
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white leading-none">
                      {user?.firstName}&nbsp;{user?.lastName}{" "}
                    </h2>
                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText leading-none mt-1">
                      {user?.role}
                    </p>
                  </div>
                </div>
              </MenuHandler>
              <MenuList className="p-0 mt-1 bg-white dark:bg-darkSidebar border dark:border-darkBorder">
                <MenuItem className="hover:bg-buttonColor hover:bg-opacity-10 border-b border-[#E5E5E5] dark:border-darkBorder rounded-none py-3">
                  <Link to="/profile">
                    <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white">
                      Your Profile
                    </p>
                  </Link>
                </MenuItem>
                <MenuItem className="hover:bg-buttonColor hover:bg-opacity-10 rounded-none py-3">
                  <Link to="/">
                    <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white">
                      Logout
                    </p>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>

        {/* ACCOUNTS AND RANGEPICKER */}
        <div className="grid grid-cols-2 gap-5 mt-3">
          <div className="col-span-1 relative">
            <div
              ref={domNode}
              className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4 h-full"
            >
              <input
                type="text"
                name="dateRange"
                placeholder="Select the date range"
                value={`${startDate} - ${endDate}`}
                onClick={() => setDatepicker(!datepicker)}
                className="w-[18vh] bg-white dark:bg-darkSidebar outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-[#4B4B4B] dark:text-white"
              />
            </div>
            {datepicker && (
              <div
                ref={domNode}
                className="absolute top-10 bottom-0 transition-all duration-300 ease-linear z-50"
              >
                <div className="block dark:hidden">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    staticRanges={defaultStaticRanges}
                    onChange={(e) => {
                      setStartDate(
                        moment(e.selection.startDate).format("DD/MM/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("DD/MM/YYYY")
                      );
                    }}
                    inputRanges={[]}
                    className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                  />
                </div>
                <div className="hidden dark:block">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    staticRanges={defaultStaticRanges}
                    onChange={(e) => {
                      setStartDate(
                        moment(e.selection.startDate).format("DD/MM/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("DD/MM/YYYY")
                      );
                    }}
                    inputRanges={[]}
                    className="shadow-xl font-proxima-regular rounded-xl border border-darkBorder cursor-pointer calendarDark"
                  />
                </div>{" "}
              </div>
            )}
          </div>
          <div className="col-span-1">
            <div className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4">
              <IoWallet className="w-4 h-4 text-[#C9C8C8] dark:text-lightGreyText" />
              <Select
                value={exchange}
                options={accountOptions}
                isMulti
                className={`${
                  darkMode ? "react-select dark" : "react-select"
                } font-proxima-semibold cursor-pointer`}
                classNamePrefix="account"
                placeholder="Select accounts"
                onChange={(e) => {
                  if (
                    e?.filter((obj) => obj.label === "All accounts")?.length > 0
                  ) {
                    setExchange(
                      accountOptions?.filter(
                        (obj) => obj.label !== "All accounts"
                      )
                    );
                  } else {
                    setExchange(e);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE DRAWER */}
      <Drawer
        open={mobileDrawer}
        onClose={() => setMobileDrawer(!mobileDrawer)}
        className="bg-sidebar dark:bg-darkSidebar p-0 !h-screen"
        overlayProps={{
          className: "h-screen fixed",
        }}
      >
        <div className="bg-sidebarHeader dark:bg-darkSidebar border-b border-sidebarHeaderBorder dark:border-darkBorder p-5 flex items-center justify-between">
          <img
            src={Logo}
            className="h-auto w-[12vh] object-cover"
            alt="Trade Talez"
          />
          <Button
            className="shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-white hover:bg-opacity-10"
            onClick={() => setMobileDrawer(!mobileDrawer)}
          >
            {mobileDrawer ? (
              <FaRegCircle className="w-4 h-4 text-sidebarText" />
            ) : (
              <FaRegCircleDot className="w-4 h-4 text-sidebarText" />
            )}
          </Button>
        </div>

        {/* MENU */}
        <div className="px-5 py-5">
          <div className="">
            <p className="text-[1.4vh] font-proxima-bold text-white">
              IMPORT TRADES
            </p>
            <Link
              to="/import-trades/file-upload"
              className="w-full group flex items-center gap-3 first:mt-0 mt-5"
              onClick={() => setMobileDrawer(!mobileDrawer)}
            >
              <FaFileUpload className="w-5 h-5 text-sidebarText group-hover:text-white transition-all duration-300 ease-in-out" />
              <p className="text-base font-proxima-semibold text-sidebarText group-hover:text-white leading-none transition-all duration-300 ease-in-out">
                File upload
              </p>
            </Link>
            <Link
              to="/import-trades/broker-sync"
              className="w-full group flex items-center gap-3 first:mt-0 mt-8"
              onClick={() => setMobileDrawer(!mobileDrawer)}
            >
              <FaSync className="w-4 h-4 text-sidebarText group-hover:text-white transition-all duration-300 ease-in-out" />
              <p className="text-base font-proxima-semibold text-sidebarText group-hover:text-white leading-none transition-all duration-300 ease-in-out">
                Broker Sync
              </p>
            </Link>
          </div>

          <div className="mt-8">
            <p className="text-[1.4vh] font-proxima-bold text-white">
              OTHER OPTIONS
            </p>
            {navigationMenu.map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first:mt-5 mt-8"
                onClick={() => setMobileDrawer(!mobileDrawer)}
              >
                <nav.icon
                  className={`${
                    !mobileDrawer && "w-6 h-6 mx-auto"
                  } w-5 h-5 text-sidebarText group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div className={`${!mobileDrawer && "hidden"}`}>
                  <p className="text-base font-proxima-semibold text-sidebarText group-hover:text-white leading-none transition-all duration-300 ease-in-out">
                    {nav.name}
                  </p>
                  <p className="text-sm font-proxima-semibold text-sidebarText group-hover:text-white group-hover:text-opacity-50 text-opacity-40 transition-all duration-300 ease-in-out">
                    {nav.smallText}
                  </p>
                </div>
              </Link>
            ))}
          </div>

          <div className="mt-8">
            <Link to="/" className="mt-5 group w-full flex items-center gap-3">
              <RiLogoutBoxFill
                className={`${
                  !mobileDrawer && "w-6 h-6 mx-auto"
                } w-5 h-5 text-sidebarText dark:text-lightGreyText group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <p
                onClick={handleLogout}
                className={`${
                  !mobileDrawer && "hidden"
                } text-base font-proxima-semibold text-sidebarText dark:text-lightGreyText group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
              >
                Logout
              </p>
            </Link>

            <a
              href="https://discord.gg/Z6DMHmXSmc"
              className="block mt-8"
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-full bg-[#7389DA] rounded-md p-2">
                <div className="flex items-center w-full">
                  <img
                    src={require("../../../assets/images/discord-icon.png")}
                    className="w-10 h-10 flex-shrink-0"
                    alt="Join out discord"
                  />
                  <div className="w-full p-0">
                    <h5 className="text-[1.8vh] font-proxima-semibold text-white text-center">
                      Join our discord
                    </h5>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Drawer>
    </header>
  );
};

export default ResponsiveHeader;
