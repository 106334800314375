/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import DataTable from "react-data-table-component";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import {
  checkSubscription,
  clearTrade,
  deleteExchane,
  getProfile,
  getUserExchange,
  subscriptionPlan,
  subscriptionPlanCF,
  unsubscriptionPlan,
  updatePassword,
  updateProfile,
} from "../../../service/api";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Card,
  CardBody,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Tab,
  Button,
  Popover,
  PopoverHandler,
  PopoverContent,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

import { toast } from "react-toastify";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/design/input";

/* ICONS */
import { IoMdSettings, IoMdEyeOff, IoMdEye } from "react-icons/io";
import { FaCalendar, FaFileUpload, FaUserCircle } from "react-icons/fa";
import { SlOptionsVertical } from "react-icons/sl";
import { IoClose } from "react-icons/io5";

/* IMAGES */
import Profile from "../../../assets/images/profile.jpg";

import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { defaultStaticRanges } from "../../../components/others/date-ranges";

import PricingBox from "../../../components/others/pricing";
import pricingPlans from "../../../data/pricing";
import Zerodha from "../../../assets/images/brokers/zerodha-full.webp";
import Upstox from "../../../assets/images/brokers/upstox-full.webp";
import Dhan from "../../../assets/images/brokers/dhan.png";
import Fyers from "../../../assets/images/brokers/fyers.jpg";
import AngelOne from "../../../assets/images/brokers/angel-one.png";
import Motilal from "../../../assets/images/brokers/motilal-oswal.png";

/* COLUMNS */
const columns = [
  {
    name: "Account",
    selector: (row) => (
      <span className="text-sm font-proxima-semibold text-black dark:text-white dark:text-opacity-60 text-opacity-60">
        {row.account}
      </span>
    ),
  },
  {
    name: "Broker name",
    selector: (row) => (
      <span className="text-sm font-proxima-semibold text-black dark:text-white dark:text-opacity-60 text-opacity-60">
        {row.brokerName}
      </span>
    ),
  },
  {
    name: "Upload date",
    selector: (row) => (
      <span className="text-sm font-proxima-semibold text-black dark:text-white dark:text-opacity-60 text-opacity-60">
        {row.uploadDate}
      </span>
    ),
  },
  {
    name: "Number of trades",
    selector: (row) => (
      <span className="text-sm font-proxima-semibold text-black dark:text-white dark:text-opacity-60 text-opacity-60">
        {row.trades}
      </span>
    ),
  },
  {
    name: "Status",
    selector: (row) => (
      <span
        className={`text-sm font-proxima-bold ${
          row.status === "Success" ? "text-green-500" : "text-red-500"
        }`}
      >
        {row.status}
      </span>
    ),
  },
  {
    name: "Actions",
    selector: (row) => (
      <span className="text-sm font-proxima-semibold text-buttonColor cursor-pointer">
        View details
      </span>
    ),
  },
];

/* SAMPLE DATA */
const sampleData = [
  {
    id: 1,
    account: "Zerodha 1675",
    brokerName: "Zerodha",
    uploadDate: "22 Dec, 2023 14:50:45 PM",
    trades: "250",
    status: "Success",
  },
];

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const ProfileSettings = () => {
  const { exchanges, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  /* GENERAL SETTINGS */
  const [clear, setClear] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFilename] = useState("");
  const [mode, setMode] = useState("");
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const location = useLocation();

  const [open, setOpen] = useState(false);
  // const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())
  const handleOpen = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [profile, setProfile] = useState();
  const handleOpen1 = () => setOpen1(!open1);
  const handleOpen2 = () => setOpen2(!open2);
  const handleOpen3 = () => setOpen3(!open3);
  const [selectedTab, setSelectedTab] = useState("Your subscriptions");

  useEffect(() => {
    if (location?.hash && location.hash === "#subscription") {
      setSelectedTab("Your subscriptions");
    }
  }, [location]);
  /* INPUT TYPE FUNCTION */
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0]?.name);
  };

  /* GET PROFILE FUNCTION */
  const profileData = () => {
    getProfile()
      .then((res) => {
        if (res?.data) {
          setEmail(res?.data?.email);
          // setUsername(res?.data?.userName);
          setFirstName(res?.data?.firstName);
          setLastName(res?.data?.lastName);
          setMode(res?.data?.mode);
          setProfile(res?.data);
          dispatch(actions.setUser(res?.data));
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* UPDATE PROFILE FUNCTION */
  const profileUpdate = () => {
    const formData = new FormData();
    // formData.append("userName", username);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("twoFactorAuthentication", true);
    if (file) {
      formData.append("profileImage", file);
    }
    formData.append("mode", mode);

    updateProfile(formData)
      .then((res) => {
        toast.success(res?.message);
        profileData();
        setFile();
        dispatch(actions.setUser(res?.data));
      })
      .catch((e) => {
        console.log("Error: ", e);
        toast.error(e?.message);
      });
  };

  /* SECURITY SETTINGS */
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordToggle, setOldPasswordToggle] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordToggle, setNewPasswordToggle] = useState(false);
  const [confNewPassword, setConfNewPassword] = useState("");
  const [confNewPasswordToggle, setConfNewPasswordToggle] = useState(false);
  const [exchangeId, setExchangeId] = useState("");

  /* UPDATE PASSWORD FUNCTION */
  const passwordUpdate = () => {
    if (oldPassword === "") {
      return toast.error("Please enter old password");
    }
    if (newPassword === "") {
      return toast.warning("Please enter new password");
    } else if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/i.test(
        newPassword
      )
    ) {
      return toast.warning(
        "Password must contain minimum eight characters, at least one letter, one special character and one number."
      );
    }
    // if (newPassword === "") {
    //   return toast.error("Please enter new password");
    // }
    if (confNewPassword === "") {
      return toast.error("Please enter confirm new password");
    }
    if (newPassword !== confNewPassword) {
      return toast.error("New password doesn't match");
    }
    if (oldPassword === newPassword) {
      return toast.error("New password can not be same as old password ");
    }
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("password", newPassword);
    updatePassword(formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          setConfNewPassword("");
          setNewPassword("");
          setOldPassword("");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
        toast.error(e?.response?.data?.message);
      });
  };

  useEffect(() => {
    profileData();
  }, [""]);

  const { startDate, endDate } = useSelector((state) => state.user);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const clearTradeData = (id) => {
    if (clear === "CLEAR ALL TRADES") {
      clearTrade(id, { startDate: selectedStartDate, endDate: selectedEndDate })
        .then((res) => {
          if (res?.status) {
            handleOpen();
            setExchangeId("");
            setClear("");
            // setStartDate(new Date())
            // setEndDate(new Date())
            toast.success(res?.message);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error("Something went wrong");
        });
    } else {
      console.log("Encountered an error while clearing the trades.");
    }
  };

  /* CAPITAL FIRST LETTER */
  function capitalizeFirstLetter(data) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  /* GET  EXCHANGE */
  const getUserExchanges = () => {
    getUserExchange().then((res) => {
      if (res?.data) {
        dispatch(actions.setConnectedExchange(res?.data));
      }
    });
  };

  /* DELETE EXCHANGE */
  const deleteExchangeData = (id) => {
    deleteExchane(id).then((res) => {
      if (res?.message) {
        handleOpen1();
        setExchangeId("");
        toast.success(res?.message);
        getUserExchanges();
      }
    });
  };
  const [selectedPlanName, setSelectedPlanName] = useState("");
  const [activeTab, setActiveTab] = useState("Monthly");

  useEffect(() => {
    if (user?.subscription) {
      if (user?.subscription?.subscriptionPlane) {
        setSelectedPlanName(user?.subscription?.subscriptionPlane?._id);
        if (user?.subscription?.subscriptionPlane?.validity > 31) {
          setActiveTab("Annually");
        }
      }
    }
  }, [user]);

  const [planLoader, setPlanLoader] = useState(false);
  const [windowLoader, setWindowLoader] = useState(false);
  const [planId, setPlanId] = useState("");
  const [mobile, setMobile] = useState("");

  const upgradePlan = (id) => {
    setSelectedPlanName(id);
    setPlanLoader(true);
    let body = {
      subscriptionPlane: id,
    };
    if (promocode) {
      body.coupenCode = promocode;
    }
    subscriptionPlan(body)
      .then((res) => {
        setPlanLoader(false);
        if (res?.status && res?.data) {
          let windowP = window.open(
            res?.data,
            "Payment",
            "height=800,width=1200"
          );
          setPlanId("");
          console.log("windowP: ", windowP);
          let timer = setInterval(function () {
            if (windowP.closed) {
              clearInterval(timer);
              setWindowLoader(true);

              checkSubscription({ subscriptionId: res?.subscriptionId })
                .then((obj) => {
                  setWindowLoader(false);
                  if (obj?.status) {
                    profileData();
                    setPromocode("");
                  }
                })
                .catch((err) => {
                  setWindowLoader(false);
                  if (err?.response?.data?.message) {
                    toast.error(err.response?.data?.message);
                  }
                });
              setTimeout(() => {
                profileData();
              }, 45000);
            }
          }, 1000);
          // setWindowPopup(windowPopup)
          // win.onclose(()=>{
          //   profileData()
          // })
          // window.location.href = res?.data
        }
      })
      .catch((err) => {
        setPlanLoader(false);
        if (err?.response?.data?.message) {
          toast.error(err.response?.data?.message);
        }
      });
  };
  const [promocode, setPromocode] = useState("");

  const upgradePlanCF = (id) => {
    setSelectedPlanName(id);
    setPlanLoader(true);
    subscriptionPlanCF({ subscriptionPlane: id, mobile, coupenCode: promocode })
      .then((res) => {
        setPlanLoader(false);
        if (res?.status && res?.data) {
          let windowP = "";
          window.location.href = res?.data;
          setPlanId("");
          setOpen3(false);
          // setWindowPopup(windowPopup)
          // win.onclose(()=>{
          //   profileData()
          // })
          // window.location.href = res?.data
        }
      })
      .catch((err) => {
        setPlanLoader(false);
        if (err?.response?.data?.message) {
          toast.error(err.response?.data?.message);
        }
      });
  };

  const upgradePlan2 = (id) => {
    setPlanId(id);
    setOpen3(true);
  };

  window.beforeunload = function () {
    var win = window.opener;
    if (!win.closed) {
      profileData();
    }
  };

  /* BROKER CARD */
  const BrokerCard = (props) => {
    return (
      <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md">
        <CardBody className="p-5">
          <div className="flex items-center justify-between">
            <div className="bg-white rounded-md p-2">
              <img
                src={props.brokerImg}
                className="h-10 w-auto object-contain rounded-md"
                alt={props.brokerName}
              />
            </div>
            <Popover placement="bottom-left">
              <PopoverHandler>
                <Button className="p-2 rounded-full bg-white dark:bg-bgDark hover:bg-[#F5F5F5] hover:bg-opacity-50 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none">
                  <SlOptionsVertical className="w-3 h-3 text-[#777]" />
                </Button>
              </PopoverHandler>
              <PopoverContent className="w-[20vh] dark:bg-darkSidebar dark:border-darkBorder p-2 z-50">
                <Link
                  to="/import-trades/file-upload"
                  className="block px-4 py-2 text-sm font-proxima-semibold dark:text-white hover:bg-buttonColor hover:text-buttonColor hover:bg-opacity-10 rounded-md transition-all duration-300 ease-in-out"
                >
                  Import CSV
                </Link>
                <div
                  onClick={() => {
                    handleOpen1();
                    setExchangeId(props?.data?._id);
                  }}
                  className="cursor-pointer px-4 py-2 dark:text-white text-sm font-proxima-semibold hover:bg-red-500 hover:text-red-500 hover:bg-opacity-10 rounded-md transition-all duration-300 ease-in-out"
                >
                  Disconnect account
                </div>
                <div
                  onClick={() => {
                    handleOpen();
                    setExchangeId(props?.data?._id);
                  }}
                  className="cursor-pointer px-4 py-2 dark:text-white text-sm font-proxima-semibold hover:bg-red-500 hover:text-red-500 hover:bg-opacity-10 rounded-md transition-all duration-300 ease-in-out"
                >
                  Clear trades
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="pt-5">
            <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
              Connect your {props.brokerName} account to effortlessly import and
              monitor your trades.
            </p>
            <div className="mt-3">
              <p className="text-base font-proxima-bold text-black dark:text-white">
                Account:{" "}
                <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                  {capitalizeFirstLetter(
                    props.data?.accountName ? props.data?.accountName : "-"
                  )}
                </span>
              </p>
              <p className="text-base font-proxima-bold text-black dark:text-white">
                Account Id:
                <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                  {/* {capitalizeFirstLetter(props.brokerName)} */}
                  &nbsp;{props?.data?.accountId ? props?.data?.accountId : "-"}
                </span>
              </p>
              <p className="text-base font-proxima-bold text-black dark:text-white mt-1">
                Status:{" "}
                {props?.data?.status === "Disconnected" ? (
                  <>
                    <span className="font-proxima-regular text-red-500">
                      {props?.data?.status}
                    </span>
                  </>
                ) : props?.data?.status === "Connected" ? (
                  <span className="font-proxima-regular text-green-500">
                    {props?.data?.status}
                  </span>
                ) : (
                  props?.data?.error && (
                    <span className="font-proxima-regular text-red-500">
                      Error - {props?.data?.error}
                    </span>
                  )
                )}
              </p>
              <p className="text-base font-proxima-bold text-black dark:text-white mt-1">
                Balance:{" "}
                <span className="font-proxima-regular text-black-500">
                  {props.balance
                    ? "₹" + parseFloat(props.balance).toLocaleString("en-IN")
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  const [datepicker, setDatepicker] = useState(false);

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const d = new Date();
  const d1 = new Date();
  const minDate = d.setFullYear(d.getFullYear() - 7);
  const maxDate = d1.setDate(d1.getDate());

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(
      endDate?.split("/")[2],
      Number(endDate?.split("/")[1]) - 1,
      endDate?.split("/")[0]
    ),
    key: "selection",
  });

  const [unsubscribeId, setUnsubscribeId] = useState();

  const subscriptionMyPlan = () => {
    if (!mobile || mobile === "") {
      return;
    }
    upgradePlanCF(planId);
  };

  const unsubscriptionMyPlan = () => {
    setPlanLoader(true);
    unsubscriptionPlan(unsubscribeId)
      ?.then((res) => {
        setPlanLoader(false);
        if (res?.status) {
          toast.success("Plan cancelled successfully");
          profileData();
          setUnsubscribeId("");
          window.location.reload();
        }
      })
      .catch((err) => {
        setPlanLoader(false);
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const [promocodePopup, setPromocodePopup] = useState(false);
  return (
    <section className="p-3 xl:p-5 lg:p-5">
      <div className="container-full-width">
        <Tabs
          value={
            location.hash === "#subscription" ? selectedTab : "General settings"
          }
          className="overflow-visible"
        >
          <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-y-5 gap-0 xl:gap-5 lg:gap-5">
            <div className="col-span-1">
              <TabsHeader
                className="p-0 bg-transparent shadow-none w-full block"
                indicatorProps={{
                  className:
                    "bg-buttonColor bg-opacity-5 dark:bg-opacity-20 shadow-none text-buttonColor",
                }}
              >
                <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full h-full shadow-none">
                  <CardBody className="px-5 py-0">
                    <div className="py-5 pb-3 border-b border-[#E5E5E5] dark:border-darkBorder">
                      <div className="flex items-center gap-2">
                        <FaUserCircle className="w-5 h-5 text-[#777] dark:text-lightGreyText" />
                        <h5 className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText">
                          User Settings
                        </h5>
                      </div>
                      <div className="mt-3">
                        <Tab
                          key="General settings"
                          value="General settings"
                          className="text-base font-proxima-semibold px-5 py-3 justify-start dark:text-white"
                          activeClassName="tab-active"
                        >
                          General settings
                        </Tab>
                        <Tab
                          key="Security settings"
                          value="Security settings"
                          className="text-base font-proxima-semibold px-5 py-3 justify-start dark:text-white"
                          activeClassName="tab-active"
                        >
                          Security settings
                        </Tab>
                        <Tab
                          key="Your subscriptions"
                          value="Your subscriptions"
                          className="text-base font-proxima-semibold px-5 py-3 justify-start dark:text-white"
                          activeClassName="tab-active"
                        >
                          Your subscription
                        </Tab>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="flex items-center gap-2">
                        <IoMdSettings className="w-5 h-5 text-[#777] dark:text-lightGreyText" />
                        <h5 className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText">
                          Platform Settings
                        </h5>
                      </div>
                      <div className="mt-3">
                        <Tab
                          key="Accounts"
                          value="Accounts"
                          className="text-base font-proxima-semibold px-5 py-3 justify-start dark:text-white"
                          activeClassName="tab-active"
                        >
                          Accounts
                        </Tab>
                        {/* <Tab
                          key="Import History"
                          value="Import History"
                          className="text-base font-proxima-semibold px-5 py-3 justify-start dark:text-white"
                          activeClassName="tab-active"
                        >
                          Import History
                        </Tab> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </TabsHeader>
            </div>
            <div className="col-span-4">
              <TabsBody className="shadow-none z-0 overflow-visible">
                {/* GENERAL SETTINGS */}
                <TabPanel
                  key="General settings"
                  value="General settings"
                  className="p-0 shadow-none rounded-lg"
                >
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full shadow-none">
                    <CardBody className="p-0">
                      <div className="block xl:flex lg:flex items-center justify-between border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3">
                        <div>
                          <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                            General Details
                          </h2>
                          <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                            Update your photo and personal details here.
                          </p>
                        </div>
                        <div className="flex items-center gap-2 mt-5 xl:mt-0 lg:mt-0">
                          {/* <Button className="w-full xl:w-auto lg:w-auto py-2 text-sm font-proxima-semibold text-black bg-white border border-[#E5E5E5] shadow-none hover:shadow-none normal-case hover:bg-black hover:bg-opacity-5">
                            Cancel
                          </Button> */}
                          <Button
                            onClick={profileUpdate}
                            className="w-full xl:w-auto lg:w-auto py-2 text-sm font-proxima-semibold bg-buttonColor shadow-none hover:shadow-none normal-case hover:bg-opacity-80"
                          >
                            Save details
                          </Button>
                        </div>
                      </div>
                      <div className="p-5">
                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                          <div className="col-span-2">
                            <form className="border-r-0 xl:border-r lg:border-r border-[#E5E5E5] dark:border-darkBorder pr-0 xl:pr-10 lg:pr-10">
                              <div className="flex items-center gap-5">
                                <div className="form-group w-full">
                                  <label
                                    htmlFor="firstName"
                                    className="text-sm font-proxima-semibold text-black dark:text-white"
                                  >
                                    First name{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="Type your first name"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group w-full">
                                  <label
                                    htmlFor="lastName"
                                    className="text-sm font-proxima-semibold text-black dark:text-white"
                                  >
                                    Last name{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Type your last name"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group mt-5">
                                <label
                                  htmlFor="profileEmail"
                                  className="text-sm font-proxima-semibold text-black dark:text-white"
                                >
                                  Email address{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <Input
                                  type="email"
                                  name="profileEmail"
                                  id="profileEmail"
                                  placeholder="Type your email address"
                                  value={email}
                                />
                              </div>
                              {/* <div className="form-group mt-5">
                                <label
                                  htmlFor="username"
                                  className="text-sm font-proxima-semibold text-black dark:text-white"
                                >
                                  Username{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <Input
                                  type="text"
                                  name="username"
                                  id="username"
                                  placeholder="Type your username"
                                  value={username}
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                              </div> */}
                              <div className="form-group mt-5">
                                <label
                                  htmlFor="timezone"
                                  className="text-sm font-proxima-semibold text-black dark:text-white"
                                >
                                  Timezone{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="h-[5vh] w-full border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4 mt-1">
                                  <div className="block dark:hidden">
                                    <TimezoneSelect
                                      value={timezone}
                                      onChange={(e) => setTimezone(e)}
                                      className="react-select upload-select font-proxima-semibold"
                                      classNamePrefix="account"
                                      placeholder="Select timezone"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <TimezoneSelect
                                      value={timezone}
                                      onChange={(e) => setTimezone(e)}
                                      className="react-select dark upload-select font-proxima-semibold"
                                      classNamePrefix="account"
                                      placeholder="Select timezone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
                            <div className="pl-0 xl:pl-10 lg:pl-10">
                              <div className="flex items-center gap-5">
                                <div className="bg-white border border-[#E5E5E5] rounded-full flex-shrink-0">
                                  <img
                                    src={
                                      user?.profileImage
                                        ? user?.profileImage
                                        : Profile
                                    }
                                    className="w-16 h-16 object-contain rounded-full"
                                    alt="User profile"
                                  />
                                </div>
                                <div>
                                  {/* <h2 className="text-lg font-proxima-semibold text-black dark:text-white leading-none">
                                    {username}
                                  </h2> */}
                                  <div className="flex items-center gap-5">
                                    {file && (
                                      <Button
                                        onClick={() => setFile()}
                                        className="text-sm font-proxima-semibold text-red-500 shadow-none hover:shadow-none normal-case p-0 mt-2 rounded bg-transparent"
                                      >
                                        Remove
                                      </Button>
                                    )}
                                    <Button
                                      onClick={profileUpdate}
                                      className="text-sm font-proxima-semibold text-black dark:text-lightGreyText shadow-none hover:shadow-none normal-case p-0 mt-2 rounded bg-transparent"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mt-5 relative">
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="opacity-0 w-full h-full absolute top-0 left-0 z-10 cursor-pointer"
                                  onChange={handleChange}
                                />
                                <div className="border border-dashed border-buttonColor border-opacity-25 dark:border-opacity-100 p-5 bg-buttonColor bg-opacity-5 dark:bg-opacity-10 rounded-md">
                                  {file ? (
                                    <div className="grid grid-cols-2">
                                      <div className="col-span-1">
                                        <div className="w-full bg-white dark:bg-bgDark rounded-md shadow-md p-3">
                                          <div className="bg-[#F5F5F5] dark:bg-darkSidebar border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center">
                                            <img
                                              src={URL.createObjectURL(file)}
                                              className="h-[10vh] w-full object-cover rounded-lg"
                                              alt={fileName}
                                            />
                                          </div>
                                          <div className="mt-2">
                                            <h5 className="text-base font-proxima-semibold text-black dark:text-white line-clamp-1 text-center">
                                              {fileName}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="text-center">
                                      <FaFileUpload className="w-7 h-7 text-[#4B4B4B] dark:text-lightGreyText mx-auto" />
                                      <h2 className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white mt-2">
                                        Upload file from your computer
                                      </h2>
                                      <p className="text-sm font-proxima-regular text-[#4B4B4B] dark:text-white">
                                        (IMAGE)
                                      </p>
                                      <Button className="text-sm bg-buttonColor py-1 px-4 text-white font-proxima-regular mt-2 normal-case font-normal rounded">
                                        Upload File
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* SECURITY SETTINGS */}
                <TabPanel
                  key="Security settings"
                  value="Security settings"
                  className="p-0 shadow-none rounded-lg"
                >
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full shadow-none">
                    <CardBody className="p-0">
                      <div className="block xl:flex lg:flex items-center justify-between border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3">
                        <div>
                          <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                            Security Details
                          </h2>
                          <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                            Update your password. You must provide your current
                            password in order to change it.
                          </p>
                        </div>
                        <div className="flex items-center gap-2 mt-5 xl:mt-0 lg:mt-0">
                          <Button
                            onClick={() => {
                              setConfNewPassword("");
                              setNewPassword("");
                              setOldPassword("");
                            }}
                            className="w-full xl:w-auto lg:w-auto py-2 text-sm font-proxima-semibold text-black bg-white border border-[#E5E5E5] shadow-none hover:shadow-none normal-case hover:bg-black hover:bg-opacity-5"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={passwordUpdate}
                            className="w-full xl:w-auto lg:w-auto py-2 text-sm font-proxima-semibold bg-buttonColor shadow-none hover:shadow-none normal-case hover:bg-opacity-80"
                          >
                            Save details
                          </Button>
                        </div>
                      </div>
                      <div className="p-5">
                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 gap-y-5 xl:gap-5 lg:gap-5">
                          <div className="col-span-1">
                            <div className="form-group w-full">
                              <label
                                htmlFor="currentPassword"
                                className="text-sm font-proxima-semibold text-black dark:text-white"
                              >
                                Current Password{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative">
                                <Input
                                  type={oldPasswordToggle ? "text" : "password"}
                                  name="currentPassword"
                                  id="currentPassword"
                                  placeholder="Type your current password"
                                  value={oldPassword}
                                  onChange={(e) =>
                                    setOldPassword(e.target.value)
                                  }
                                />
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                                  onClick={() =>
                                    setOldPasswordToggle(!oldPasswordToggle)
                                  }
                                >
                                  {oldPasswordToggle ? (
                                    <IoMdEyeOff className="w-5 h-5 text-greyText" />
                                  ) : (
                                    <IoMdEye className="w-5 h-5 text-greyText" />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="form-group w-full">
                              <label
                                htmlFor="newPassword"
                                className="text-sm font-proxima-semibold text-black dark:text-white"
                              >
                                New Password{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative">
                                <Input
                                  type={newPasswordToggle ? "text" : "password"}
                                  name="newPassword"
                                  id="newPassword"
                                  placeholder="Type your new password"
                                  value={newPassword}
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                />
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                                  onClick={() =>
                                    setNewPasswordToggle(!newPasswordToggle)
                                  }
                                >
                                  {newPasswordToggle ? (
                                    <IoMdEyeOff className="w-5 h-5 text-greyText" />
                                  ) : (
                                    <IoMdEye className="w-5 h-5 text-greyText" />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="form-group w-full">
                              <label
                                htmlFor="confNewPassword"
                                className="text-sm font-proxima-semibold text-black dark:text-white"
                              >
                                Confim New Password{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative">
                                <Input
                                  type={
                                    confNewPasswordToggle ? "text" : "password"
                                  }
                                  name="confNewPassword"
                                  id="confNewPassword"
                                  placeholder="Type your new password"
                                  value={confNewPassword}
                                  onChange={(e) =>
                                    setConfNewPassword(e.target.value)
                                  }
                                />
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                                  onClick={() =>
                                    setConfNewPasswordToggle(
                                      !confNewPasswordToggle
                                    )
                                  }
                                >
                                  {confNewPasswordToggle ? (
                                    <IoMdEyeOff className="w-5 h-5 text-greyText" />
                                  ) : (
                                    <IoMdEye className="w-5 h-5 text-greyText" />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* SUBSCRIPTIONS */}
                <TabPanel
                  key="Your subscriptions"
                  value="Your subscriptions"
                  className="p-0 shadow-none rounded-lg"
                >
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full shadow-none">
                    <CardBody className="p-0">
                      {/* HEADING */}
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3">
                        <div className="flex items-center justify-between">
                          <div className="p-0">
                            <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                              Your subscription
                            </h2>
                            <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                              The details of your active subscription and other
                              pricing plans for traders at every stage of
                              growth.
                            </p>
                          </div>
                          <div className="w-fit flex items-center justify-center gap-1 bg-white dark:bg-bgDark border border-borderGrey dark:border-darkBorder rounded-lg p-1">
                            <Button
                              className={`shadow-none hover:shadow-none ${
                                activeTab === "Monthly"
                                  ? "bg-logoOcre font-proxima-semibold text-white border border-logo"
                                  : "bg-transparent font-proxima-semibold text-grey dark:text-sidebarText"
                              } text-sm rounded-md px-5 py-2 normal-case transition-all duration-300 ease-in-out`}
                              onClick={() => setActiveTab("Monthly")}
                            >
                              Monthly
                            </Button>
                            <Button
                              className={`shadow-none hover:shadow-none ${
                                activeTab === "Annually"
                                  ? "bg-logoOcre font-proxima-semibold text-white border border-logo"
                                  : "bg-transparent font-proxima-semibold text-grey dark:text-sidebarText"
                              } text-sm rounded-md px-5 py-2 normal-case transition-all duration-300 ease-in-out`}
                              onClick={() => setActiveTab("Annually")}
                            >
                              Annually
                            </Button>
                          </div>
                        </div>
                      </div>

                      {/* PLANS LIST */}
                      <div className="p-5">
                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          {pricingPlans.map((data) => (
                            <div className="col-span-1" key={data.id}>
                              <PricingBox
                                myPlan={
                                  user?.subscription?.subscriptionPlane?._id
                                }
                                upgradePlan={(id) => {
                                  upgradePlan(id);
                                }}
                                unsubscriptionMyPlan={(id) => {
                                  setUnsubscribeId(id);
                                  handleOpen2();
                                }}
                                promocode={promocode}
                                setPromocode={setPromocode}
                                // unsubscriptionMyPlan={unsubscriptionMyPlan}
                                planLoader={planLoader}
                                sId={user?.subscription?._id}
                                selectedPlanName={selectedPlanName}
                                // validity={user?.subscription?.isActive && user?.subscription?.subscriptionPlane?.validity > 0 ? (moment(user?.subscription?.paymentStartDate).add(user?.subscription?.subscriptionPlane?.validity, 'days')).fromNow() : 0}
                                validity={
                                  user?.subscription?.isActive &&
                                  user?.subscription?.subscriptionPlane
                                    ?.validity > 0
                                    ? moment(
                                        user?.subscription?.paymentStartDate
                                      )
                                        .add(
                                          user?.subscription?.subscriptionPlane
                                            ?.validity,
                                          "days"
                                        )
                                        .format("ll")
                                    : 0
                                }
                                name={data.name}
                                description={data.description}
                                features={data.features}
                                monthlyPrice={data.monthlyPrice}
                                originalMonthlyPrice={data.originalMonthlyPrice}
                                monthlyDiscount={data.monthlyDiscount}
                                annualPrice={data.annualPrice}
                                originalAnnualPrice={data.originalAnnualPrice}
                                annualDiscount={data.annualDiscount}
                                pId={
                                  activeTab === "Monthly"
                                    ? data?.id
                                    : data?.yearId
                                }
                                isMonthly={
                                  activeTab === "Monthly" ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>

                {/* ACCOUNTS */}
                <TabPanel
                  key="Accounts"
                  value="Accounts"
                  className="p-0 shadow-none rounded-lg"
                >
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full shadow-none">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3 block xl:flex lg:flex items-center justify-between">
                        <div>
                          <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                            Accounts
                          </h2>
                          <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                            The accounts connected with TradeTalez will be
                            listed here.
                          </p>
                        </div>
                        <Link
                          to="/import-trades/broker-sync"
                          className="block mt-5 xl:mt-0 lg:mt-0"
                        >
                          <Button className="w-full xl:w-auto lg:w-auto shadow-none hover:shadow-none bg-buttonColor py-2 text-sm font-proxima-semibold normal-case rounded-md hover:bg-opacity-80">
                            Add account
                          </Button>
                        </Link>
                      </div>
                      <div className="p-5">
                        {exchanges?.filter(
                          (o1) => o1.accountName !== "csv upload"
                        ).length === 0 && (
                          <Card className="bg-white dark:bg-darkSidebar border border-borderGrey dark:border-darkBorder rounded-md shadow-none">
                            <CardBody className="p-10">
                              <div className="w-2/5 mx-auto">
                                <h5 className="text-2xl font-proxima-bold text-black dark:text-white text-center">
                                  No brokers connected
                                </h5>
                                <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText text-center mt-2">
                                  You haven't connected any brokers with
                                  TradeTalez. Add broker to journal your trades
                                  and optimize your strategies.
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        )}
                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 gap-y-5 xl:gap-5 lg:gap-5">
                          {exchanges &&
                            exchanges?.length !== 0 &&
                            exchanges
                              ?.filter((o1) => o1.accountName !== "csv upload")
                              ?.map((obj) => {
                                return (
                                  <div className="col-span-1" key={obj?._id}>
                                    <BrokerCard
                                      brokerImg={
                                        obj?.exchange === "fyers"
                                          ? Fyers
                                          : obj?.exchange === "dhan"
                                          ? Dhan
                                          : obj?.exchange === "upstox"
                                          ? Upstox
                                          : obj?.exchange === "angel"
                                          ? AngelOne
                                          : obj?.exchange === "motilal"
                                          ? Motilal
                                          : Zerodha
                                      }
                                      brokerName={obj?.exchange}
                                      data={obj}
                                      balance={obj?.balance}
                                    />
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </TabPanel>
                <TabPanel
                  key="Import History"
                  value="Import History"
                  className="p-0 shadow-none rounded-lg"
                >
                  <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg w-full shadow-none">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder p-5 py-3">
                        <div>
                          <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                            Import history
                          </h2>
                          <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                            We store the history logs upto 1 month after you
                            connect the broker.
                          </p>
                        </div>
                      </div>
                      <div className="p-5">
                        <div className="block dark:hidden">
                          <DataTable
                            columns={columns}
                            data={sampleData}
                            className="trades-table"
                            selectableRows
                            responsive
                            pagination
                            paginationTotalRows={12}
                            noDataComponent={
                              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                  There are no records to display. Please
                                  connect your broker to see your import
                                  history.
                                </p>
                              </div>
                            }
                          />
                        </div>
                        <div className="hidden dark-pagination dark:block">
                          <DataTable
                            columns={columns}
                            data={sampleData}
                            className="trades-table dark"
                            selectableRows
                            responsive
                            pagination
                            paginationTotalRows={12}
                            noDataComponent={
                              <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-50 rounded-md border border-[#E5E5E5] dark:border-darkBorder py-5">
                                <p className="text-sm font-proxima-semibold text-black dark:text-white text-center">
                                  There are no records to display. Please
                                  connect your broker to see your import
                                  history.
                                </p>
                              </div>
                            }
                          />
                        </div>
                      </div>
                      <div className="p-5"></div>
                    </CardBody>
                  </Card>
                </TabPanel>
              </TabsBody>
            </div>
          </div>
        </Tabs>

        {/* CLEAR TRADES */}
        <Dialog
          open={open}
          handler={handleOpen}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="dark:bg-darkSidebar"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
              <h5 className="text-base font-proxima-bold text-black dark:text-white">
                Clear all trades
              </h5>
            </div>
            <div className="p-5 relative">
              {/* RANGE PICKER */}

              <p className="text-base font-proxima-regular text-black dark:text-white">
                Warning: This can't be undone. Clear trades from this account?
                All tags, trades, notes will be destroyed.
              </p>

              <div className="mt-5">
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Select the timeframe for which you want to clear trades
                  </label>
                  <div className="mt-1">
                    <div
                      ref={domNode}
                      className="flex items-center gap-3 border border-[#E5E5E5] dark:border-darkBorder rounded-md py-2 px-4"
                    >
                      <FaCalendar className="w-4 h-4 text-[#C9C8C8]" />
                      <input
                        type="text"
                        name="dateRange"
                        placeholder="Select the date range"
                        value={`${selectedStartDate} - ${selectedEndDate}`}
                        onClick={() => setDatepicker(!datepicker)}
                        className="w-[18vh] cursor-pointer bg-white dark:bg-darkSidebar outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-[#4B4B4B] dark:text-white"
                      />
                    </div>
                    {datepicker && (
                      <div
                        ref={domNode}
                        className="absolute top-16 bottom-0 transition-all duration-300 ease-linear z-50"
                      >
                        <div className="block dark:hidden">
                          <DateRangePicker
                            ranges={[selectionRange]}
                            dragSelectionEnabled
                            showPreview
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            staticRanges={defaultStaticRanges}
                            onChange={(e) => {
                              if (e?.selection) {
                                setSelectedEndDate(
                                  moment(e?.selection.endDate).format(
                                    "DD/MM/YYYY"
                                  )
                                );
                                setSelectedStartDate(
                                  moment(e?.selection.startDate).format(
                                    "DD/MM/YYYY"
                                  )
                                );
                              }
                              setSelectionRange(e?.selection);
                            }}
                            inputRanges={[]}
                            className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] cursor-pointer"
                          />
                        </div>
                        <div className="hidden dark:block">
                          <DateRangePicker
                            ranges={[selectionRange]}
                            dragSelectionEnabled
                            showPreview
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            staticRanges={defaultStaticRanges}
                            onChange={(e) => {
                              setSelectedEndDate(
                                moment(e?.selection.endDate).format(
                                  "DD/MM/YYYY"
                                )
                              );
                              setSelectedStartDate(
                                moment(e?.selection.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              );
                              setSelectionRange(e.selection);
                            }}
                            inputRanges={[]}
                            className="shadow-xl font-proxima-regular rounded-xl border border-[#E5E5E5] dark:border-darkBorder cursor-pointer calendarDark"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <p className="text-base font-proxima-bold text-black dark:text-white">
                  <span className="font-proxima-regular">Please type</span>{" "}
                  CLEAR ALL TRADES
                </p>

                <div className="w-full mt-1 mx-auto">
                  <input
                    value={clear}
                    onChange={(e) => {
                      setClear(e.target.value);
                    }}
                    type="text"
                    name="confirmText"
                    className="w-full text-center block bg-white dark:bg-bgDark border border-borderGrey dark:border-darkBorder rounded-md text-base font-proxima-semibold placeholder:font-proxima-regular text-black dark:text-white px-4 py-2 outline-none focus:outline-none"
                    placeholder="Type CLEAR ALL TRADES"
                  />
                </div>
              </div>
            </div>
            <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
              <Button
                className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
                onClick={handleOpen}
              >
                Cancel
              </Button>
              <Button
                onClick={() => clearTradeData(exchangeId)}
                className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
              >
                Confirm
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* DISCONNECT ACCOUNT */}
        <Dialog
          open={open1}
          handler={handleOpen1}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="dark:bg-darkSidebar"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
              <h5 className="text-base font-proxima-bold text-black dark:text-white">
                Disconnect account
              </h5>
            </div>
            <div className="p-5">
              <p className="text-base font-proxima-semibold text-black dark:text-white">
                Are you sure you want to disconnect your account?
              </p>
            </div>
            <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
              <Button
                className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
                onClick={handleOpen1}
              >
                Cancel
              </Button>
              <Button
                onClick={() => deleteExchangeData(exchangeId)}
                className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
              >
                Confirm
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* DISCONNECT ACCOUNT */}
        <Dialog
          open={open2}
          handler={handleOpen2}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="dark:bg-darkSidebar"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
              <h5 className="text-base font-proxima-bold text-black dark:text-white">
                Cancel Subscription
              </h5>
            </div>
            <div className="p-5">
              <p className="text-base font-proxima-semibold text-black dark:text-white">
                Are you sure you want to unsubscribe your plan?
              </p>
            </div>
            <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
              <Button
                className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
                onClick={handleOpen2}
              >
                Cancel
              </Button>
              <Button
                disabled={planLoader}
                onClick={() => unsubscriptionMyPlan()}
                className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
              >
                {planLoader ? "Loading..." : "Confirm"}
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* DISCONNECT ACCOUNT */}
        <Dialog
          open={open3}
          handler={handleOpen3}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="dark:bg-darkSidebar"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
              <h5 className="text-base font-proxima-bold text-black dark:text-white">
                Enter your mobile number
              </h5>
            </div>
            <div className="p-5">
              <div className="form-group">
                <label className="text-sm font-proxima-regular text-greyText leading-tight">
                  Enter your phone number to proceed further with the payment
                </label>
                <div className="mt-2">
                  <Input
                    type="number"
                    name="mobile"
                    id="mobile"
                    placeholder="Type your mobile number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {promocodePopup && (
              <div className="p-5">
                <p className="text-sm font-proxima-regular text-greyText">
                  Please enter your promo code below to apply it to your pricing
                  plan and enjoy exclusive discounts.
                </p>
                <div className="mt-3">
                  <div className="flex items-center gap-3">
                    <input
                      type="text"
                      name="promocode"
                      id="promocode"
                      value={promocode}
                      onChange={(e) => setPromocode(e.target.value)}
                      placeholder="Enter the promocode"
                      className="px-5 py-2 block w-full outline-none focus:outline-none bg-white border border-borderGrey rounded-md text-base placeholder:text-sm font-proxima-semibold placeholder:font-proxima-regular"
                    />

                    {/* <Button className="shadow-none hover:shadow-none bg-black font-proxima-semibold text-white text-base normal-case px-5 py-2">
                    Apply
                  </Button> */}
                  </div>
                </div>
              </div>
            )}
            <div className="p-5">
              <p className="mt-2 text-sm font-proxima-regular text-black dark:text-white text-center">
                Have a promocode?{" "}
                <Button
                  onClick={() => setPromocodePopup(true)}
                  className="p-0 bg-transparent text-logoOcre shadow-none hover:shadow-none normal-case"
                >
                  Apply promocode
                </Button>
              </p>
            </div>

            <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
              <Button
                className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
                onClick={() => {
                  handleOpen3();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={planLoader}
                onClick={() => subscriptionMyPlan()}
                className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
              >
                {planLoader ? "Loading..." : "Confirm"}
              </Button>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ProfileSettings;
