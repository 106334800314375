/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import store from "../redux/store";
import { useEffect, useState } from "react";

let subscription = store.getState().user.user?.subscription;

let exchanges = store
  .getState()
  .user?.exchanges?.filter((obj) => obj?.accountName !== "csv upload");
// setUpMistake
export const checkAccountLimit = () => {
  let accountLimit = subscription?.accountConnect;
  let myExchanges = exchanges?.length;

  return accountLimit > myExchanges;
};
export const checkSetupLimit = (setupLength) => {
  let setUpMistakeLimit = subscription?.setUpMistake;
  return setUpMistakeLimit > setupLength;
};
export const checkInsightsLimit = () => {
  return (
    subscription?.subscriptionPlane?.name === "Personal Plan" ||
    subscription?.subscriptionPlane?.name === "Professional Plan"
  );
};
const useCheckLimit = (key, length) => {
  const { user, exchanges } = useSelector((state) => state?.user);
  const [subscription, setSubscriptions] = useState(user?.subscription);
  const [check, setCheck] = useState(false);
  useEffect(() => {
    if (user?.subscription) {
      setSubscriptions(user?.subscription);
    } else {
      setSubscriptions(null);
    }
  }, [user]);
  useEffect(() => {
    if (subscription) {
      if (key === "insights") {
        setCheck(
          subscription?.subscriptionPlane?.name === "Personal Plan" ||
            subscription?.subscriptionPlane?.name === "Professional Plan"
        );
      } else if (key === "setup") {
        let setUpMistakeLimit = subscription?.setUpMistake;
        if (setUpMistakeLimit === "Infinity"){
          setCheck(true)
        }else{
          setCheck(setUpMistakeLimit * 2 > length);
        }
      } else if (key === "exchange") {
        let accountLimit = subscription?.accountConnect;
        let myExchanges = exchanges?.filter(
          (obj) => obj?.accountName !== "csv upload"
        )?.length;
        setCheck(accountLimit > myExchanges);
      }
    } else {
      setCheck(false);
    }
  }, [subscription, length]);
  return [check];
};
export default useCheckLimit;
