/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Tooltip } from "react-tooltip";

/* MATERIIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import Input from "../../../../components/design/input";

/* IMAGES */
import Upstox from "../../../../assets/images/brokers/upstox-full.webp";
import Dhan from "../../../../assets/images/brokers/dhan.png";
import Fyers from "../../../../assets/images/brokers/fyers.jpg";
import Zerodha from "../../../../assets/images/brokers/zerodha.png";
import AngelOne from "../../../../assets/images/brokers/angel-one.png";
import MotilalOswal from "../../../../assets/images/brokers/motilal-oswal.png";
import {
  addAOExchange,
  addDhanExchange,
  connectAngelOne,
  connectDhan,
  connectFyers,
  connectKite,
  connectMotilal,
  connectUpstox,
  deleteExchane,
  getConnectAOLogin,
  getConnectDhanLogin,
  getConnectFyers,
  getConnectFyersLogin,
  getConnectKite,
  getConnectKiteLogin,
  getConnectMotilal,
  getConnectUpstox,
  getConnectUpstoxLogin,
  getConnectconnectAngelOne,
  getUserExchange,
  updateTokenDhan,
} from "../../../../service/api";
import { SERVER_URL } from "../../../../config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/user/actions";
import { BiCopy } from "react-icons/bi";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import useCheckLimit, { checkAccountLimit } from "../../../../service/utils";

const NewBrokerSync = () => {
  const { exchanges, user } = useSelector((state) => state.user);
  const [upstoxDialog, setUpstoxDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showLoading, setShowLoading] = useState(false);
  var randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  const [upstoxState, setUpstoxState] = useState({
    apiKey: "",
    secretKey: "",
    redirectUrl:
      SERVER_URL + "/exchange/upstox/verify/" + user?._id + "/" + randomNumber,
  });
  const [fyersDialog, setFyersDialog] = useState(false);
  const [fyersState, setFyersState] = useState({
    apiKey: "",
    secretKey: "",
    redirectUrl:
      SERVER_URL + "/exchange/fyers/verify/" + user?._id + "/" + randomNumber,
  });
  const [dhanDialog, setDhanDialog] = useState(false);
  const [updateToken, setUpdateToken] = useState(false);
  const [dhanState, setDhanState] = useState({
    accessToken: "",
    accountId: "",
    accountName: "",
  });
  const [kiteDialog, setKiteDialog] = useState(false);

  const [kiteState, setKiteState] = useState({
    apiKey: "",
    secretKey: "",
    redirectUrl:
      SERVER_URL + "/exchange/kite/verify/" + user?._id + "/" + randomNumber,
  });
  const [AODialog, setAODialog] = useState(false);

  const [aoState, setAOState] = useState({
    apiKey: "",
    clientId: "",
    password: "",
    totpKey: "",
  });

  const [mlDialog, setMLDialog] = useState(false);

  const [mlState, setMLState] = useState({
    apiKey: "",
    secretKey: "",
    redirectUrl:
      SERVER_URL + "/exchange/motilal/verify/" + user?._id + "/" + randomNumber,
  });
  const dispatch = useDispatch();
  const getUserExchanges = () => {
    // setShowLoading(true)

    getUserExchange().then((res) => {
      // setShowLoading(false)

      if (res?.data) {
        dispatch(actions.setConnectedExchange(res?.data));
      }
    });
  };

  /* UPSTOX CONNECTION */
  const submitConnectUpstox = () => {
    if (upstoxState.apiKey === "") {
      toast.error("Please enter Api Key.");
      return;
    } else if (upstoxState.secretKey === "") {
      toast.error("Please enter Secret Key.");
      return;
    }
    setLoading(true);
    upstoxState.randomNumber =
      upstoxState?.redirectUrl.split("/")[
        upstoxState?.redirectUrl.split("/")?.length - 1
      ];
    connectUpstox(upstoxState).then((res) => {
      setLoading(false);
      if (res?.data) {
        syncUpstox(res?.data?._id);
        randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        // window.location.href = res?.data;
      }
    });
  };

  const submitConnectKite = () => {
    if (kiteState.apiKey === "") {
      toast.error("Please enter Api Key.");
      return;
    } else if (kiteState.secretKey === "") {
      toast.error("Please enter Secret Key.");
      return;
    }
    kiteState.randomNumber =
      kiteState?.redirectUrl.split("/")[
        kiteState?.redirectUrl.split("/")?.length - 1
      ];

    connectKite(kiteState).then((res) => {
      if (res?.data) {
        syncKite(res?.data?._id);
        randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        // window.location.href = res?.data;
      }
    });
  };
  const submitConnectMotilal = () => {
    if (mlState.apiKey === "") {
      toast.error("Please enter Api Key.");
      return;
    }
    // else if (mlState.secretKey === "") {
    //   toast.error("Please enter Secret Key.");
    //   return;
    // }
    mlState.randomNumber =
      mlState?.redirectUrl.split("/")[
        mlState?.redirectUrl.split("/")?.length - 1
      ];

    connectMotilal(mlState).then((res) => {
      if (res?.data) {
        syncML(res?.data?._id);
        randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        // window.location.href = res?.data;
      }
    });
  };
  /* FYERS CONNECTIONN */
  const submitConnectFyers = () => {
    if (fyersState.apiKey === "") {
      toast.error("Please enter Api Key.");
      return;
    } else if (fyersState.secretKey === "") {
      toast.error("Please enter Secret Key.");
      return;
    }
    fyersState.randomNumber =
      fyersState?.redirectUrl.split("/")[
        fyersState?.redirectUrl.split("/")?.length - 1
      ];

    connectFyers(fyersState).then((res) => {
      if (res?.data) {
        syncFyers(res?.data?._id);
        randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        // window.location.href = res?.data;
      }
    });
  };

  /* DHAN CONNECTION */
  const submitConnectDhan = (accountId, accountName, accessToken) => {
    if (dhanState.accountId === "" && !accountId) {
      toast.error("Please enter Account Id.");
      return;
    }
    if (dhanState.accountName === "" && !accountName) {
      toast.error("Please enter Account Name.");
      return;
    }
    if (dhanState.accessToken === "" && !accessToken) {
      toast.error("Please enter Access Token.");
      return;
    }
    setLoading(true);

    connectDhan(
      dhanState?.accountId ? dhanState : { accountId, accountName, accessToken }
    ).then((res) => {
      if (res?.data) {
        setLoading(false);
        // randomNumber = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000)
        toast.success(accountId ? "Broker synced successfully" : res?.message);
        getUserExchanges();
        setDhanDialog(false);
      }
    });
  };
  const submitConnectAO = (accountId, accountName, accessToken) => {
    if (aoState.clientId === "") {
      toast.error("Please enter Client Id.");
      return;
    }
    if (aoState.password === "") {
      toast.error("Please enter Pin.");
      return;
    }
    if (aoState.apiKey === "") {
      toast.error("Please enter API Key.");
      return;
    }
    if (aoState.totpKey === "") {
      toast.error("Please enter TOTP.");
      return;
    }
    setLoading(true);

    connectAngelOne(aoState)
      .then((res) => {
        if (res?.data) {
          setLoading(false);
          // randomNumber = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000)
          toast.success("Broker connected successfully");
          getUserExchanges();
          setAODialog(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const syncConnectDhan = (accountId, accountName, accessToken, id) => {
    if (dhanState.accountId === "" && !accountId) {
      toast.error("Please enter Account Id.");
      return;
    }
    if (dhanState.accountName === "" && !accountName) {
      toast.error("Please enter Account Name.");
      return;
    }
    setLoading(true);
    if (dhanState?._id) delete dhanState?._id;
    updateTokenDhan(id, { accessToken: dhanState?.accessToken })
      .then((res) => {
        if (res?.data) {
          setLoading(false);
          // randomNumber = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000)
          toast.success(
            accountName ? "Broker synced successfully" : res?.message
          );
          getUserExchanges();
          setDhanDialog(false);
          setUpdateToken(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserExchanges()
  }, []);

  /* UPSTOX SYNC */
  const syncUpstox = (id) => {
    getConnectUpstoxLogin().then((res) => {
      if (res?.data) {
        window.location.href = res?.data;
      }
    });
  };

  /* FYERS SYNC */
  const syncFyers = (id) => {
    getConnectFyers(id).then((res) => {
      if (res?.data) {
        window.location.href = res?.data;
      }
    });
  };

  const syncKite = (id) => {
    getConnectKite(id).then((res) => {
      if (res?.data) {
        window.location.href = res?.data;
      }
    });
  };
  const syncML = (id) => {
    getConnectMotilal(id).then((res) => {
      if (res?.data) {
        window.location.href = res?.data;
      }
    });
  };
  const syncAO = (id) => {
    getConnectconnectAngelOne(id).then((res) => {
      if (res?.status) {
        // toast.success("Broker synced successfully.");

        window.location.href = res?.data;
      }
    });
  };
  /* DELETE EXCHANGE */
  const deleteExchangeData = (id) => {
    deleteExchane(id).then((res) => {
      if (res?.message) {
        toast.success(res?.message);
        getUserExchanges();
      }
    });
  };

  const [toggleConnectedAccounts, setToggleConnectedAccounts] = useState(true);
  const [check] = useCheckLimit("exchange");

  const handleConnectFyres = () => {
    if (check) {
      getConnectFyersLogin().then((res) => {
        if (res?.data) {
          window.location.href = res?.data;
        }
      });
    } else {
      if (!user?.subscription) {
        toast.error("Please subscribe the plan.");
      } else {
        toast.error("Account limit is reached. Please upgrade your plan.");
      }
    }
  };
  const handleConnectKite = () => {
    if (check) {
      getConnectKiteLogin().then((res) => {
        if (res?.data) {
          window.location.href = res?.data;
        }
      });
    } else {
      if (!user?.subscription) {
        toast.error("Please subscribe the plan.");
      } else {
        toast.error("Account limit is reached. Please upgrade your plan.");
      }
    }
  };
  const handleConnectUpstox = () => {
    if (check) {
      getConnectUpstoxLogin().then((res) => {
        if (res?.data) {
          window.location.href = res?.data;
        }
      });
    } else {
      if (!user?.subscription) {
        toast.error("Please subscribe the plan.");
      } else {
        toast.error("Account limit is reached. Please upgrade your plan.");
      }
    }
  };
  const handleConnectAO = () => {
    if (check) {
      getConnectAOLogin().then((res) => {
        if (res?.data) {
          window.location.href = res?.data;
        }
      });
    } else {
      if (!user?.subscription) {
        toast.error("Please subscribe the plan.");
      } else {
        toast.error("Account limit is reached. Please upgrade your plan.");
      }
    }
  };
  const handleConnectDhan = () => {
    if (check) {
      getConnectDhanLogin().then((res) => {
        if (res?.data) {
          window.location.href = res?.data;
        }
      });
    } else {
      if (!user?.subscription) {
        toast.error("Please subscribe the plan.");
      } else {
        toast.error("Account limit is reached. Please upgrade your plan.");
      }
    }
  };
  useEffect(() => {
    if (searchParams.get("exchange") === "dhan") {
      setShowLoading(true);
      addDhanExchange({
        tokenId: searchParams.get("tokenId"),
        userId: user?._id,
      }).then((res) => {
        setShowLoading(false);
        if (res?.status) {
          getUserExchanges();
        }
      });
    }
  }, [searchParams]);
  useEffect(() => {
    if (searchParams.get("exchange") === "angel") {
      setShowLoading(true);
      addAOExchange({
        auth_token: searchParams.get("auth_token"),
        feed_token: searchParams.get("feed_token"),
        userId: user?._id,
      }).then((res) => {
        setShowLoading(false);

        if (res?.status) {
          getUserExchanges();
        }
      });
    }
  }, [searchParams]);
  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Broker Sync | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {!toggleConnectedAccounts ? (
          <div className="w-full xl:w-[90%] lg:w-[90%] mx-auto">
            <div className="block xl:flex lg:flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-proxima-bold text-black dark:text-white leading-tight">
                  Brokers you can connect with Trade Talez
                </h1>
                <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText">
                  Supported brokers which can be connected and synced with Trade
                  Talez
                </p>
              </div>
              <Button
                className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 shadow-none hover:shadow-none text-sm font-proxima-semibold text-white bg-black dark:bg-white dark:bg-opacity-10 rounded-md px-5 py-3 normal-case hover:bg-opacity-80 dark:hover:bg-opacity-100 dark:hover:text-black transition-all duration-300 ease-in-out"
                onClick={() =>
                  setToggleConnectedAccounts(!toggleConnectedAccounts)
                }
              >
                Go back
              </Button>
            </div>

            <div className="mt-5 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              {/* UPSTOX DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <div className="hidden dark:block">
                        <div className="bg-white rounded-md px-2 py-1">
                          <img
                            src={Upstox}
                            className="h-10 w-auto"
                            alt="Upstox"
                          />
                        </div>
                      </div>
                      <div className="block dark:hidden">
                        <img
                          src={Upstox}
                          className="h-10 w-auto"
                          alt="Upstox"
                        />
                      </div>
                      <Button
                        // onClick={() => setUpstoxDialog(!upstoxDialog)}
                        onClick={handleConnectUpstox}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Upstox
                        account to effortlessly import and monitor your trades.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* DHAN DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <img src={Dhan} className="h-10 w-auto" alt="DHAN" />
                        <h5 className="text-xl font-proxima-semibold text-black dark:text-white tracking-tight">
                          Dhan
                        </h5>
                      </div>
                      <Button
                        // onClick={() => {
                        //   setDhanDialog(!dhanDialog);
                        //   setDhanState({
                        //     accessToken: "",
                        //     accountId: "",
                        //     accountName: "",
                        //   });
                        //   setUpdateToken(false);
                        // }}
                        onClick={handleConnectDhan}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Dhan
                        account to effortlessly import and monitor your trades.
                      </p>
                      <div className="mt-3"></div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* FYERS DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <img src={Fyers} className="h-10 w-auto" alt="Fyers" />
                        <h5 className="text-xl font-proxima-semibold text-black dark:text-white tracking-tight">
                          Fyers
                        </h5>
                      </div>
                      <Button
                        // onClick={() => setFyersDialog(!fyersDialog)}
                        onClick={() => handleConnectFyres()}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Fyers
                        account to effortlessly import and monitor your trades.
                      </p>
                      <div className="mt-3"></div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* KITE DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <img
                          src={Zerodha}
                          className="h-10 w-auto"
                          alt="Zerodha"
                        />
                        <h5 className="text-xl font-proxima-semibold text-black dark:text-white tracking-tight">
                          Zerodha
                        </h5>
                      </div>
                      <Button
                        onClick={handleConnectKite}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Kite
                        account to effortlessly import and monitor your trades.
                      </p>
                      <div className="mt-3"></div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* ANGLE ONE DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <img
                        src={AngelOne}
                        className="h-10 w-auto"
                        alt="AngelOne"
                      />
                      <Button
                        // onClick={() => setAODialog(!AODialog)}
                        onClick={handleConnectAO}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Angel One
                        account to effortlessly import and monitor your trades.
                      </p>
                      <div className="mt-3"></div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* MOTILAL DIALOG */}
              <div className="col-span-1">
                <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                  <CardBody className="p-0">
                    <div className="p-5 pb-0 flex items-center justify-between">
                      <img
                        src={MotilalOswal}
                        className="h-10 w-auto"
                        alt="Motilal Oswal"
                      />
                      <Button
                        onClick={() => {
                          if (check) {
                            setMLDialog(!mlDialog);
                          } else {
                            if (!user?.subscription) {
                              toast.error("Please subscribe the plan.");
                            } else {
                              toast.error(
                                "Account limit is reached. Please upgrade your plan."
                              );
                            }
                          }
                        }}
                        className="w-auto mt-0 text-sm font-proxima-semibold bg-buttonColor normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-white hover:bg-opacity-80 cursor-pointer rounded-md"
                      >
                        Connect
                      </Button>
                    </div>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                        Maximize your trading efficiency. Connect your Motilal
                        account to effortlessly import and monitor your trades.
                      </p>
                      <div className="mt-3"></div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full xl:w-[90%] lg:w-[90%] mx-auto">
            <div className="block xl:flex lg:flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-proxima-bold text-black dark:text-white">
                  Connected brokers
                </h1>
                <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText">
                  Brokers you have connected with Trade Talez
                </p>
              </div>
              <Button
                className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 shadow-none hover:shadow-none text-sm font-proxima-semibold text-white bg-black dark:bg-white dark:bg-opacity-10 rounded-md px-5 py-3 normal-case hover:bg-opacity-80 dark:hover:bg-opacity-100 dark:hover:text-black transition-all duration-300 ease-in-out"
                onClick={() =>
                  setToggleConnectedAccounts(!toggleConnectedAccounts)
                }
              >
                Add new broker
              </Button>
            </div>

            {(exchanges.length === 0 ||
              exchanges?.every((obj) => obj?.accountName === "csv upload")) &&
              !showLoading && (
                <Card className="bg-white dark:bg-darkSidebar border border-borderGrey dark:border-darkBorder rounded-md shadow-none mt-5">
                  <CardBody className="p-10">
                    <div className="w-2/5 mx-auto">
                      <h5 className="text-2xl font-proxima-bold text-black dark:text-white text-center">
                        No brokers connected
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText text-center mt-2">
                        You haven't connected any brokers with TradeTalez. Add
                        broker to journal your trades and optimize your
                        strategies.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              )}

            <div className="mt-5 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              {showLoading ? (
                <>
                  <div className="col-span-1">
                    <div className="block dark:hidden">
                      <Skeleton
                        baseColor="#E5E5E5"
                        highlightColor="#F5F5F5"
                        className="w-full h-[15vh]"
                      />
                    </div>
                    <div className="hidden dark:block">
                      <Skeleton
                        baseColor="#141418"
                        highlightColor="#232327"
                        className="w-full h-[15vh]"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="block dark:hidden">
                      <Skeleton
                        baseColor="#E5E5E5"
                        highlightColor="#F5F5F5"
                        className="w-full h-[15vh]"
                      />
                    </div>
                    <div className="hidden dark:block">
                      <Skeleton
                        baseColor="#141418"
                        highlightColor="#232327"
                        className="w-full h-[15vh]"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="block dark:hidden">
                      <Skeleton
                        baseColor="#E5E5E5"
                        highlightColor="#F5F5F5"
                        className="w-full h-[15vh]"
                      />
                    </div>
                    <div className="hidden dark:block">
                      <Skeleton
                        baseColor="#141418"
                        highlightColor="#232327"
                        className="w-full h-[15vh]"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* UPSTOX DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "upstox") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "upstox" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <div className="bg-white p-2 rounded-md">
                                      <img
                                        src={Upstox}
                                        className="h-10 w-auto"
                                        alt="Upstox"
                                      />
                                    </div>
                                    <div className="flex items-center gap-2">
                                      <Button
                                        onClick={() => syncUpstox(obj?._id)}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Sync broker
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:bg-opacity-100 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                                      Maximize your trading efficiency. Connect
                                      your Upstox account to effortlessly import
                                      and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}

                  {/* DHAN DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "dhan") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "dhan" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <div className="bg-white p-2 rounded-md">
                                      <div className="flex items-center gap-2">
                                        <img
                                          src={Dhan}
                                          className="h-10 w-auto"
                                          alt={obj?.exchange}
                                        />

                                        <h5 className="text-xl font-proxima-semibold text-black tracking-tight">
                                          Dhan
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      {/* <Button
                                        disabled={loading}
                                        onClick={() => {
                                          setUpdateToken(true);
                                          setDhanDialog(!dhanDialog);
                                          setDhanState({
                                            ...dhanState,
                                            accountId: obj?.accountId,
                                            accountName: obj?.accountName,
                                            _id: obj?._id,
                                          });
                                        }}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-black dark:text-white hover:text-white dark:hover:text-black hover:bg-opacity-100 dark:hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Update Token
                                      </Button> */}
                                      <Button
                                        disabled={loading}
                                        onClick={() => {
                                          setDhanState({
                                            ...dhanState,
                                            accountId: obj?.accountId,
                                            accountName: obj?.accountName,
                                            accessToken: obj?.accessToken,
                                          });
                                          handleConnectDhan()
                                          // syncConnectDhan(
                                          //   obj?.accountId,
                                          //   obj?.accountName,
                                          //   obj?.accessToken,
                                          //   obj?._id
                                          // );
                                        }}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        {loading &&
                                        obj?.accountId === dhanState?.accountId
                                          ? "Loading..."
                                          : "Sync broker"}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 hover:bg-opacity-100 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                                      Maximize your trading efficiency. Connect
                                      your Dhan account to effortlessly import
                                      and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}

                  {/* FYERS DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "fyers") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "fyers" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                      <img
                                        src={Fyers}
                                        className="h-10 w-auto"
                                        alt="Fyers"
                                      />
                                      <h5 className="text-xl font-proxima-semibold text-black dark:text-white tracking-tight">
                                        Fyers
                                      </h5>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      <Button
                                        onClick={() => syncFyers(obj?._id)}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Sync broker
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:bg-opacity-100 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug mt-3">
                                      Maximize your trading efficiency. Connect
                                      your Fyers account to effortlessly import
                                      and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>

                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}

                  {/* KITE DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "kite") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "kite" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                      <img
                                        src={Zerodha}
                                        className="h-10 w-auto"
                                        alt="Zerodha"
                                      />
                                      <h5 className="text-xl font-proxima-semibold text-black dark:text-white tracking-tight">
                                        Zerodha
                                      </h5>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      <Button
                                        onClick={() => syncKite(obj?._id)}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Sync broker
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:bg-opacity-100 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug mt-3">
                                      Maximize your trading efficiency. Connect
                                      your Kite account to effortlessly import
                                      and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}

                  {/* ANGLE ONE DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "angel") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "angel" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <img
                                      src={AngelOne}
                                      className="h-10 w-auto"
                                      alt="Angel One"
                                    />

                                    <div className="flex items-center gap-2">
                                      <Button
                                        onClick={() => syncAO(obj?._id)}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Sync broker
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:bg-opacity-100 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                                      Maximize your trading efficiency. Connect
                                      your Angel One account to effortlessly
                                      import and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                  {/* MOTILAL DIALOG */}
                  {exchanges &&
                  exchanges?.length > 0 &&
                  exchanges?.find((obj) => obj?.exchange === "motilal") ? (
                    exchanges?.map((obj) => {
                      if (
                        obj?.exchange === "motilal" &&
                        obj?.accountName !== "csv upload"
                      ) {
                        return (
                          <>
                            <div className="col-span-1">
                              <Card className="border border-[#E5E5E5] dark:border-darkBorder dark:bg-darkSidebar rounded-lg shadow-md h-full">
                                <CardBody className="p-0">
                                  <div className="p-5 pb-0 block xl:flex lg:flex items-center justify-between">
                                    <img
                                      src={MotilalOswal}
                                      className="h-10 w-auto"
                                      alt="Motilal"
                                    />
                                    <div className="flex items-center gap-2">
                                      <Button
                                        onClick={() => syncML(obj?._id)}
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-buttonColor bg-opacity-20 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-buttonColor hover:text-white hover:bg-opacity-100 cursor-pointer rounded-md"
                                      >
                                        Sync broker
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          deleteExchangeData(obj?._id)
                                        }
                                        className="w-full xl:w-auto lg:w-auto mt-5 xl:mt-0 lg:mt-0 text-sm font-proxima-semibold bg-red-500 bg-opacity-10 normal-case font-medium px-5 py-2 shadow-none hover:shadow-none text-red-500 hover:bg-opacity-100 hover:text-white cursor-pointer rounded-md"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="p-5">
                                    <p className="text-base font-proxima-regular text-black dark:text-white leading-snug">
                                      Maximize your trading efficiency. Connect
                                      your Motilal account to effortlessly
                                      import and monitor your trades.
                                    </p>
                                    <div className="mt-3">
                                      <p className="text-base font-proxima-bold text-black dark:text-white">
                                        Account:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountName
                                            ? obj?.accountName
                                            : obj?.accountName}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Account Id:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-white">
                                          {obj?.accountId
                                            ? obj?.accountId
                                            : obj?.accountId}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Status:{" "}
                                        {obj?.status === "Disconnected" ? (
                                          <span className="font-proxima-regular text-red-500">
                                            {obj?.status}
                                          </span>
                                        ) : obj?.status === "Connected" ? (
                                          <span className="font-proxima-regular text-green-500">
                                            {obj?.status}
                                          </span>
                                        ) : (
                                          obj?.error && (
                                            <span className="font-proxima-regular text-red-500">
                                              Error - {obj?.error}
                                            </span>
                                          )
                                        )}
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Last sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {moment(obj?.updatedAt).format("lll")}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Balance:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          {obj.balance
                                            ? "₹" +
                                              parseFloat(
                                                obj.balance
                                              ).toLocaleString("en-IN")
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-base font-proxima-bold text-black dark:text-white mt-2">
                                        Trades Sync:{" "}
                                        <span className="font-proxima-regular text-[#4B4B4B] dark:text-lightGreyText">
                                          Every 1 Hour
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {/* UPSTOX DIALOG */}
      <Dialog
        open={upstoxDialog}
        handler={() => setUpstoxDialog(!upstoxDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0 dark:bg-darkSidebar rounded-lg">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Connect Upstox
            </h5>
          </div>
          <div className="px-5">
            <form className="border-b border-borderGrey dark:border-darkBorder py-5">
              <div className="form-group w-full">
                <label
                  htmlFor="APIKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  API Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="APIKey"
                  id="APIKey"
                  placeholder="Your API Key"
                  value={upstoxState.apiKey}
                  onChange={(e) =>
                    setUpstoxState({ ...upstoxState, apiKey: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="SecretKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Secret Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="SecretKey"
                  id="SecretKey"
                  placeholder="Your Secret Key"
                  value={upstoxState.secretKey}
                  onChange={(e) =>
                    setUpstoxState({
                      ...upstoxState,
                      secretKey: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="RedirectURL"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Redirect URL <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Input
                    type="text"
                    name="SecretKey"
                    id="SecretKey"
                    placeholder="Your Secret Key"
                    value={upstoxState.redirectUrl}
                    readOnly={true}
                  />
                  <CopyToClipboard
                    text={upstoxState.redirectUrl}
                    onCopy={() =>
                      toast.success("Redirect URL is copied successfully.")
                    }
                  >
                    <Button className="bg-white border border-[#E5E5E5] hover:bg-black hover:bg-opacity-10 shadow-none hover:shadow-none p-2 rounded-full !absolute top-[0.5vh] right-2">
                      <BiCopy className="w-4 h-4 text-black" />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to create an API app?
              </h5>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Login on the Upstox mobile app or web platform.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Navigate to the Account Section and click on My Account.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  Click on the Profile tab.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Navigate to the Apps Section and click on My apps.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Click on Create App or +New app
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Enter the relevant information and click on continue.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 7:
                  </span>{" "}
                  Click on ‘Confirm plan' on the order summary page.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 8:
                  </span>{" "}
                  After confirming a confirmation pops up.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 9:
                  </span>{" "}
                  Click on My apps for API key and API secret code.
                </p>
              </div>
              <div className="mt-3">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-bold text-logoOcre">Note:</span>{" "}
                  Access Token will expire everyday at 5 AM, All users have to
                  reconnect once everyday post 5:30 AM to get renewed Access
                  Token.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setUpstoxDialog(!upstoxDialog)}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={submitConnectUpstox}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loading ? "Loading..." : "Connect"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* FYERS DIALOG */}
      <Dialog
        open={fyersDialog}
        handler={() => setFyersDialog(!fyersDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0 dark:bg-darkSidebar rounded-lg">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Connect Fyers
            </h5>
          </div>
          <div className="px-5">
            <form className="py-5 border-b border-borderGrey dark:border-darkBorder">
              <div className="form-group w-full">
                <label
                  htmlFor="APIKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  API Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="APIKey"
                  id="APIKey"
                  placeholder="Your API Key"
                  value={fyersState.apiKey}
                  onChange={(e) =>
                    setFyersState({ ...fyersState, apiKey: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="SecretKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Secret Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="SecretKey"
                  id="SecretKey"
                  placeholder="Your Secret Key"
                  value={fyersState.secretKey}
                  onChange={(e) =>
                    setFyersState({
                      ...fyersState,
                      secretKey: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="RedirectURL"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Redirect URL <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Input
                    type="text"
                    name="SecretKey"
                    id="SecretKey"
                    placeholder="Your Secret Key"
                    value={fyersState.redirectUrl}
                    readOnly={true}
                  />
                  <CopyToClipboard
                    text={fyersState.redirectUrl}
                    onCopy={() =>
                      toast.success("Redirect URL is copied successfully.")
                    }
                  >
                    <Button className="bg-white border border-[#E5E5E5] hover:bg-black hover:bg-opacity-10 shadow-none hover:shadow-none p-2 rounded-full !absolute top-[0.5vh] right-2">
                      <BiCopy className="w-4 h-4 text-black" />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to create an API app?
              </h5>
              <a
                href="https://myapi.fyers.in/"
                target="_blank"
                rel="noreferrer"
                className="block text-logoOcre text-base font-proxima-semibold"
              >
                https://myapi.fyers.in/
              </a>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Login on the URL provided.{" "}
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Click on “Create App”
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black">
                    Step 3:
                  </span>{" "}
                  Enter App Name, Redirect URL (As Provided Above) &
                  Description.{" "}
                  <span className="italic text-xs block text-logoOcre">
                    Note : Remove webhook configuration by clicking on three
                    dots and click “Delete”.
                  </span>
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Select All App Permissions
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Tick checkbox for “I accept the API Usage T&C”
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Click on ‘Create App’.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 7:
                  </span>{" "}
                  Copy App ID & Secret ID
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 8:
                  </span>{" "}
                  Provide App ID & Secret ID in the TradeTalez Broker Connection
                  as API Key & Secret Key.
                </p>
              </div>
              <div className="mt-3">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-bold text-logoOcre">Note:</span>{" "}
                  Access Token will expire everyday at 5 AM, All users have to
                  reconnect once everyday post 5:30 AM to get renewed Access
                  Token.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setFyersDialog(!fyersDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={submitConnectFyers}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              Connect
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* KITE DIALOG */}
      <Dialog
        open={kiteDialog}
        handler={() => setKiteDialog(!kiteDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Connect Kite
            </h5>
          </div>
          <div className="px-5">
            <form className="py-5 border-b border-borderGrey">
              <div className="form-group w-full">
                <label
                  htmlFor="APIKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  API Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="APIKey"
                  id="APIKey"
                  placeholder="Your API Key"
                  value={kiteState.apiKey}
                  onChange={(e) =>
                    setKiteState({ ...kiteState, apiKey: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="SecretKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Secret Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="SecretKey"
                  id="SecretKey"
                  placeholder="Your Secret Key"
                  value={kiteState.secretKey}
                  onChange={(e) =>
                    setKiteState({
                      ...kiteState,
                      secretKey: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="RedirectURL"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Redirect URL <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Input
                    type="text"
                    name="SecretKey"
                    id="SecretKey"
                    placeholder="Your Secret Key"
                    value={kiteState.redirectUrl}
                    readOnly={true}
                  />
                  <CopyToClipboard
                    text={kiteState.redirectUrl}
                    onCopy={() =>
                      toast.success("Redirect URL is copied successfully.")
                    }
                  >
                    <Button className="bg-white border border-[#E5E5E5] hover:bg-opacity-10 shadow-none hover:shadow-none p-2 rounded-full !absolute top-[0.5vh] right-2">
                      <BiCopy className="w-4 h-4 text-black" />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to create an API app?
              </h5>
              <a
                href="https://kite.trade/"
                target="_blank"
                rel="noreferrer"
                className="block text-logoOcre text-base font-proxima-semibold"
              >
                https://kite.trade/
              </a>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Register/Login on the URL provided.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Purchase the Subscription for API Access on kite connect
                  portal.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  Enter App Name, Redirect URL (As Provided Above) &
                  Description.{" "}
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Select All App Permissions
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Copy API Key & Secret Key
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Provide App ID & Secret ID in the TradeTalez Broker Connection
                  as API Key & Secret Key.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 7:
                  </span>{" "}
                  Hit “Connect” and you good to go
                </p>
              </div>
              <div className="mt-3">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-bold text-logoOcre">Note:</span>{" "}
                  Access Token will expire everyday at 5 AM, All users have to
                  reconnect once everyday post 5:30 AM to get renewed Access
                  Token.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setKiteDialog(!kiteDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={submitConnectKite}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              Connect
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* DHAN DIALOG */}
      <Dialog
        open={dhanDialog}
        handler={() => setDhanDialog(!dhanDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0 dark:bg-darkSidebar rounded-lg">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              {updateToken ? "Update Token" : "Connect Dhan"}
            </h5>
          </div>
          <div className="px-5">
            <form className="py-5 border-b border-borderGrey dark:border-darkBorder">
              <div className="form-group w-full">
                <label
                  htmlFor="AccountID"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Account ID <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="AccountID"
                  id="AccountID"
                  placeholder="Your Account ID"
                  value={dhanState.accountId}
                  onChange={(e) =>
                    setDhanState({ ...dhanState, accountId: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="AccountName"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Account Name <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="AccountName"
                  id="AccountName"
                  placeholder="Your Account Name"
                  value={dhanState.accountName}
                  onChange={(e) =>
                    setDhanState({
                      ...dhanState,
                      accountName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="AccessToken"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Access Token <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="AccessToken"
                  id="AccessToken"
                  placeholder="Your Access Token"
                  value={dhanState.accessToken}
                  onChange={(e) =>
                    setDhanState({
                      ...dhanState,
                      accessToken: e.target.value,
                    })
                  }
                />
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to generate access token?
              </h5>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Login to your Dhan account on Dhan Web.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Go to your profile section and click on DhanHQ Tradiing APIs.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  In that section, you will find option to generate access
                  token.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  In case you are using APIs for the first time, you will have
                  to click "Request Access".
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Refresh the page and you will get access within seconds.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Provide the Account ID, Account Name and Access token in
                  TradeTalez Broker Connection.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 7:
                  </span>{" "}
                  Hit “Connect” and you good to go
                </p>
              </div>
              <div className="mt-3">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-bold text-logoOcre">Note:</span>{" "}
                  Access Token will expire everyday at 5 AM, All users have to
                  reconnect once everyday post 5:30 AM to get renewed Access
                  Token.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setDhanDialog(!dhanDialog)}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                if (updateToken) {
                  syncConnectDhan("", "", "", dhanState?._id);
                } else {
                  submitConnectDhan();
                }
              }}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loading ? "Loading..." : updateToken ? "Update" : "Connect"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* ANGEL ONE DIALOG */}
      <Dialog
        open={AODialog}
        handler={() => setAODialog(!AODialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Connect Angel One
            </h5>
          </div>
          <div className="px-5 h-[40vh] xl:h-[80vh] lg:h-[80vh] overflow-auto overflow-y-auto overflow-x-hidden hide-scrollbar">
            <form className="py-5 border-b border-borderGrey">
              <div className="form-group w-full">
                <label
                  htmlFor="clientId"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Client ID <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="clientId"
                  id="clientId"
                  placeholder="Your CLient ID"
                  value={aoState.clientId}
                  onChange={(e) =>
                    setAOState({ ...aoState, clientId: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="apikey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Pin <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="pin"
                  id="pin"
                  placeholder="Your Password"
                  value={aoState.password}
                  onChange={(e) =>
                    setAOState({
                      ...aoState,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="apikey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  API Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="apiKey"
                  id="apiKey"
                  placeholder="Your API Key"
                  value={aoState.apiKey}
                  onChange={(e) =>
                    setAOState({
                      ...aoState,
                      apiKey: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="apikey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  TOTP <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="totpKey"
                  id="totpKey"
                  placeholder="Your TOTP"
                  value={aoState.totpKey}
                  onChange={(e) =>
                    setAOState({
                      ...aoState,
                      totpKey: e.target.value,
                    })
                  }
                />
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to generate an API Key?
              </h5>
              <a
                href="https://smartapi.angelbroking.com/"
                target="_blank"
                rel="noreferrer"
                className="block text-logoOcre text-base font-proxima-semibold"
              >
                https://smartapi.angelbroking.com/
              </a>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Go the URL provided above. Click on the 'Signup' button to
                  create your account.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Enter the necessary details. Entering the Angel Client ID is
                  not necessary for this step.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  Once you have registered successfully, you will get an option
                  to 'Create an App'.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Select 'Trading APIs' option and fill in the 'App Name',
                  'Redirect URL', and optionally 'Postback URL' and 'Angel
                  Client ID'.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  After clicking 'Create App', you should receive your API Key
                  and Secret Key. Please do not share these with anyone.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Provide App ID & Secret ID in the TradeTalez Broker Connection
                  as API Key & Secret Key.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 8:
                  </span>{" "}
                  Hit “Connect” and you good to go
                </p>
              </div>
            </div>

            <div className="pb-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to generate a TOTP?
              </h5>
              <a
                href="https://smartapi.angelbroking.com/"
                target="_blank"
                rel="noreferrer"
                className="block text-logoOcre text-base font-proxima-semibold"
              >
                https://smartapi.angelbroking.com/
              </a>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Visit the above URL and enable TOTP.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Enter your AngelOne Client ID and trading terminal password or
                  PIN.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  Enter the OTP sent to your registered email and mobile.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Once the OTP is entered, you will se a QR code on the screen.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Open the authenticator app of your choice.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Copy the TOTP content and paste it in broker connection modal
                  in TradeTalez Portal.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setAODialog(!AODialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={submitConnectAO}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              Connect
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* MOTILAL DIALOG */}
      <Dialog
        open={mlDialog}
        handler={() => setMLDialog(!mlDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Connect Motilal
            </h5>
          </div>
          <div className="px-5">
            <form className="py-5 border-b border-borderGrey">
              <div className="form-group w-full">
                <label
                  htmlFor="APIKey"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  API Key <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="APIKey"
                  id="APIKey"
                  placeholder="Your API Key"
                  value={mlState.apiKey}
                  onChange={(e) =>
                    setMLState({ ...mlState, apiKey: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full mt-5">
                <label
                  htmlFor="RedirectURL"
                  className="text-sm font-proxima-semibold text-black dark:text-white"
                >
                  Redirect URL <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Input
                    type="text"
                    name="SecretKey"
                    id="SecretKey"
                    placeholder="Your Secret Key"
                    value={mlState.redirectUrl}
                    readOnly={true}
                  />
                  <CopyToClipboard
                    text={mlState.redirectUrl}
                    onCopy={() =>
                      toast.success("Redirect URL is copied successfully.")
                    }
                  >
                    <Button className="bg-white border border-[#E5E5E5] hover:bg-opacity-10 shadow-none hover:shadow-none p-2 rounded-full !absolute top-[0.5vh] right-2">
                      <BiCopy className="w-4 h-4 text-black" />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </form>
            <div className="py-5">
              <h5 className="text-lg font-proxima-bold text-black dark:text-white tracking-tight">
                How to create an API app?
              </h5>
              <a
                href="https://www.motilaloswal.com/"
                target="_blank"
                rel="noreferrer"
                className="block text-logoOcre text-base font-proxima-semibold"
              >
                https://www.motilaloswal.com/
              </a>
              <div className="mt-2">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 1:
                  </span>{" "}
                  Register/Login on the URL provided.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 2:
                  </span>{" "}
                  Purchase the Subscription for API Access on motilal connect
                  portal.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 3:
                  </span>{" "}
                  Enter App Name, Redirect URL (As Provided Above) &
                  Description.{" "}
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 4:
                  </span>{" "}
                  Select All App Permissions
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 5:
                  </span>{" "}
                  Copy API Key & Secret Key
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 6:
                  </span>{" "}
                  Provide App ID & Secret ID in the TradeTalez Broker Connection
                  as API Key & Secret Key.
                </p>
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText mt-1">
                  <span className="font-proxima-semibold text-black dark:text-white">
                    Step 7:
                  </span>{" "}
                  Hit “Connect” and you good to go
                </p>
              </div>
              <div className="mt-3">
                <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                  <span className="font-proxima-bold text-logoOcre">Note:</span>{" "}
                  Access Token will expire everyday at 5 AM, All users have to
                  reconnect once everyday post 5:30 AM to get renewed Access
                  Token.
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setMLDialog(!mlDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={submitConnectMotilal}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              Connect
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default NewBrokerSync;
