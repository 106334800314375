export const SERVER_URL = 
"https://tradechannel-backend-3oa2v6e2la-uc.a.run.app/user";
// "https://qnk3c0s0-8080.inc1.devtunnels.ms/user"
// const PLAN_ID = ["6659cb9d46229b53415f9f1f", "6659cc5da7eb921bd4f508fc","6659ccd4a7eb921bd4f508fe"] // monthly
// ["6659cbe1a7eb921bd4f508fb", "6659cc8da7eb921bd4f508fd", "6659cd04a7eb921bd4f508ff"] //yearly
// eslint-disable-next-line import/no-anonymous-default-export
// export default {
//   SERVER_URL,
//   PLAN_ID
// };
