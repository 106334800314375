import Zerodha from "../assets/images/brokers/zerodha.png";
import Upstox from "../assets/images/brokers/upstox.png";
import Dhan from "../assets/images/brokers/dhan.png";
import Fyers from "../assets/images/brokers/fyers.jpg";

const brokers = [
  {
    id: 1,
    logo: Zerodha,
    name: "Zerodha",
  },
  {
    id: 2,
    logo: Upstox,
    name: "Upstox",
  },
  {
    id: 3,
    logo: Dhan,
    name: "Dhan",
  },
  {
    id: 4,
    logo: Fyers,
    name: "Fyers",
  },
];

export default brokers;
