import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

/* SAMPLE DATA */
import moment from "moment";

const CumulativeGraph = (props) => {
  const categories = props?.profitLoss?.map((item) =>
    moment(item.date).format("DD MMM YY")
  );

  const [state] = useState({
    series: [
      {
        name: "Cumulative P&L",
        data: props?.profitLoss?.map((res) =>
          res?.cumulativeProfit?.toFixed(2)
        ),
      },
    ],
    options: {
      xaxis: {
        categories: categories,
        type: "datetime",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      chart: {
        id: "area-datetime",
        type: "area",
        fontFamily: "ProximaNova-Semibold",
        redrawOnWindowResize: false,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 10000000],
        },
      },
      stroke: {
        width: 2,
      },
      colors: ["#4CAF50"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="area"
      />
    </div>
  );
};

const CumulativeGraphDark = (props) => {
  const categories = props?.profitLoss?.map((item) =>
    moment(item.date).format("DD MMM YY")
  );
  const [state] = useState({
    series: [
      {
        name: "Cumulative P&L",
        data: props?.profitLoss?.map((res) =>
          res?.cumulativeProfit?.toFixed(2)
        ),
      },
    ],
    options: {
      xaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
        categories: categories,
        type: "datetime",
      },
      chart: {
        id: "area-datetime",
        type: "area",
        fontFamily: "ProximaNova-Semibold",
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
          formatter: function (value) {
            return parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
              "en-IN"
            );
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 2,
      },
      colors: ["#4CAF50"],
    },
  });

  return (
    <div className={`area-chart ${props.height}`}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        height="100%"
        type="area"
      />
    </div>
  );
};

export { CumulativeGraph, CumulativeGraphDark };
