import { useState } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";

/* ICONS */
import { FaPlus, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";

/* DATA */
import navigation from "../../data/navigation";

/* ASSETS */
import LogoDark from "../../assets/images/logo-dark.png";
import useCheckLimit from "../../service/utils";
import { BiLockAlt } from "react-icons/bi";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  var pathname = window.location.pathname;
  const [check] = useCheckLimit("insights");

  return (
    <section
      className={`h-screen bg-sidebar dark:bg-darkSidebar dark:border-r dark:border-darkBorder transition-all duration-300 ease-linear ${
        sidebarOpen ? "w-sidebar" : "w-sidebarCollapsed"
      }`}
    >
      {/* HEADER */}
      <div
        className={`h-header border-b border-sidebarHeaderBorder dark:border-darkBorder px-5 flex items-center ${
          !sidebarOpen ? "justify-center" : "justify-between"
        }`}
      >
        <img
          src={LogoDark}
          className={`${!sidebarOpen && "hidden"} w-[12vh] h-auto object-cover`}
          alt="Trade Talez"
        />
        <Button
          className="shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-white hover:bg-opacity-10"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? (
            <FaRegCircle className="w-4 h-4 text-sidebarText" />
          ) : (
            <FaRegCircleDot className="w-4 h-4 text-sidebarText" />
          )}
        </Button>
      </div>

      {/* NAVIGATION BUTTONS */}
      <div className="h-[92vh] flex flex-col justify-between">
        {/* MENU */}
        <div className="px-5 py-5">
          <Menu
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}
          >
            <MenuHandler>
              <Button
                className={`w-full text-center cursor-pointer rounded-md bg-buttonColor hover:bg-opacity-80 font-proxima-semibold text-white normal-case text-base py-2 ${
                  !sidebarOpen && "px-0 py-3"
                }`}
              >
                {!sidebarOpen ? (
                  <FaPlus className="w-4 h-4 text-white mx-auto" />
                ) : (
                  "Import trades"
                )}
              </Button>
            </MenuHandler>
            <MenuList className="w-[22vh] p-2 dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder">
              <Link
                to="/import-trades/file-upload"
                className="text-sm font-proxima-semibold text-[#7B7B7B] dark:text-white"
              >
                <MenuItem className="hover:bg-buttonColor hover:bg-opacity-20 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 dark:hover:text-white">
                  File upload
                </MenuItem>
              </Link>
              <Link
                to="/import-trades/broker-sync"
                className="text-sm font-proxima-semibold text-[#7B7B7B] dark:text-white"
              >
                <MenuItem className="hover:bg-buttonColor hover:bg-opacity-20 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 dark:hover:text-white">
                  Broker sync
                </MenuItem>
              </Link>
              {/* <Link
                to="/import-trades/manual-trade"
                className="text-sm font-proxima-semibold text-[#7B7B7B] dark:text-white"
              >
                <MenuItem className="hover:bg-buttonColor hover:bg-opacity-20 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 dark:hover:text-white">
                  Manual Trade
                </MenuItem>
              </Link> */}
            </MenuList>
          </Menu>

          {navigation.map((nav) => {
            if (nav?.name === "TT Insights") {
              if (check) {
                return (
                  <Link
                    to={nav.link}
                    key={nav.id}
                    className="w-full group flex items-center gap-3 first:mt-0 mt-8"
                  >
                    <nav.icon
                      className={`${
                        !sidebarOpen && "w-6 h-6 mx-auto"
                      } w-5 h-5 text-sidebarText dark:text-lightGreyText ${
                        pathname === nav.link && "text-white dark:text-white"
                      } group-hover:text-white transition-all duration-300 ease-in-out`}
                    />
                    <div className={`${!sidebarOpen && "hidden"}`}>
                      <p
                        className={`text-base font-proxima-semibold text-sidebarText ${
                          pathname === nav.link && "text-white dark:text-white"
                        } dark:text-lightGreyText group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                      >
                        {nav.name}
                      </p>
                      <p
                        className={`text-sm font-proxima-semibold text-sidebarText ${
                          pathname === nav.link &&
                          "text-white dark:text-white text-opacity-100 dark:text-opacity-100"
                        } dark:text-lightGreyText group-hover:text-white group-hover:text-opacity-50 text-opacity-40 dark:text-opacity-40 transition-all duration-300 ease-in-out`}
                      >
                        {nav.smallText}
                      </p>
                    </div>
                  </Link>
                );
              } else {
                return (
                  <Link
                    // to={nav.link}
                    key={nav.id}
                    className="w-full group flex items-center gap-3 first:mt-0 mt-8"
                  >
                    <nav.icon
                      className={`${
                        !sidebarOpen && "w-6 h-6 mx-auto"
                      } w-5 h-5 text-sidebarText dark:text-lightGreyText ${
                        pathname === nav.link && "text-white dark:text-white"
                      } group-hover:text-white transition-all duration-300 ease-in-out`}
                    />
                    <div className={`${!sidebarOpen && "hidden"}`}>
                      <p
                        className={`text-base font-proxima-semibold text-sidebarText ${
                          pathname === nav.link && "text-white dark:text-white"
                        } dark:text-lightGreyText group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                      >
                        {nav.name}
                      </p>
                      <p
                        className={`text-sm font-proxima-semibold text-sidebarText ${
                          pathname === nav.link &&
                          "text-white dark:text-white text-opacity-100 dark:text-opacity-100"
                        } dark:text-lightGreyText group-hover:text-white group-hover:text-opacity-50 text-opacity-40 dark:text-opacity-40 transition-all duration-300 ease-in-out`}
                      >
                        {nav.smallText}
                      </p>
                    </div>
                    <BiLockAlt
                      className={`${
                        !sidebarOpen && "w-6 h-6 mx-auto"
                      } w-5 h-5 text-sidebarText dark:text-lightGreyText ${
                        pathname === nav.link && "text-white dark:text-white"
                      } group-hover:text-white transition-all duration-300 ease-in-out`}
                    />
                  </Link>
                );
              }
            } else {
              return (
                <Link
                  to={nav.link}
                  key={nav.id}
                  className="w-full group flex items-center gap-3 first:mt-0 mt-8"
                >
                  <nav.icon
                    className={`${
                      !sidebarOpen && "w-6 h-6 mx-auto"
                    } w-5 h-5 text-sidebarText dark:text-lightGreyText ${
                      pathname === nav.link && "text-white dark:text-white"
                    } group-hover:text-white transition-all duration-300 ease-in-out`}
                  />
                  <div className={`${!sidebarOpen && "hidden"}`}>
                    <p
                      className={`text-base font-proxima-semibold text-sidebarText ${
                        pathname === nav.link && "text-white dark:text-white"
                      } dark:text-lightGreyText group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                    >
                      {nav.name}
                    </p>
                    <p
                      className={`text-sm font-proxima-semibold text-sidebarText ${
                        pathname === nav.link &&
                        "text-white dark:text-white text-opacity-100 dark:text-opacity-100"
                      } dark:text-lightGreyText group-hover:text-white group-hover:text-opacity-50 text-opacity-40 dark:text-opacity-40 transition-all duration-300 ease-in-out`}
                    >
                      {nav.smallText}
                    </p>
                  </div>
                </Link>
              );
            }
          })}
        </div>

        {/* LOGOUT */}
        <div className="px-5 py-8">
          <a
            href="https://discord.gg/Z6DMHmXSmc"
            className="block"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full bg-[#7389DA] rounded-md p-2">
              <div className="flex items-center w-full">
                <img
                  src={require("../../assets/images/discord-icon.png")}
                  className={`${
                    sidebarOpen ? "w-10 h-10" : "w-8 h-8"
                  } flex-shrink-0`}
                  alt="Join out discord"
                />
                <div className="w-full p-0">
                  {sidebarOpen && (
                    <h5 className="text-[1.8vh] font-proxima-semibold text-white text-center">
                      Join our discord
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </a>

          <Popover placement="top-end">
            <PopoverHandler>
              <Button className="mt-8 p-0 normal-case shadow-none hover:shadow-none bg-transparent flex items-center gap-3 text-lightGreyText hover:text-white">
                <MdContactSupport className="w-6 h-6" />
                <p className="text-base font-proxima-semibold">
                  Support Center
                </p>
              </Button>
            </PopoverHandler>
            <PopoverContent className="w-[30vh] bg-white border border-borderGrey rounded-lg shadow-lg">
              <p className="text-base font-proxima-regular text-black">
                We're here to help you! Here are the 3 ways you can reach us:
              </p>
              <div className="mt-3">
                <a href="mailto:contact@tradetalez.com">
                  <Button className="px-5 py-2 shadow-none hover:shadow-none bg-white hover:bg-logoOcre border border-logoOcre text-black hover:text-white rounded-md text-base font-proxima-semibold normal-case transition-all duration-300 ease-in-out">
                    Reach us via email
                  </Button>
                </a>
                <a
                  href="https://tradetalez.com/resource-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="mt-3 px-5 py-2 shadow-none hover:shadow-none bg-white hover:bg-logoOcre border border-logoOcre text-black hover:text-white rounded-md text-base font-proxima-semibold normal-case transition-all duration-300 ease-in-out">
                    TradeTalez Resource Center
                  </Button>
                </a>
                <a
                  href="https://discord.gg/Z6DMHmXSmc"
                  className="block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="mt-3 px-5 py-2 shadow-none hover:shadow-none bg-white hover:bg-logoOcre border border-logoOcre text-black hover:text-white rounded-md text-base font-proxima-semibold normal-case transition-all duration-300 ease-in-out">
                    Join us on discord
                  </Button>
                </a>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
