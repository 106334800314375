/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

/* PACKAGES */
import moment from "moment";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";

/* API CALLS */
import { getInsights } from "../../../service/api";
import { useSelector } from "react-redux";

import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Insights = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [bestSetupText, setBestSetupText] = useState(false);
  const [worstSetupText, setWorstSetupText] = useState(false);
  const [mistake, setMistake] = useState(false);
  const { startDate, endDate, selectedExchange } = useSelector(
    (state) => state.user
  );
  /* GET INSIGHTS DATA */
  const getInsightsData = () => {
    setLoading(true);
    let params = { startDate: startDate, endDate: endDate };
    if (selectedExchange && selectedExchange?.length > 0) {
      params.exchangeId = selectedExchange?.map((obj) => obj?._id);
    }
    getInsights(params)
      .then((res) => {
        if (res?.status) {
          setData(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInsightsData();
  }, [startDate, endDate]);

  return (
    <section className="p-3 xl:p-10 lg:p-10">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Insights | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* ROW ONE */}
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
          <div className="col-span-2">
            <h5 className="text-base xl:text-lg lg:text-lg font-proxima-semibold text-logoOcre">
              TradeTalez Insights
            </h5>
            <div className="w-full xl:w-[85%] lg:w-[85%]">
              <h2 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-bold text-black dark:text-white leading-tight mt-2">
                Custom Insights to Refine Your Trades and Boost Performance
              </h2>
              {/* 
              <h2 className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText dark:text-lightGreyText mt-3">
                Learn from your trading habits and understand what type of
                trader are you and how to improve your mistakes and boost
                profits.
              </h2> */}
              <div className="mt-5 bg-white dark:bg-darkSidebar shadow border border-borderGrey dark:border-darkBorder rounded-md p-5 pl-14 pr-14 py-3 relative overflow-hidden">
                <h5 className="text-base font-proxima-semibold text-black dark:text-white">
                  The consistency you seek is in your mind, not in the markets.
                </h5>
                <p className="text-sm font-proxima-regular text-greyText">
                  - Mark Douglas
                </p>

                <div className="absolute -bottom-4 -right-3">
                  <FaQuoteRight className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
                <div className="absolute -top-4 -left-3">
                  <FaQuoteLeft className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
              </div>
              <div className="mt-5 bg-white dark:bg-darkSidebar shadow border border-borderGrey dark:border-darkBorder rounded-md p-5 pl-14 pr-14 py-3 relative overflow-hidden">
                <h5 className="text-base font-proxima-semibold text-black dark:text-white">
                  A trader must be disciplined, patient, and objective to
                  succeed in the markets.
                </h5>
                <p className="text-sm font-proxima-regular text-greyText">
                  - Jesse Livermore
                </p>

                <div className="absolute -bottom-4 -right-3">
                  <FaQuoteRight className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
                <div className="absolute -top-4 -left-3">
                  <FaQuoteLeft className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
              </div>
              <div className="mt-5 bg-white dark:bg-darkSidebar shadow border border-borderGrey dark:border-darkBorder rounded-md p-5 pl-14 pr-14 py-3 relative overflow-hidden">
                <h5 className="text-base font-proxima-semibold text-black dark:text-white">
                  What gets measured, gets managed.
                </h5>
                <p className="text-sm font-proxima-regular text-greyText">
                  - Peter Drucker
                </p>

                <div className="absolute -bottom-4 -right-3">
                  <FaQuoteRight className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
                <div className="absolute -top-4 -left-3">
                  <FaQuoteLeft className="w-12 h-12 text-greyText text-opacity-20" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
              {/* BEST vs WORST SETUP */}
              <div className="col-span-1">
                <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
                  <Tabs value="Best setup" className="overflow-visible">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder">
                        <TabsHeader
                          className="w-auto xl:w-[25vh] rounded-none p-0 bg-transparent"
                          indicatorProps={{
                            className:
                              "bg-transparent border-b-2 border-buttonColor shadow-none rounded-none text-buttonColor",
                          }}
                        >
                          <Tab
                            value="Best setup"
                            key="Best setup"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Best Setup
                          </Tab>
                          <Tab
                            value="Worst setup"
                            key="Worst setup"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Worst Setup
                          </Tab>
                        </TabsHeader>
                      </div>
                      <div className="pt-5 flex items-center">
                        <TabsBody className="p-0 overflow-visible">
                          <TabPanel
                            key="Best setup"
                            value="Best setup"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The setup with which you got maximum profits
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.bestSetup?.name === undefined
                                        ? "-"
                                        : data?.bestSetup?.name}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="min-h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                    {bestSetupText
                                      ? data?.bestSetup?.description
                                      : data?.bestSetup?.description?.substring(
                                          0,
                                          40
                                        )}
                                    <Button
                                      className="ml-2 p-0 bg-transparent text-xs font-proxima-regular font-normal text-logoOcre normal-case shadow-none hover:shadow-none"
                                      onClick={() =>
                                        setBestSetupText(!bestSetupText)
                                      }
                                    >
                                      {bestSetupText ? "less" : "more"}
                                    </Button>
                                  </p>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel
                            key="Worst setup"
                            value="Worst setup"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The setup with which you got maximum losses
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.worstSetup?.name === undefined
                                        ? "-"
                                        : data?.worstSetup?.name}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="min-h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                    {worstSetupText
                                      ? data?.worstSetup?.description
                                      : data?.worstSetup?.description?.substring(
                                          0,
                                          40
                                        )}
                                    <Button
                                      className="ml-2 p-0 bg-transparent text-xs font-proxima-regular font-normal text-logoOcre normal-case shadow-none hover:shadow-none"
                                      onClick={() =>
                                        setWorstSetupText(!worstSetupText)
                                      }
                                    >
                                      {worstSetupText ? "less" : "more"}
                                    </Button>
                                  </p>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </div>
                    </CardBody>
                  </Tabs>
                </Card>
              </div>

              {/* BEST VS WORST MONTH */}
              <div className="col-span-1">
                <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
                  <Tabs value="Best month" className="overflow-visible">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder">
                        <TabsHeader
                          className="w-auto xl:w-[25vh] rounded-none p-0 bg-transparent"
                          indicatorProps={{
                            className:
                              "bg-transparent border-b-2 border-buttonColor shadow-none rounded-none text-buttonColor",
                          }}
                        >
                          <Tab
                            value="Best month"
                            key="Best month"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Best Month
                          </Tab>
                          <Tab
                            value="Worst month"
                            key="Worst month"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Worst Month
                          </Tab>
                        </TabsHeader>
                      </div>
                      <div className="pt-5 flex items-center">
                        <TabsBody className="p-0 overflow-visible">
                          <TabPanel
                            key="Best month"
                            value="Best month"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The month when you got the maximum profits
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.bestMonth === undefined
                                        ? "-"
                                        : data?.bestMonth}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total profit earned in this month
                                    </p>
                                    <p
                                      className={`text-sm font-proxima-bold ${
                                        parseFloat(
                                          data?.bestMonthPnL?.toFixed(2)
                                        ) < 0
                                          ? "text-red-500"
                                          : "text-green-500"
                                      }`}
                                    >
                                      ₹{" "}
                                      {data?.bestMonthPnL
                                        ? parseFloat(
                                            data?.bestMonthPnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel
                            key="Worst month"
                            value="Worst month"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The month when you got the maximum losses
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.worstMonth === undefined
                                        ? "-"
                                        : data?.worstMonth}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total loss in this month
                                    </p>
                                    <p className="text-sm font-proxima-bold text-red-500">
                                      ₹{" "}
                                      {data?.worstMonthPnL
                                        ? parseFloat(
                                            data?.worstMonthPnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </div>
                    </CardBody>
                  </Tabs>
                </Card>
              </div>

              {/* BEST VS WORST DAY */}
              <div className="col-span-1">
                <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
                  <Tabs value="Best day" className="overflow-visible">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder">
                        <TabsHeader
                          className="w-auto xl:w-[25vh] rounded-none p-0 bg-transparent"
                          indicatorProps={{
                            className:
                              "bg-transparent border-b-2 border-buttonColor shadow-none rounded-none text-buttonColor",
                          }}
                        >
                          <Tab
                            value="Best day"
                            key="Best day"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Best Day
                          </Tab>
                          <Tab
                            value="Worst day"
                            key="Worst day"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Worst Day
                          </Tab>
                        </TabsHeader>
                      </div>
                      <div className="pt-5 flex items-center">
                        <TabsBody className="p-0 overflow-visible">
                          <TabPanel
                            key="Best day"
                            value="Best day"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The day when you got maximum profits
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    {data?.bestDate?.day === undefined ? (
                                      <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                        -
                                      </h2>
                                    ) : (
                                      <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                        {data?.bestDate?.day},{" "}
                                        {moment(
                                          data?.bestDate?.date?.split("/")[1] +
                                            "/" +
                                            data?.bestDate?.date?.split(
                                              "/"
                                            )[0] +
                                            "/" +
                                            data?.bestDate?.date?.split("/")[2]
                                        ).format("ll")}
                                      </h2>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total profit earned on that day
                                    </p>
                                    <p
                                      className={`text-sm font-proxima-bold ${
                                        parseFloat(
                                          data?.bestDatePnL?.toFixed(2)
                                        ) < 0
                                          ? "text-red-500"
                                          : "text-green-500"
                                      }`}
                                    >
                                      ₹{" "}
                                      {data?.bestDatePnL
                                        ? parseFloat(
                                            data?.bestDatePnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel
                            key="Worst day"
                            value="Worst day"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The day when you got the maximum losses
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    {data?.worstDate?.day === undefined ? (
                                      <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                        -
                                      </h2>
                                    ) : (
                                      <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                        {data?.worstDate?.day},{" "}
                                        {moment(
                                          data?.worstDate?.date?.split("/")[1] +
                                            "/" +
                                            data?.worstDate?.date?.split(
                                              "/"
                                            )[0] +
                                            "/" +
                                            data?.worstDate?.date?.split("/")[2]
                                        ).format("ll")}
                                      </h2>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total loss on that day
                                    </p>
                                    <p className="text-sm font-proxima-bold text-red-500">
                                      ₹{" "}
                                      {data?.worstDatePnL
                                        ? parseFloat(
                                            data?.worstDatePnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </div>
                    </CardBody>
                  </Tabs>
                </Card>
              </div>

              {/* BEST VS WORST RR RANGE */}
              <div className="col-span-1">
                <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
                  <Tabs value="Best range" className="overflow-visible">
                    <CardBody className="p-0">
                      <div className="border-b border-[#E5E5E5] dark:border-darkBorder">
                        <TabsHeader
                          className="w-auto xl:w-[25vh] rounded-none p-0 bg-transparent"
                          indicatorProps={{
                            className:
                              "bg-transparent border-b-2 border-buttonColor shadow-none rounded-none text-buttonColor",
                          }}
                        >
                          <Tab
                            value="Best range"
                            key="Best range"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Best RR Range
                          </Tab>
                          <Tab
                            value="Worst range"
                            key="Worst range"
                            className="text-sm font-proxima-semibold py-3 dark:text-white dark:text-opacity-60"
                            activeClassName="trades-active"
                          >
                            Worst RR Range
                          </Tab>
                        </TabsHeader>
                      </div>
                      <div className="pt-5 flex items-center">
                        <TabsBody className="p-0 overflow-visible">
                          <TabPanel
                            key="Best range"
                            value="Best range"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The best risk reward ratio range for maximum
                                profits
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.bestRR === undefined
                                        ? "-"
                                        : data?.bestRR}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total profit on RR range
                                    </p>
                                    <p
                                      className={`text-sm font-proxima-bold ${
                                        parseFloat(
                                          data?.bestRRPnL?.toFixed(2)
                                        ) < 0
                                          ? "text-red-500"
                                          : "text-green-500"
                                      }`}
                                    >
                                      ₹{" "}
                                      {data?.bestRRPnL
                                        ? parseFloat(
                                            data?.bestRRPnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel
                            key="Worst range"
                            value="Worst range"
                            className="p-0"
                          >
                            <div className="px-5">
                              <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                                The worst risk reward ratio range for maximum
                                profits
                              </p>
                              <div className="mt-8">
                                {loading ? (
                                  <>
                                    <div className="block dark:hidden">
                                      <Skeleton
                                        baseColor="#E5E5E5"
                                        highlightColor="#F5F5F5"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                    <div className="hidden dark:block">
                                      <Skeleton
                                        baseColor="#141418"
                                        highlightColor="#232327"
                                        className="w-1/3 h-8"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="min-h-[3vh]">
                                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                                      {data?.worstRR === undefined
                                        ? "-"
                                        : data?.worstRR}
                                    </h2>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1 pt-0 mt-8">
                              {loading ? (
                                <>
                                  <div className="block dark:hidden">
                                    <Skeleton
                                      baseColor="#E5E5E5"
                                      highlightColor="#F5F5F5"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                  <div className="hidden dark:block">
                                    <Skeleton
                                      baseColor="#141418"
                                      highlightColor="#232327"
                                      className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                                      Total loss on RR range
                                    </p>
                                    <p className="text-sm font-proxima-bold text-red-500">
                                      ₹{" "}
                                      {data?.worstRRPnL
                                        ? parseFloat(
                                            data?.worstRRPnL.toFixed(2)
                                          ).toLocaleString("en-IN")
                                        : "0.00"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </div>
                    </CardBody>
                  </Tabs>
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* ROW TWO */}
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 mt-5">
          {/* BEST TIME */}
          <div className="col-span-1">
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
              <CardBody className="p-0">
                <div className="h-[8vh] px-5 border-b border-borderGrey dark:border-darkBorder flex items-center">
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                      Best time
                    </h2>
                    <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                      The time range when you got maximum profits
                    </p>
                  </div>
                </div>
                <div className="h-[10vh] px-5 flex items-center">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-[15vh] h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-[15vh] h-8"
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                      {data?.bestTime === undefined ? "-" : data?.bestTime}
                    </h2>
                  )}
                </div>
                <div className="p-1 pt-0">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                          Total profit earned in this time range
                        </p>
                        <p
                          className={`text-sm font-proxima-bold ${
                            parseFloat(data?.bestTimePnL?.toFixed(2)) < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          ₹{" "}
                          {data?.bestTimePnL
                            ? parseFloat(
                                data?.bestTimePnL?.toFixed(2)
                              ).toLocaleString("en-IN")
                            : "0.00"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* BEAR OR BULL TRADE */}
          <div className="col-span-1">
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
              <CardBody className="p-0">
                <div className="h-[8vh] px-5 border-b border-borderGrey dark:border-darkBorder flex items-center">
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                      Bear or Bull Trader
                    </h2>
                    <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                      The type of trader you are based on positions
                    </p>
                  </div>
                </div>
                <div className="h-[10vh] px-5 flex items-center">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-[15vh] h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-[15vh] h-8"
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                      {data?.bestSide && data?.bestSide === "SHORT"
                        ? "Bear Trader"
                        : data?.bestSide && data?.bestSide === "LONG"
                        ? "Bull Trader"
                        : "-"}
                    </h2>
                  )}
                </div>
                <div className="p-1 pt-0">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                          Total profit earned as bear trader
                        </p>
                        <p
                          className={`text-sm font-proxima-bold ${
                            parseFloat(data?.bestSidePnL?.toFixed(2)) < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          ₹{" "}
                          {data?.bestSidePnL
                            ? parseFloat(
                                data?.bestSidePnL.toFixed(2)
                              ).toLocaleString("en-IN")
                            : "0.00"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* MAIN MISTAKES */}
          <div className="col-span-1">
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
              <CardBody className="p-0">
                <div className="h-[8vh] px-5 border-b border-borderGrey dark:border-darkBorder flex items-center">
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                      Main mistake
                    </h2>
                    <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                      The mistakes which cost you losses
                    </p>
                  </div>
                </div>
                <div className="h-[10vh] px-5 flex items-center">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-[15vh] h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-[15vh] h-8"
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                      {data?.bestMistake?.name === undefined
                        ? "-"
                        : data?.bestMistake?.name}
                    </h2>
                  )}
                </div>
                <div className="p-1 pt-0">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                      <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                        {mistake
                          ? data?.bestMistake?.description
                          : data?.bestMistake?.description?.substring(0, 50)}
                        <Button
                          className="ml-2 p-0 bg-transparent text-xs font-proxima-regular font-normal text-logoOcre normal-case shadow-none hover:shadow-none"
                          onClick={() => setMistake(!mistake)}
                        >
                          {mistake ? "less" : "more"}
                        </Button>
                      </p>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* INTRADAY OR SWING */}
          <div className="col-span-1">
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-full">
              <CardBody className="p-0">
                <div className="h-[8vh] px-5 border-b border-borderGrey dark:border-darkBorder flex items-center">
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                      Type of trader
                    </h2>
                    <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                      Type of trader you are based on your trade duration
                    </p>
                  </div>
                </div>
                <div className="h-[10vh] px-5 flex items-center">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-[15vh] h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-[15vh] h-8"
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-[2.8vh] font-proxima-bold leading-none text-black dark:text-white">
                      {data?.bestDuration === undefined
                        ? "-"
                        : data?.bestDuration}
                    </h2>
                  )}
                </div>
                <div className="p-1 pt-0">
                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-full h-[5vh] border border-darkBorder rounded-lg"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="h-[5vh] px-5 bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder w-full rounded-lg flex items-center">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-sm font-proxima-regular text-greyText dark:text-lightGreyText">
                          Total profit as intraday trader
                        </p>
                        <p className="text-sm font-proxima-bold text-green-500">
                          -
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Insights;
