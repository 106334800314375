/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import moment from "moment";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import DateTime from "react-datetime";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/design/input";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Tooltip,
  Dialog,
  DialogBody,
  Spinner,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";

/* ICONS */
import { PiNotePencil } from "react-icons/pi";
import { GoArrowUpRight } from "react-icons/go";
import { MdLabel, MdNoteAlt } from "react-icons/md";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdAddCircle,
} from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { IoSettingsSharp, IoExit } from "react-icons/io5";
import { BiSolidBookmarkAlt } from "react-icons/bi";

/* SAMPLE DATA */
import {
  getNotesByDay,
  getTag,
  getTradeByDay,
  addNotebook,
  updateNotebook,
  getNotebook,
  closeTrade,
} from "../../../service/api";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { Link } from "react-router-dom";
// const yearData = [{label:"2014",value:"2014"},
// { label: "2015",value: "2015" },
// { label: "2016", value: "2016" },
// { label: "2017", value: "2017" },
//   { label: "2018",value: "2018" },
//   { label: "2019", value: "2019" },
//   { label: "2020", value: "2020" },
//   { label: "2021", value: "2021" },
//   { label: "2022", value: "2022" },
//   { label: "2023", value: "2023" },
//   { label: "2024", value: "2024" },

// ]
const yearData = [
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
];
const DailyJournal = () => {
  const { selectedDay, exchanges } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  /* DIALOG STATES */
  const [noteDialog, setNoteDialog] = useState(false);
  const [dayNoteDialog, setDayNoteDialog] = useState(false);
  const [monthNoteDialog, setMonthNoteDialog] = useState(false);
  const [quillValue, setQuillValue] = useState("");
  const [dayQuillValue, setDayQuillValue] = useState("");
  const [monthQuillValue, setMonthQuillValue] = useState("");
  const [tag, setTag] = useState();
  const [mistake, setMistake] = useState();
  const [tagm, setTagm] = useState();
  const [mistakem, setMistakem] = useState();
  const redux = useSelector((state) => state.user);
  const [calendarData, setCalendarData] = useState();
  const [sortData, setSortData] = useState([]);
  const [id, setId] = useState("");
  const [tradeId, setTradeId] = useState();
  const [noteDataById, setNoteDataById] = useState();
  const [noteData, setNoteData] = useState();
  const [noteDatam, setNoteDatam] = useState();
  const [date, setDate] = useState();
  const [tagData, setTagData] = useState();
  const [mistakeData, setMistakeData] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editDay, setEditDay] = useState(false);
  const [editMonth, setEditMonth] = useState(false);
  const [tradeTileData, setTradeTileData] = useState();
  const [labelColorSelected, setLabelColorSelected] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTag2, setSelectedTag2] = useState("");
  const [selectedMistake, setSelectedMistake] = useState("");
  const [side, setSide] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedSide, setSelectedSide] = useState("Filter by side");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [addLabel, setAddLabel] = useState(false);
  const [filterPopover, setFilterPopover] = useState(false);
  const [filterAdded, setFilterAdded] = useState(false);
  const [exchangeFee, setExchangeFee] = useState(0);
  const [exchangeFeem, setExchangeFeem] = useState(0);
  const [selectedYear, setSelectedYear] = useState();
  const [squareOffDialog, setSquareOffDialog] = useState(false);
  const [closePrice, setClosePrice] = useState();
  const [dateTime, setDateTime] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({ closed: false, dateTime: false });
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState();
  const handleSelect = (e) => {
    setDateTime(e);
  };
  const validation = (currentDate) => {
    return currentDate.isBefore(new Date());
  };
  const handleSquareOff = () => {
    setIsSubmitted(true);
    let error = errors;
    if (!closePrice) {
      // setErrors({ ...errors, closed: true })
      return;
    } else {
      // setErrors({ ...errors, closed: false })
    }
    if (!dateTime) {
      // setErrors({ ...errors, dateTime: true })
      return;
    } else {
      // setErrors({ ...errors, dateTime: false })
    }
    let body = {
      dateTime: moment(dateTime).format("DD/MM/YYYY HH:MM:SS"),
      price: closePrice.toString(),
    };
    setLoader(true);
    closeTrade(selectedTrade?._id, body).then((res) => {
      if (res?.status) {
        setLoader(false);
        setIsSubmitted(false);
        toast.success(res.message);
        setErrors({ closed: false, dateTime: false });
        setSquareOffDialog(false);
        setClosePrice();
        setDateTime();
        setSelectedTrade();
        // getTradeData();
      }
    });
  };
  /* MONTHS LIST */
  const monthsList = [
    {
      id: 1,
      name: "January",
      key: "JANUARY",
    },
    {
      id: 2,
      name: "February",
      key: "FEBRUARY",
    },
    {
      id: 3,
      name: "March",
      key: "MARCH",
    },
    {
      id: 4,
      name: "April",
      key: "APRIL",
    },
    {
      id: 5,
      name: "May",
      key: "MAY",
    },
    {
      id: 6,
      name: "June",
      key: "JUNE",
    },
    {
      id: 7,
      name: "July",
      key: "JULY",
    },
    {
      id: 8,
      name: "August",
      key: "AUGUST",
    },
    {
      id: 9,
      name: "September",
      key: "SEPTEMBER",
    },
    {
      id: 10,
      name: "October",
      key: "OCTOBER",
    },
    {
      id: 11,
      name: "November",
      key: "NOVEMBER",
    },
    {
      id: 12,
      name: "December",
      key: "DECEMBER",
    },
  ];

  const productData = [
    { value: "MIS", label: "MIS" },
    { value: "NRML", label: "NRML" },
    { value: "CNC", label: "CNC" },
    { value: "CO", label: "CO" },
    { value: "BO", label: "BO" },
  ];

  /* MONTHLY LIST VARIABLES */
  const [currentTab, setCurrentTab] = useState(
    Number(redux.endDate?.split("/")[1]?.split("/")[0])
  );

  useEffect(() => {
    setCurrentTab(Number(redux.endDate?.split("/")[1]?.split("/")[0]));
    // setDate("")
  }, [redux.endDate]);
  /* LABEL COLORS */
  const labelColors = [
    {
      id: 1,
      color: "#3498DB",
    },
    {
      id: 2,
      color: "#2ECC71",
    },
    {
      id: 3,
      color: "#E74C3C",
    },
    {
      id: 4,
      color: "#F39C12",
    },
    {
      id: 5,
      color: "#9B59B6",
    },
    {
      id: 6,
      color: "#2C3E50",
    },
    {
      id: 7,
      color: "#27AE60",
    },
    {
      id: 8,
      color: "#C0392B",
    },
    {
      id: 9,
      color: "#8E44AD",
    },
  ];

  /* SIDE OPTIONS */
  const sideOptions = [
    { value: "SHORT", label: "SHORT" },
    { value: "LONG", label: "LONG" },
  ];

  /* GET TAG DATA FUNCTION */
  const getTagData = () => {
    getTag()
      .then((res) => {
        if (res?.status) {
          let arr = [];
          let arr2 = [];
          res?.data?.map((obj) => {
            if (obj?.type === "MISTAKE") {
              arr2.push({ value: obj?._id, label: obj?.name, type: obj?.type });
            } else if (obj?.type === "SETUP") {
              arr.push({
                value: obj?._id,
                label: obj?.name,
                type: obj?.type,
                color: obj?.color,
              });
            }
          });
          setTagData(arr);
          setMistakeData(arr2);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  function isFirstDigitZeroNotAcceptable(number) {
    // Convert number to string
    let numberString = number.toString();

    // Check if number is greater than 10 and first digit is zero
    if (number == 0) {
      return number; // First digit is zero and number is greater than 10
    } else if (number > 0 && numberString.charAt(0) !== "0") {
      return number; // First digit is zero and number is greater than 10
    } else {
      return numberString.split("0")[1]; // First digit is not zero or number is less than or equal to 10
    }
  }

  const handleData = (props) => {
    let params = { startDate: redux.startDate, endDate: redux.endDate };
    if (redux.selectedExchange && redux.selectedExchange?.length > 0) {
      params.exchangeId = redux.selectedExchange?.map((obj) => obj?._id);
    }
    if (search && props !== "clear") {
      params.search = search;
    }
    if (side?.length > 0 && side?.length < 2 && props !== "clear") {
      params.side = side[0];
    }
    if (selectedTag && !props) {
      params.setUpTag = selectedTag;
    }
    if (selectedTag2?.value && props !== "clear") {
      params.setUpTag = selectedTag2?.value;
    }

    if (selectedMistake?.value && props !== "clear") {
      params.mistakeTag = selectedMistake?.value;
    }
    if (selectedProduct?.value && props !== "clear") {
      params.product = selectedProduct?.value;
    }

    setLoading(true);
    getNotesByDay(params)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setCalendarData(res?.data);
          if (res?.data?.length === 0) {
            setTradeTileData([]);
          }
          // setNoteData();
          // setTradeTileData([]);
          setId("");
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
    getTradeByDate(props);
  };

  /* GET FOLDER FUNCTION */
  const getNote = (val) => {
    if (!date && !selectedDay) {
      return;
    }
    let params = { page: 1, sizePerPage: 100 };
    if (selectedDay) {
      params.date = selectedDay;
    }
    if (date) {
      params.date = date;

      let note = calendarData.filter((d) => d.date === date);
      if (note?.length > 0 && val && note?.notebookData) {
        note = [note.notebookData];
        setNoteData(note);
        setDayQuillValue(note[0]?.text);
        if (note[0]?.exchangeFee) setExchangeFee(note[0]?.exchangeFee);

        if (tagData?.length !== 0) {
          let arr = tagData?.filter((obj) => obj?.value === note[0]?.tag);
          setTag(arr);
        }
        if (mistakeData?.length !== 0) {
          let arr2 = mistakeData?.filter(
            (obj) => obj?.value === note[0]?.mistakeTag
          );
          setMistake(arr2);
        }
        return;
      }
    }

    setLoading(true);

    getNotebook(params)
      .then((res) => {
        if (res?.data?.docs?.length !== 0) {
          setNoteData(res?.data?.docs);
          setEditDay(true);
          setLoading(false);

          setDayQuillValue(res?.data?.docs[0]?.text);
          if (res?.data?.docs[0]?.exchangeFee) {
            setExchangeFee(res?.data?.docs[0]?.exchangeFee);
          } else {
            setExchangeFee(0);
          }

          if (tagData?.length !== 0) {
            let arr = tagData?.filter(
              (obj) => obj?.value === res?.data?.docs[0]?.tag
            );
            setTag(arr);
          }
          if (mistakeData?.length !== 0) {
            let arr2 = mistakeData?.filter(
              (obj) => obj?.value === res?.data?.docs[0]?.mistakeTag
            );
            setMistake(arr2);
          }
        } else {
          setEditDay(false);
          setNoteData();
          setTag();
          setMistake();
          setDayQuillValue("");
          setExchangeFee(0);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* NOTE FOR THE MONTH */
  const getNoteMonth = (val) => {
    let params = { page: 1, sizePerPage: 100 };
    if (selectedDay && !val) {
      params.date = selectedDay;
    }
    if (date && !val) {
      params.date = date;
    }
    if (val) {
      params.year = redux.endDate?.split("/")[2];
      params.month = monthsList[currentTab - 1]?.key;
    }
    setLoading(true);

    getNotebook(params)
      .then((res) => {
        if (res?.data?.docs?.length !== 0) {
          setNoteDatam(res?.data?.docs);
          setEditMonth(true);
          setLoading(false);
          setExchangeFeem(res?.data?.docs[0]?.exchangeFee);
          setMonthQuillValue(res?.data?.docs[0]?.text);
          if (tagData?.length !== 0) {
            let arr = tagData?.filter(
              (obj) => obj?.value === res?.data?.docs[0]?.tag
            );
            setTagm(arr);
          }
          if (mistakeData?.length !== 0) {
            let arr2 = mistakeData?.filter(
              (obj) => obj?.value === res?.data?.docs[0]?.mistakeTag
            );
            setMistakem(arr2);
          }
        } else {
          setEditMonth(false);
          setNoteDatam();
          setTagm([]);
          setMistakem([]);
          setMonthQuillValue("");
          setExchangeFeem(0);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* GET TRADE BY DATE */
  const getTradeByDate = (props) => {
    let params = {};
    if (redux.selectedExchange && redux.selectedExchange?.length > 0) {
      params.exchangeId = redux.selectedExchange?.map((obj) => obj?._id);
    }
    if (selectedDay) {
      params.date = selectedDay;
    }
    if (!date) {
      return;
    }
    if (date) {
      params.date = date;
    }
    if (search && props !== "clear") {
      params.search = search;
    }
    if (side?.length > 0 && side?.length < 2 && props !== "clear") {
      params.side = side[0];
    }
    if (selectedTag && !props) {
      params.setUpTag = selectedTag;
    }
    if (selectedTag2?.value && props !== "clear") {
      params.setUpTag = selectedTag2?.value;
    }
    if (selectedMistake?.value && props !== "clear") {
      params.mistakeTag = selectedMistake?.value;
    }
    if (selectedProduct?.value && props !== "clear") {
      params.product = selectedProduct?.value;
    }
    setLoading(true);
    getTradeByDay(params)
      .then((res) => {
        setLoading(false);

        if (res.status) {
          setTradeTileData(res?.data);
        }
      })
      .catch((e) => {
        setTradeTileData([]);
        console.log("Error: ", e);
      });
  };

  /* ADD NOTEBOOK FUNCTION */
  const addNote = (data, e, tId, tags, check, id) => {
    if (data?.type === "TRADE") {
      const checking = check ? check : edit;

      if (!checking) {
        let body = {
          tag: tags === "tag" ? e?.value : tag?.value,
          type: data?.type,
          title: date ? date : selectedDay,
          templete: "100 trades",
          trade: e ? tId : tradeId,
          mistakeTag: tags === "mistake" ? e?.value : mistake?.value,
        };
        if (quillValue) {
          body.text = quillValue;
        }
        setLoading(true);
        addNotebook(body)
          .then((res) => {
            if (res.status) {
              toast.success("The details have been saved successfully.");
              setQuillValue("");
              setLoading(false);
              setDayNoteDialog(false);
              setNoteDialog(false);
              setMistake();
              setTag();
              getTradeByDate();
              // getNotesByDay();
              getNote();
            } else {
              setLoading(false);
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
            toast.error(e?.message);
            setLoading(false);
          });
      } else {
        let body = {};
        if (quillValue) {
          body.text = quillValue;
        }
        if (tags === "tag") {
          body.tag = e?.value;
        } else if (tags === "mistake") {
          body.mistakeTag = e?.value;
        }
        setLoading(true);
        updateNotebook(id ? id?._id : noteDataById?._id, body)
          .then((res) => {
            if (res.status) {
              toast.success("The details have been saved successfully.");
              setQuillValue("");
              setDayQuillValue("");
              setExchangeFee(0);
              setDayNoteDialog(false);
              setNoteDialog(false);
              setLoading(false);
              setEdit(false);
              setTag();
              setMistake();
              // getNotesByDay();
              getTradeByDate();
              getNote();
            } else {
              toast.error(res?.message);
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
            toast.error(e?.message);
            setLoading(false);
          });
      }
    } else {
      if (!editDay) {
        let body = {
          tag: tag?.value,
          type: data?.type,
          title: date ? date : selectedDay,
          templete: "100 trades",
          mistakeTag: mistake?.value,
        };
        if (data?.type === "DATE") {
          if (dayQuillValue) {
            body.text = dayQuillValue;
          }
          body.date = date ? date : selectedDay;
          if (exchangeFee) {
            body.exchangeFee = exchangeFee?.toString();
          }
        }
        if (data?.type === "MONTH") {
          body.year = redux.endDate?.split("/")[2];
          body.month = monthsList[currentTab - 1].key;
          if (monthQuillValue) {
            body.text = monthQuillValue;
          }
          body.title = redux.endDate?.split("/")[2];
          body.tag = tagm?.value;
          body.mistakeTag = mistakem?.value;
        }

        setLoading(true);
        addNotebook(body)
          .then((res) => {
            if (res.status) {
              toast.success("The details have been saved successfully.");

              setQuillValue("");
              setExchangeFee(0);
              setDayQuillValue("");
              setMonthQuillValue("");
              setLoading(false);
              setDayNoteDialog(false);
              setMonthNoteDialog(false);
              setNoteDialog(false);
              getTradeByDate();
              // getNotesByDay();
              setMistake();

              setTag();
              setTagm([]);
              setMistakem([]);
              getNote();
              getNoteMonth(true);

              setTimeout(() => {
                handleData();
              }, 500);
            } else {
              setLoading(false);
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
            toast.error(e?.message);
            setLoading(false);
          });
      } else {
        let body = {
          tag: tag?.value,
          mistakeTag: mistake?.value,
        };
        if (data?.type === "DATE") {
          if (dayQuillValue) {
            body.text = dayQuillValue;
          }
          if (exchangeFee !== "") {
            body.exchangeFee = exchangeFee ? exchangeFee?.toString() : "0";
          }
        }
        if (data?.type === "MONTH") {
          if (monthQuillValue) {
            body.text = monthQuillValue;
          }
          body.tag = tagm?.value;
          body.mistakeTag = mistakem?.value;
        }
        setLoading(true);
        updateNotebook(
          data?.type === "DATE" ? noteData[0]?._id : noteDatam[0]?._id,
          body
        )
          .then((res) => {
            if (res.status) {
              setQuillValue("");
              setExchangeFee(0);
              setDayQuillValue("");
              setMonthQuillValue("");
              setLoading(false);
              setDayNoteDialog(false);
              setMonthNoteDialog(false);
              setNoteDialog(false);
              getNoteMonth(true);
              getTradeByDate();
              // getNotesByDay();
              setMistake();
              setMistakem();
              setTag();
              setTagm();
              getTradeByDate();
              getNote();
              setTimeout(() => {
                handleData();
              }, 500);
            } else {
              toast.error(res?.message);
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
            toast.error(e?.message);
            setLoading(false);
          });
      }
    }
  };

  /* ADD NOTE FOR THE MONTH */
  const addNoteMonth = (data, e, tId, tags, check, id) => {
    if (!editMonth) {
      let body = {
        tag: tag?.value,
        type: data?.type,
        title: date ? date : selectedDay,
        templete: "100 trades",
        mistakeTag: mistake?.value,
      };
      if (data?.type === "DATE") {
        if (dayQuillValue) {
          body.text = dayQuillValue;
        }
        body.date = date ? date : selectedDay;
      }
      if (data?.type === "MONTH") {
        body.year = redux.endDate?.split("/")[2];
        body.month = monthsList[currentTab - 1].key;
        if (monthQuillValue) {
          body.text = monthQuillValue;
        }
        // body.exchangeFee = exchangeFeem ? exchangeFeem?.toString() : "0"
        body.title = redux.endDate?.split("/")[2];
        body.tag = tagm?.value;
        body.mistakeTag = mistakem?.value;
      }

      setLoading(true);
      addNotebook(body)
        .then((res) => {
          if (res.status) {
            toast.success("The details have been saved successfully.");
            setQuillValue("");
            setDayQuillValue("");
            setMonthQuillValue(0);
            setExchangeFee("");
            setLoading(false);
            setDayNoteDialog(false);
            setMonthNoteDialog(false);
            setNoteDialog(false);
            getTradeByDate();
            // getNotesByDay();
            setMistake();
            setNoteDatam("");
            setExchangeFeem(0);
            setTag();
            setTagm([]);
            setMistakem([]);
            getNote();
            getNoteMonth(true);

            setTimeout(() => {
              handleData();
            }, 500);
          } else {
            setLoading(false);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
          setLoading(false);
        });
    } else {
      let body = {
        tag: tag?.value,
        text: dayQuillValue,
        mistakeTag: mistake?.value,
      };
      if (data?.type === "MONTH") {
        if (monthQuillValue) {
          body.text = monthQuillValue;
        }
        // body.exchangeFee = exchangeFeem ? exchangeFeem?.toString() : "0"
        body.tag = tagm?.value;
        body.mistakeTag = mistakem?.value;
      }
      setLoading(true);
      updateNotebook(
        data?.type === "DATE" ? noteData[0]?._id : noteDatam[0]?._id,
        body
      )
        .then((res) => {
          if (res.status) {
            setQuillValue("");
            setExchangeFee(0);
            setDayQuillValue("");
            setMonthQuillValue("");
            setLoading(false);
            setDayNoteDialog(false);
            setMonthNoteDialog(false);
            setNoteDialog(false);
            getNoteMonth(true);
            getTradeByDate();
            setNoteDatam("");
            setExchangeFeem(0);
            // getNotesByDay();
            setMistake();
            setMistakem();
            setTag();
            setTagm();
            getTradeByDate();
            getNote();
            setTimeout(() => {
              handleData();
            }, 500);
          } else {
            toast.error(res?.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
          setLoading(false);
        });
    }
  };

  /* DAY TILE */
  const DayTile = (props) => {
    let data = props?.data;
    let profit =
      (data?.data !== 0
        ? data?.data?.trade
            ?.map((res) => (res?.profit ? res?.profit : 0))
            .reduce((acc, crr) => acc + +crr)
            ?.toFixed(2)
        : 0) - (props?.exchangeFee ? props?.exchangeFee : 0);
    const TickerBadge = (props) => {
      return (
        <div className="bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder px-2 py-2 text-[1.3vh] font-proxima-semibold text-[#777] dark:text-lightGreyText rounded leading-none uppercase">
          {props?.data}
        </div>
      );
    };
    return (
      <div
        onClick={() => {
          setDate(moment(props?.data?.date).format("DD/MM/YYYY"));
          setId(props?.id);
          dispatch(actions.setSelectedDay(null));
        }}
        className={`w-full border ${
          date?.toString() ===
          moment(props?.data?.date).format("DD/MM/YYYY")?.toString()
            ? "border-buttonColor border-opacity-50 bg-buttonColor bg-opacity-5 dark:bg-opacity-30"
            : "border-[#E5E5E5] dark:border-darkBorder bg-white dark:bg-darkSidebar"
        }  rounded-lg shadow-md p-5 cursor-pointer hover:border-buttonColor hover:border-opacity-50 transition-all duration-300 ease-out first:mt-0`}
        key={props.key}
      >
        <div className="flex items-center justify-between">
          <h5 className="text-base font-proxima-bold text-black dark:text-white">
            {moment(data?.date).format("ddd, MMM DD, YYYY")}
          </h5>
          <h5 className="text-sm font-proxima-regular dark:text-lightGreyText">
            Net P&L:{" "}
            <span
              className={`font-proxima-semibold text-base ${
                parseFloat(profit) > 0
                  ? "text-green-500"
                  : parseFloat(profit) < 0
                  ? "text-red-500"
                  : "text-black dark:text-white"
              }`}
            >
              ₹
              {parseFloat(parseFloat(profit).toFixed(2)).toLocaleString(
                "en-IN"
              )}
            </span>
          </h5>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="p-0">
            <h5 className="text-sm font-proxima-regular dark:text-lightGreyText leading-none">
              Total Trades:{" "}
            </h5>
            <p className="mt-1 font-proxima-bold text-sm text-black dark:text-white">
              {data?.data?.total}
            </p>
          </div>
          <div className="p-0">
            <h5 className="text-sm font-proxima-regular dark:text-lightGreyText leading-none">
              Win:Loss:{" "}
            </h5>
            <p
              className={`mt-1 font-proxima-semibold text-sm ${
                parseFloat(data?.data?.win) > parseFloat(data?.data?.loss)
                  ? "text-green-500"
                  : parseFloat(data?.data?.win) < parseFloat(data?.data?.loss)
                  ? "text-red-500"
                  : "text-black dark:text-white"
              }`}
            >
              {data?.data?.win}:{data?.data?.loss}
            </p>
          </div>
          <div className="p-0">
            <h5 className="text-sm font-proxima-regular dark:text-lightGreyText leading-none">
              Commission / Fees: &nbsp;
            </h5>
            <p className="mt-1 font-proxima-bold text-sm text-black dark:text-white">
              {props?.exchangeFee ? props?.exchangeFee : 0}
            </p>
          </div>
        </div>
        <div className="flex items-center whitespace-nowrap gap-2 mt-3 overflow-scroll overflow-y-hidden hide-scrollbar">
          {props?.tickers?.length > 0 &&
            [...new Set(props?.tickers)]?.map((obj) => {
              return <TickerBadge data={obj} />;
            })}
        </div>
        {props.note && (
          <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText mt-3">
            <ReactQuill
              modules={{
                toolbar: false,
              }}
              theme="snow"
              toolbar={false}
              className="rounded-lg text-base dark:text-white text-black readonly-editor font-proxima-regular"
              placeholder="Add your note here"
              value={props.note}
              readOnly
            />
          </p>
        )}
      </div>
    );
  };

  /* TRADE TILE */
  const TradeTile = (props) => {
    const StatTile = (props) => {
      return (
        <div>
          <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
            {props.labelName}
          </p>
          <p
            className={`text-base font-proxima-semibold ${
              props.labelName === "Net P&L"
                ? props?.labelValue < 0
                  ? "text-red-500 dark:text-red-500"
                  : "text-green-500 dark:text-green-500"
                : "text-black dark:text-white mt-1"
            } text-black dark:text-white mt-1`}
          >
            {props.labelValue === "-"
              ? "-"
              : parseFloat(
                  parseFloat(props.labelValue).toFixed(2)
                ).toLocaleString("en-IN")}
          </p>
        </div>
      );
    };

    const StatTile2 = (props) => {
      return (
        <div>
          <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
            {props.labelName}
          </p>
          <p
            className={`text-base font-proxima-semibold ${
              props.labelName === "Net P&L"
                ? props?.labelValue < 0
                  ? "text-red-500 dark:text-red-500"
                  : "text-green-500 dark:text-green-500"
                : "text-black dark:text-white mt-1"
            } text-black dark:text-white mt-1`}
          >
            {props.labelValue}
          </p>
        </div>
      );
    };

    const handlePopup = (props) => {
      setNoteDialog(!noteDialog);
      setTradeId(props?.data?._id);
      if (props?.data?.notebooksByTrade?.length > 0) {
        setEdit(true);
        setNoteDataById(props?.data?.notebooksByTrade[0]);
        setQuillValue(props?.data?.notebooksByTrade[0]?.text);
        if (tagData?.length !== 0) {
          let arr = tagData?.filter(
            (obj) => obj?.value === props?.data?.notebooksByTrade[0]?.tag
          );
          setTag(arr);
        }
        if (mistakeData?.length !== 0) {
          let arr2 = mistakeData?.filter(
            (obj) => obj?.value === props?.data?.notebooksByTrade[0]?.mistakeTag
          );
          setMistake(arr2);
        }
      } else {
        setEdit(false);
        setQuillValue("");
        setNoteDataById();
        // setTag();
      }
    };

    const [quantity, setQuantity] = useState(0);

    const calculateQuantity = () => {
      if (
        props?.data?.instrumentType === "EQUITY" ||
        props?.data?.instrumentType === "FUTURE"
      ) {
        if (props?.data?.side === "SHORT") {
          setQuantity(props?.data?.sellQuantity);
        } else if (props?.data?.side === "LONG") {
          setQuantity(props?.data?.buyQuantity);
        } else {
          setQuantity(0);
        }
      } else {
        if (
          props?.data?.side === "LONG" &&
          props?.data?.instrumentToken?.slice(-3).includes("CE")
        ) {
          setQuantity(props?.data?.buyQuantity);
        } else if (
          props?.data?.side === "LONG" &&
          props?.data?.instrumentToken?.slice(-3).includes("PE")
        ) {
          setQuantity(props?.data?.sellQuantity);
        } else if (
          props?.data?.side === "SHORT" &&
          props?.data?.instrumentToken?.slice(-3).includes("CE")
        ) {
          setQuantity(props?.data?.sellQuantity);
        } else if (
          props?.data?.side === "SHORT" &&
          props?.data?.instrumentToken?.slice(-3).includes("PE")
        ) {
          setQuantity(props?.data?.buyQuantity);
        }
      }
    };

    useEffect(() => {
      calculateQuantity();
    }, [props?.data]);

    return (
      <div
        className={`${
          props?.data?.profit?.toFixed(2) > 0
            ? "bg-green-500 border-green-500"
            : props?.data?.profit?.toFixed(2) < 0
            ? "bg-red-500 border-red-500"
            : props?.data?.isOpen
            ? "bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5]"
            : "bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5]"
        } rounded-lg p-5 cursor-pointer bg-opacity-5 dark:bg-opacity-5 border relative`}
        style={{ marginBottom: "5px" }}
      >
        {/* OPTIONS */}
        <div className="flex items-center gap-2 flex-wrap">
          {props.data?.product && (
            <div className="px-2 py-1 rounded bg-[#E5E5E5] dark:bg-[#F5F5F5]">
              <p className="text-[1.2vh] font-proxima-regular text-black leading-none">
                {props.data?.product}
              </p>
            </div>
          )}

          {props.data?.userExchange &&
            exchanges?.filter((obj) => obj?._id === props.data?.userExchange)
              ?.length > 0 && (
              <div className="px-2 py-1 rounded bg-[#E5E5E5] dark:bg-[#F5F5F5]">
                <p
                  style={{ textTransform: "uppercase" }}
                  className="text-[1.2vh] font-proxima-regular text-black leading-none"
                >
                  {props.data?.userExchange
                    ? exchanges?.filter(
                        (obj) => obj?._id === props.data?.userExchange
                      )[0]?.exchange
                    : ""}
                </p>
              </div>
            )}
          {props.data?.instrumentType && (
            <div className="px-2 py-1 rounded bg-[#E5E5E5] dark:bg-[#F5F5F5]">
              <p className="text-[1.2vh] font-proxima-regular text-black leading-none">
                {props.data?.instrumentType}
              </p>
            </div>
          )}
        </div>

        {/* TICKER DETAILS */}
        <div className="flex items-center justify-between mt-1">
          <div className="mt-2">
            <h5 className="w-4/5 text-lg font-proxima-bold text-black dark:text-white tracking-tight leading-snug">
              {props?.data?.tickerName}
            </h5>
            <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
              {props?.data?.date}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {props?.data?.isOpen && (
              <Tooltip
                content="Square off this position"
                className="font-proxima-semibold"
              >
                {/* <Link to="/trade-details"> */}
                <Button
                  onClick={() => {
                    setSquareOffDialog(!squareOffDialog);
                    setSelectedTrade(props.data);
                  }}
                  className="border border-greyText dark:border-darkBorder hover:border-buttonColor bg-transparent hover:bg-buttonColor dark:bg-bgDark dark:hover:bg-buttonColor hover:text-white shadow-none hover:shadow-none rounded-full p-2 transition-all duration-300 ease-in-out text-greyText dark:text-white"
                >
                  <IoExit className="w-5 h-5" />
                </Button>
                {/* </Link> */}
              </Tooltip>
            )}
            <Tooltip
              content="Add note for this position"
              className="font-proxima-semibold"
            >
              <Button
                className="border border-greyText dark:border-darkBorder hover:border-buttonColor bg-transparent hover:bg-buttonColor dark:bg-bgDark dark:hover:bg-buttonColor hover:text-white shadow-none hover:shadow-none rounded-full p-2 transition-all duration-300 ease-in-out text-greyText dark:text-white"
                onClick={() => {
                  handlePopup(props);
                }}
              >
                <PiNotePencil className="w-5 h-5" />
              </Button>
            </Tooltip>
            <Tooltip
              content="View position details"
              className="font-proxima-semibold"
            >
              <Link
                to="/trade-details"
                onClick={() => dispatch(actions.setSelectedTrade(props.data))}
              >
                <Button className="border border-greyText dark:border-darkBorder hover:border-buttonColor bg-transparent dark:bg-bgDark dark:hover:bg-buttonColor hover:bg-buttonColor hover:text-white shadow-none hover:shadow-none rounded-full p-2 transition-all duration-300 ease-in-out text-greyText dark:text-white">
                  <GoArrowUpRight className="w-5 h-5" />
                </Button>
              </Link>
            </Tooltip>
          </div>
        </div>

        {/* TRADE STATISTICS */}
        <div className="mt-5">
          <div className="flex items-center flex-wrap gap-3 xl:gap-0 lg:gap-0 justify-between">
            <StatTile
              labelName="Net P&L"
              labelValue={
                !props?.data?.profit ? "-" : props?.data?.profit?.toFixed(2)
              }
            />
            <StatTile
              labelName={props?.data?.isOpen ? "Pending Quantity" : "Quantity"}
              labelValue={
                props?.data?.isOpen
                  ? props.data?.pendingQuantity
                  : props.data?.buyQuantity
              }
            />
            {props.data?.side ? (
              <StatTile2 labelName="Side" labelValue={props.data?.side} />
            ) : (
              <StatTile
                labelName="Quantity"
                labelValue={props.data?.quantity}
              />
            )}
            <StatTile
              labelName="Trade ROI (%)"
              labelValue={
                props.data?.profit
                  ? ((props.data?.profit * 100) / props?.data?.amount).toFixed(
                      2
                    )
                  : "-"
              }
            />
          </div>
        </div>

        {/* SETUPS */}
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
              Add setups for this trade
            </p>
            <Link
              to="/setup-management"
              className="group text-[1.4vh] normal-case shadow-none hover:shadow-none hover:text-buttonColor flex items-center gap-1 font-proxima-regular text-[#777] dark:text-lightGreyText rounded-md w-auto"
            >
              <IoMdAddCircle className="w-4 h-4 text-[#777] dark:text-lightGreyText group-hover:text-buttonColor transition-all duration-300 ease-in-out" />
              Add new setup
            </Link>
          </div>

          <div className="block dark:hidden">
            <Select
              options={tagData}
              className="tags-select w-full font-proxima-regular mt-1"
              classNamePrefix="note-tag"
              placeholder="Click to add setups"
              onChange={(e) => {
                setTag(e);
                setTradeId(props?.data?._id);
                if (props?.data?.notebooksByTrade?.length > 0) {
                  setEdit(true);
                  setNoteDataById(props?.data?.notebooksByTrade[0]);
                  setQuillValue(props?.data?.notebooksByTrade[0]?.text);
                } else {
                  setEdit(false);
                  setQuillValue("");
                  setNoteDataById();
                }
                setTimeout(() => {
                  addNote(
                    { type: "TRADE" },
                    e,
                    props?.data?._id,
                    "tag",
                    props?.data?.notebooksByTrade?.length > 0,
                    props?.data?.notebooksByTrade[0]
                  );
                }, 500);
              }}
              value={
                props?.data?.notebooksByTrade[0]?.tag
                  ? tagData?.filter(
                      (ob) => ob.value === props?.data?.notebooksByTrade[0]?.tag
                    )
                  : ""
              }
            />
          </div>
          <div className="hidden dark:block">
            <Select
              options={tagData}
              className="tags-select-dark w-full font-proxima-regular mt-1"
              classNamePrefix="note-tag"
              placeholder="Click to add setups"
              onChange={(e) => {
                setTag(e);
                setTradeId(props?.data?._id);
                if (props?.data?.notebooksByTrade?.length > 0) {
                  setEdit(true);
                  setNoteDataById(props?.data?.notebooksByTrade[0]);
                  setQuillValue(props?.data?.notebooksByTrade[0]?.text);
                } else {
                  setEdit(false);
                  setQuillValue("");
                  setNoteDataById();
                }
                setTimeout(() => {
                  addNote(
                    { type: "TRADE" },
                    e,
                    props?.data?._id,
                    "tag",
                    props?.data?.notebooksByTrade?.length > 0,
                    props?.data?.notebooksByTrade[0]
                  );
                }, 1000);
              }}
              value={
                props?.data?.notebooksByTrade[0]?.tag
                  ? tagData?.filter(
                      (ob) => ob.value === props?.data?.notebooksByTrade[0]?.tag
                    )
                  : ""
              }
            />
          </div>
        </div>

        {/* MISTAKES */}
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
              Add mistake for this trade
            </p>
            <Link
              to="/setup-management"
              className="group text-[1.4vh] normal-case shadow-none hover:shadow-none hover:text-buttonColor flex items-center gap-1 font-proxima-regular text-[#777] dark:text-lightGreyText rounded-md w-auto"
            >
              <IoMdAddCircle className="w-4 h-4 text-[#777] dark:text-lightGreyText group-hover:text-buttonColor transition-all duration-300 ease-in-out" />
              Add new mistake
            </Link>
          </div>
          <div className="block dark:hidden">
            <Select
              options={mistakeData}
              className="tags-select w-full font-proxima-regular mt-1"
              classNamePrefix="note-tag"
              placeholder="Click to add mistakes"
              onChange={(e) => {
                setMistake(e);
                setTradeId(props?.data?._id);
                if (props?.data?.notebooksByTrade?.length > 0) {
                  setEdit(true);
                  setNoteDataById(props?.data?.notebooksByTrade[0]);
                  setQuillValue(props?.data?.notebooksByTrade[0]?.text);
                } else {
                  setEdit(false);
                  setQuillValue("");
                  setNoteDataById();
                }
                setTimeout(() => {
                  addNote(
                    { type: "TRADE" },
                    e,
                    props?.data?._id,
                    "mistake",
                    props?.data?.notebooksByTrade?.length > 0,
                    props?.data?.notebooksByTrade[0]
                  );
                }, 500);
              }}
              value={
                props?.data?.notebooksByTrade[0]?.mistakeTag
                  ? mistakeData?.filter(
                      (ob) =>
                        ob.value ===
                        props?.data?.notebooksByTrade[0]?.mistakeTag
                    )
                  : ""
              }
            />
          </div>
          <div className="hidden dark:block">
            <Select
              options={mistakeData}
              className="tags-select-dark w-full font-proxima-regular mt-1"
              classNamePrefix="note-tag"
              placeholder="Click to add mistakes"
              onChange={(e) => {
                setMistake(e);
                setTradeId(props?.data?._id);
                if (props?.data?.notebooksByTrade?.length > 0) {
                  setEdit(true);
                  setNoteDataById(props?.data?.notebooksByTrade[0]);
                  setQuillValue(props?.data?.notebooksByTrade[0]?.text);
                } else {
                  setEdit(false);
                  setQuillValue("");
                  setNoteDataById();
                }
                setTimeout(() => {
                  addNote(
                    { type: "TRADE" },
                    e,
                    props?.data?._id,
                    "mistake",
                    props?.data?.notebooksByTrade?.length > 0,
                    props?.data?.notebooksByTrade[0]
                  );
                }, 500);
              }}
              value={
                props?.data?.notebooksByTrade[0]?.mistakeTag
                  ? mistakeData?.filter(
                      (ob) =>
                        ob.value ===
                        props?.data?.notebooksByTrade[0]?.mistakeTag
                    )
                  : ""
              }
            />
          </div>
        </div>

        {/* BOOKMARK ICON */}
        {props?.data?.notebooksByTrade[0]?.tag && (
          <div className="absolute -top-2 right-1">
            <BiSolidBookmarkAlt
              className="w-6 h-6"
              color={
                tagData?.filter(
                  (ob) => ob?.value === props?.data?.notebooksByTrade[0]?.tag
                )[0].color
              }
            />
          </div>
        )}
      </div>
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    getTradeByDate();
    getNote("first");
  }, [date, selectedDay]);

  /* USE EFFECT */
  useEffect(() => {
    if (!loading) getNoteMonth(true);
  }, [currentTab]);

  /* USE EFFECT */
  useEffect(() => {
    getTagData();
  }, []);

  /* USE EFFECT */
  useEffect(() => {
    handleData();
    if (redux.endDate) {
      setSelectedYear(yearData?.indexOf(redux.endDate?.split("/")[2]));
      // setSelectedYear({ label: redux.endDate?.split("/")[2], value: redux.endDate?.split("/")[2] })
    }
  }, [redux.selectedExchange, redux.endDate]);

  /* USE EFFECT */
  useEffect(() => {
    if (
      selectedMistake ||
      selectedTag2 ||
      selectedTag ||
      search ||
      side?.length > 0 ||
      selectedProduct
    ) {
      handleData();
    }
  }, [filterAdded, selectedTag, search]);

  /* USE EFFECT */
  useEffect(() => {
    if (calendarData) {
      let data = [];
      calendarData?.map((res, i) => {
        data.push({
          date: `${res?.month}/${res?.day}/${res?.year}`,
          data: res,
        });
      });

      let arr = data
        ? data.sort((a, b) => new Date(a.date) - new Date(b.date))
        : [];
      if (arr?.length > 0 && !date) {
        if (selectedDay) {
          setDate(selectedDay);
          dispatch(actions.setSelectedDay(null));
        } else {
          setDate(moment(arr[0]?.date).format("DD/MM/YYYY"));
        }
      }
      setSortData(arr);
    } else {
      setSortData([]);
      setTradeTileData([]);
      setDate("");
    }
  }, [calendarData]);

  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Daily Journal | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        {/* PAGE HEADING */}
        <div className="block xl:flex lg:flex justify-between items-center">
          <h1 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-bold text-black dark:text-white tracking-tight leading-none">
            Daily Journal
          </h1>
          <div className="block xl:flex lg:flex items-center gap-y-2 gap-2 mt-5 xl:mt-0 lg:mt-0">
            <div className="w-full xl:w-[25vh] lg:w-[25vh]">
              <input
                type="text"
                name="searchField"
                id="searchField"
                placeholder="Type ticker to search"
                value={search}
                className="w-full block border border-borderGrey dark:border-darkBorder rounded-md text-base font-proxima-semibold px-4 py-[0.7vh] placeholder:font-proxima-regular placeholder:text-sm focus:outline-none dark:bg-transparent dark:text-white"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <Popover
              open={filterPopover}
              handler={() => setFilterPopover(!filterPopover)}
              dismiss={{ outsidePress: false }}
              placement="bottom-start"
            >
              <PopoverHandler>
                <Button className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-md flex items-center justify-center gap-2 normal-case font-normal px-5 mt-2 xl:mt-0 lg:mt-0">
                  <IoSettingsSharp className="w-4 h-4 text-black dark:text-white" />
                  <p className="text-sm font-proxima-regular text-black dark:text-white">
                    Filters
                  </p>
                </Button>
              </PopoverHandler>
              <PopoverContent className="w-auto xl:w-[50vh] lg:w-[50vh] p-0 bg-white dark:bg-darkSidebar dark:border-darkBorder">
                <div className="px-5 py-2 border-b border-[#E5E5E5] dark:border-darkBorder">
                  <p className="text-sm font-proxima-semibold text-black dark:text-white">
                    Filters
                  </p>
                </div>
                <div className="p-5">
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <div className="block dark:hidden w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={productData}
                          className="tags-select w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Product"
                          onChange={(e) => setSelectedProduct(e)}
                          value={selectedProduct}
                        />
                      </div>
                      <div className="hidden dark:block w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={productData}
                          className="tags-select-dark w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Product"
                          onChange={(e) => setSelectedProduct(e)}
                          value={selectedProduct}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="block dark:hidden w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={mistakeData}
                          className="tags-select w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Mistakes"
                          onChange={(e) => setSelectedMistake(e)}
                          value={selectedMistake}
                        />
                      </div>
                      <div className="hidden dark:block w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={mistakeData}
                          className="tags-select-dark w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Mistakes"
                          onChange={(e) => setSelectedMistake(e)}
                          value={selectedMistake}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="block dark:hidden w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={tagData}
                          className="tags-select w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Setups"
                          onChange={(e) => setSelectedTag2(e)}
                          value={selectedTag2}
                        />
                      </div>
                      <div className="hidden dark:block w-full mt-2 xl:mt-0 lg:mt-0">
                        <Select
                          options={tagData}
                          className="tags-select-dark w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Setups"
                          onChange={(e) => setSelectedTag2(e)}
                          value={selectedTag2}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="block dark:hidden w-full">
                        <Select
                          options={sideOptions}
                          className="tags-select w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Side"
                          value={
                            selectedSide && {
                              label: selectedSide,
                              value: selectedSide,
                            }
                          }
                          onChange={(e) => {
                            setSelectedSide(e.value);
                            if (e.value === "SHORT") {
                              setSide(["SHORT"]);
                              return;
                            } else if (e.value === "LONG") {
                              setSide(["LONG"]);
                            } else {
                              setSide([]);
                            }
                          }}
                        />
                      </div>
                      <div className="hidden dark:block w-full">
                        <Select
                          options={sideOptions}
                          className="tags-select-dark w-full font-proxima-regular"
                          classNamePrefix="note-tag"
                          placeholder="Filter by Side"
                          value={{ label: selectedSide, value: selectedSide }}
                          onChange={(e) => {
                            setSelectedSide(e.value);
                            if (e.value === "SHORT") {
                              setSide(["SHORT"]);
                              return;
                            } else if (e.value === "LONG") {
                              setSide(["LONG"]);
                            } else {
                              setSide([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end px-5 py-2 border-t border-[#E5E5E5] dark:border-darkBorder">
                  <div className="flex items-center gap-3">
                    <Button
                      className="bg-white dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder text-black dark:text-white shadow-none hover:shadow-none py-2 rounded-md px-3 text-sm font-proxima-semibold normal-case font-normal"
                      onClick={() => setFilterPopover(!filterPopover)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="bg-logoOcre border border-logoOcre text-white shadow-none hover:shadow-none py-2 rounded-md px-3 text-sm font-proxima-semibold normal-case font-normal"
                      onClick={() => {
                        if (
                          selectedMistake !== "" ||
                          selectedSide !== "" ||
                          selectedProduct !== ""
                        ) {
                          setFilterAdded(!filterAdded);
                          setFilterPopover(!filterPopover);
                        } else {
                          setFilterAdded(false);
                        }
                      }}
                    >
                      Apply Filters
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            <Button
              className="w-full xl:w-auto lg:w-auto bg-buttonColor hover:bg-opacity-80 rounded-md text-sm font-proxima-semibold text-white shadow-none hover:shadow-none normal-case py-2 transition-all duration-300 ease-in-out mt-2 xl:mt-0 lg:mt-0"
              onClick={() => {
                setSelectedTag("");
                setSelectedMistake("");
                setSelectedTag2("");
                setSelectedProduct("");
                setSearch("");
                setSide([]);
                setSelectedSide("Filter by side");
                handleData("clear");
              }}
            >
              Clear
            </Button>
          </div>
        </div>

        {/* JOURNAL LIST */}
        <Tabs value={currentTab}>
          <div className="grid grid-cols-1 xl:grid-cols-7 lg:grid-cols-6 gap-0 gap-y-5 xl:gap-5 lg:gap-5 mt-5 overflow-auto overflow-x-hidden hide-scrollbar">
            {/* MONTHS LIST */}
            <div className="col-span-1 xl:col-span-1 lg:col-span-1">
              <Card className="h-auto xl:h-dailyJournalPageCard lg:h-dailyJournalPageCard bg-transparent dark:bg-darkSidebar rounded-lg border border-[#E5E5E5] dark:border-darkBorder bg-white shadow-lg block overflow-auto overflow-x-hidden hide-scrollbar">
                <CardBody className="px-3 py-5">
                  {/* MONTHS LIST */}
                  <div>
                    <div className="flex items-center justify-between px-3">
                      <p className="text-xs text-gray-400 font-proxima-semibold">
                        MONTHS
                      </p>

                      {/* YEAR SWITCHER */}
                      <div className="flex items-center">
                        <Button
                          className="p-1 rounded-r-none rounded-l-md shadow-none hover:shadow-none bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder flex items-center"
                          onClick={() => {
                            if (selectedYear !== 0 && selectedYear <= 9) {
                              setSelectedYear(selectedYear - 1);
                            }
                          }}
                        >
                          <IoIosArrowBack className="w-3 h-3 text-black dark:text-white" />
                        </Button>
                        <div className="px-2 py-1 border-t border-b border-borderGrey dark:border-darkBorder">
                          <h5 className="text-xs font-proxima-semibold text-black dark:text-white leading-none">
                            {yearData[selectedYear]}
                          </h5>
                        </div>
                        <Button
                          className="p-1 rounded-l-none rounded-r-md shadow-none hover:shadow-none bg-[#F5F5F5] dark:bg-bgDark border border-[#E5E5E5] dark:border-darkBorder flex items-center"
                          onClick={() => {
                            if (selectedYear < 9) {
                              setSelectedYear(selectedYear + 1);
                            }
                          }}
                        >
                          <IoIosArrowForward className="w-3 h-3 text-black dark:text-white" />
                        </Button>
                      </div>
                    </div>

                    <TabsHeader
                      className="p-0 bg-transparent shadow-none w-full block mt-3"
                      indicatorProps={{
                        className:
                          "bg-buttonColor bg-opacity-5 dark:bg-opacity-20 shadow-none text-buttonColor",
                      }}
                    >
                      {monthsList.map((folder) => (
                        <>
                          <Tab
                            key={folder.id}
                            value={folder.id}
                            className="text-sm font-proxima-semibold py-2 px-3 justify-start text-black dark:text-lightGreyText mt-2 block"
                            activeClassName="tab-active"
                            onClick={() => {
                              setCurrentTab(folder.id);
                              // let year = redux.endDate?.split("/")[2];
                              let year = yearData[selectedYear];
                              const input = `${folder.id}/${year}`;
                              const output = moment(input, "MM/YYYY");
                              dispatch(
                                actions.setStartDate(
                                  output.startOf("month").format("DD/MM/YYYY")
                                )
                              );
                              dispatch(
                                actions.setEndDate(
                                  output.endOf("month").format("DD/MM/YYYY")
                                )
                              );
                              dispatch(actions.setSelectedDay(null));
                              setDate("");
                              setNoteData("");
                            }}
                          >
                            <div className="flex items-center justify-between">
                              {folder.name}
                              <Tooltip content="Add note for the month">
                                <MdNoteAlt
                                  className="w-4 h-4 text-lightGreyText cursor-pointer"
                                  onClick={() => {
                                    setMonthNoteDialog(!monthNoteDialog);
                                    setMonthQuillValue("");
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </Tab>
                        </>
                      ))}
                    </TabsHeader>
                  </div>

                  {/* SETUPS */}
                  <div className="labels mt-5">
                    <p className="text-[1.3vh] text-gray-400 font-proxima-semibold">
                      SETUPS
                    </p>
                    <div className="flex flex-col gap-1 mt-3">
                      {tagData?.map((label) => (
                        <Button
                          key={label.value}
                          onClick={() => {
                            if (label.value === selectedTag) {
                              setSelectedTag("");
                              handleData("remove");
                            } else {
                              setSelectedTag(label.value);
                            }
                          }}
                          style={{
                            color: label.value === selectedTag && "#d59a4a",
                          }}
                          className="w-full bg-transparent hover:bg-buttonColor hover:bg-opacity-5 rounded-md px-4 py-2 shadow-none hover:shadow-none flex items-start gap-3 text-sm font-proxima-semibold text-[#8995AE] text-left capitalize"
                        >
                          <MdLabel
                            className="w-5 h-5 flex-shrink-0"
                            color={label?.color}
                          />
                          {label.label}
                        </Button>
                      ))}

                      {/* ADD LABEL BUTTON */}
                      <Link
                        to="/setup-management"
                        className="group w-full bg-transparent normal-case shadow-none hover:shadow-none hover:text-buttonColor hover:bg-buttonColor hover:bg-opacity-5 flex items-center gap-3 px-4 py-2 mt-1 text-sm font-proxima-semibold text-[#8995AE] rounded-md"
                      >
                        <IoMdAddCircle className="w-5 h-5 text-[#C3CAD9] group-hover:text-buttonColor transition-all duration-300 ease-in-out" />
                        Add label
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            {/* TRADES LIST */}
            <div className="col-span-1 xl:col-span-2 lg:col-span-2">
              <TabsBody className="p-0">
                <div className="p-0">
                  {loading ? (
                    <div className="h-auto xl:h-dailyJournalPageCard lg:h-dailyJournalPageCard overflow-auto overflow-x-hidden hide-scrollbar">
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg"
                        />
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                        <Skeleton
                          baseColor="#232327"
                          highlightColor="#141418"
                          className="w-full h-[15vh] rounded-lg mt-3"
                        />
                      </div>
                    </div>
                  ) : sortData?.length !== 0 ? (
                    <Card className="h-auto xl:h-dailyJournalPageCard lg:h-dailyJournalPageCard bg-transparent shadow-none rounded-lg overflow-auto overflow-x-hidden hide-scrollbar">
                      <CardBody className="p-0 flex flex-col gap-y-3">
                        {sortData.map((journal, idx) => (
                          <DayTile
                            data={journal}
                            key={journal.id}
                            date={journal.id}
                            tickers={
                              journal?.data?.trade
                                ? journal?.data?.trade?.map(
                                    (obj) => obj?.ticker
                                  )
                                : ["223"]
                            }
                            netProfitLoss={journal.netProfitLoss}
                            note={journal.data?.notebookData?.text}
                            exchangeFee={
                              journal.data?.notebookData?.exchangeFee
                            }
                            isActive={idx}
                            id={idx}
                          />
                        ))}
                      </CardBody>
                    </Card>
                  ) : (
                    <Card className="h-auto xl:h-dailyJournalPageCard lg:h-dailyJournalPageCard bg-transparent dark:bg-darkSidebar rounded-lg border border-[#E5E5E5] dark:border-darkBorder bg-white shadow-lg block">
                      <CardBody className="p-0 flex items-center justify-center h-full">
                        <div>
                          <img
                            src={require("../../../assets/images/no-records-found.jpg")}
                            className="w-[35vh] h-auto object-cover mix-blend-multiply mx-auto"
                            alt="No records found"
                          />
                          <div className="mt-5 text-center">
                            <h5 className="text-xl font-proxima-bold text-black dark:text-white">
                              No trades to display
                            </h5>
                            <p className="text-base font-proxima-regular text-greyText dark:text-lightGreyText">
                              Please change the date range to see your previous
                              trades.
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </TabsBody>
            </div>

            {/* TRADE DETAILS */}
            <div className="col-span-1 xl:col-span-4 lg:col-span-4">
              <div className="h-auto xl:h-dailyJournalPageCard lg:h-dailyJournalPageCard bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg overflow-auto overflow-x-hidden hide-scrollbar">
                {/* HEADING */}
                <div className="block xl:flex lg:flex items-center justify-between border-b border-[#E5E5E5] dark:border-darkBorder px-5 py-3">
                  <h2 className="text-lg font-proxima-bold text-black dark:text-white leading-none">
                    {date ? date : selectedDay}
                  </h2>
                  <Button
                    className="w-full xl:w-auto lg:w-auto bg-buttonColor hover:bg-opacity-80 rounded-md text-sm font-proxima-semibold text-white shadow-none hover:shadow-none normal-case py-2 transition-all duration-300 ease-in-out mt-2 xl:mt-0 lg:mt-0"
                    onClick={() => {
                      setDayNoteDialog(!dayNoteDialog);
                    }}
                  >
                    Trading Day Details
                  </Button>
                </div>

                {/* TRADES LIST */}
                <div className="p-5 h-full">
                  {/* NOTE FOR THE DAY */}
                  {noteData && (
                    <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-30 border border-[#E5E5E5] dark:border-darkBorder rounded-lg px-5 py-3 mb-5">
                      <h5 className="text-base font-proxima-bold text-black dark:text-white">
                        Note for the day
                      </h5>
                      <ReactQuill
                        modules={{
                          toolbar: false,
                        }}
                        theme="snow"
                        toolbar={false}
                        className="rounded-lg text-base dark:text-white text-black readonly-editor font-proxima-regular"
                        placeholder="Add your note here"
                        value={noteData[0]?.text}
                        readOnly
                      />
                    </div>
                  )}

                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0 gap-y-5 xl:gap-5 lg:gap-5">
                    {tradeTileData &&
                      tradeTileData?.length !== 0 &&
                      tradeTileData?.map((res, idx) => {
                        return (
                          <div className="col-span-1" key={idx}>
                            <TradeTile isProfit data={res} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>

      {/* NOTES DIALOG */}
      <Dialog
        open={noteDialog}
        handler={() => setNoteDialog(!noteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              {edit ? "Edit" : "Add"} note
            </h5>
          </div>
          <div className="p-0">
            <div className="hidden dark:block">
              <ReactQuill
                value={quillValue}
                onChange={setQuillValue}
                theme="snow"
                className="modal-box rounded-lg text-base dark"
                placeholder="Add your note here"
                preserveWhitespace={true}
              />
            </div>
            <div className="block dark:hidden">
              <ReactQuill
                value={quillValue}
                onChange={setQuillValue}
                theme="snow"
                className="modal-box rounded-lg text-base"
                placeholder="Add your note here"
                preserveWhitespace={true}
              />
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setNoteDialog(!noteDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => addNote({ type: "TRADE" })}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loading ? (
                <Spinner className="h-full" />
              ) : edit ? (
                "Update "
              ) : (
                "Save "
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* DIALOG BOX */}
      <Dialog
        open={dayNoteDialog}
        handler={() => setDayNoteDialog(!dayNoteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Trading Day Details
            </h5>
          </div>
          <div className="p-5">
            <div className="form-group mt-3">
              <label
                htmlFor="comission"
                className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText"
              >
                Commission
              </label>
              <Input
                type="number"
                name="comission"
                id="comission"
                placeholder="Commission"
                value={exchangeFee}
                min={0}
                onChange={(e) => {
                  // if (isFirstDigitZeroNotAcceptable(e.target.value)){

                  setExchangeFee(isFirstDigitZeroNotAcceptable(e.target.value));
                }}
                // }
              />
            </div>
            <div className="mt-5">
              <div className="block dark:hidden">
                <ReactQuill
                  value={dayQuillValue}
                  onChange={setDayQuillValue}
                  theme="snow"
                  className="modal-box rounded-lg text-base note-editor"
                  placeholder="Add your note here"
                  preserveWhitespace={true}
                />
              </div>
              <div className="hidden dark:block">
                <ReactQuill
                  value={dayQuillValue}
                  onChange={setDayQuillValue}
                  theme="snow"
                  className="modal-box rounded-lg text-base note-editor dark"
                  placeholder="Add your note here"
                  preserveWhitespace={true}
                />
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setDayNoteDialog(!dayNoteDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                addNote({
                  type: "DATE",
                })
              }
              disabled={loading}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loading ? (
                <Spinner className="h-full" />
              ) : editDay ? (
                "Update "
              ) : (
                "Save "
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* ADD/EDIT NOTE FOR THE MONTH */}
      <Dialog
        open={monthNoteDialog}
        handler={() => setMonthNoteDialog(!monthNoteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              {editMonth ? "Update" : "Add"} note for the month
            </h5>
          </div>
          <div className="p-5">
            <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
              Add tags for this month
            </p>
            <div className="block dark:hidden">
              <Select
                options={tagData}
                className="tags-select w-full font-proxima-regular mt-2"
                classNamePrefix="note-tag"
                placeholder="Click to add tags"
                onChange={(e) => setTagm(e)}
                value={tagm}
              />
            </div>
            <div className="hidden dark:block">
              <Select
                options={tagData}
                className="tags-select-dark w-full font-proxima-regular mt-2"
                classNamePrefix="note-tag"
                placeholder="Click to add tags"
                onChange={(e) => setTagm(e)}
                value={tagm}
              />
            </div>
            <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText mt-5">
              Add mistake for this month
            </p>
            <div className="block dark:hidden">
              <Select
                options={mistakeData}
                className="tags-select w-full font-proxima-regular mt-2"
                classNamePrefix="note-tag"
                placeholder="Click to add mistake"
                onChange={(e) => setMistakem(e)}
                value={mistakem}
              />
            </div>
            {/* <div className="form-group mt-3">
              <label
                htmlFor="comission"
                className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
                Commission
              </label>
              <Input
                type="number"
                name="comission"
                id="comission"
                placeholder="Commission"
                value={exchangeFeem}
                min={0}
                onChange={(e) => setExchangeFeem(+Number(e.target.value))}
              />
            </div> */}
            <div className="hidden dark:block">
              <Select
                options={mistakeData}
                className="tags-select-dark w-full font-proxima-regular mt-2"
                classNamePrefix="note-tag"
                placeholder="Click to add mistake"
                onChange={(e) => setMistakem(e)}
                value={mistakem}
              />
            </div>
            <div className="mt-5">
              <div className="block dark:hidden">
                <ReactQuill
                  value={monthQuillValue}
                  onChange={setMonthQuillValue}
                  theme="snow"
                  className="modal-box rounded-lg text-base note-editor"
                  placeholder="Add your note here"
                  preserveWhitespace={true}
                />
              </div>
              <div className="hidden dark:block">
                <ReactQuill
                  value={monthQuillValue}
                  onChange={setMonthQuillValue}
                  theme="snow"
                  className="modal-box rounded-lg text-base note-editor dark"
                  placeholder="Add your note here"
                  preserveWhitespace={true}
                />
              </div>
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black dark:text-white bg-[#F5F5F5] dark:bg-bgDark hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setMonthNoteDialog(!monthNoteDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                addNoteMonth({
                  type: "MONTH",
                })
              }
              disabled={loading}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loading ? (
                <Spinner className="h-full" />
              ) : editMonth ? (
                "Update "
              ) : (
                "Save "
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* ADD LABEL DIALOG */}
      <Dialog
        open={addLabel}
        handler={() => setAddLabel(!addLabel)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Add Label
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => setAddLabel(!addLabel)}
            >
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
          <div className="px-5 py-3">
            <div className="form-group">
              <label
                htmlFor="labelName"
                className="text-sm font-proxima-semibold text-black dark:text-white"
              >
                Label Name <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                name="labelName"
                id="labelName"
                placeholder="Type your label name"
              />
            </div>
            <div className="form-group mt-5">
              <label
                htmlFor="labelColor"
                className="text-sm font-proxima-semibold text-black dark:text-white"
              >
                Label Color <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center gap-2 flex-wrap mt-2">
                {labelColors.map((color) => (
                  <div
                    className={`w-10 h-10 rounded-full flex-shrink-0 border cursor-pointer flex items-center justify-center`}
                    style={{
                      borderColor:
                        labelColorSelected === color.color
                          ? color.color
                          : "#E5E5E5",
                    }}
                    onClick={() => setLabelColorSelected(color.color)}
                    key={color.id}
                  >
                    <div
                      className="w-8 h-8 rounded-full"
                      style={{ backgroundColor: color.color }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end px-5 py-3">
            <Button className="shadow-none hover:shadow-none bg-buttonColor text-sm font-proxima-semibold text-white py-2 normal-case">
              Submit
            </Button>
          </div>
        </DialogBody>
      </Dialog>
      {/* SQUARE OFF DIALOG */}
      <Dialog
        open={squareOffDialog}
        handler={() => setSquareOffDialog(!squareOffDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Square off this trade
            </h5>
          </div>
          <div className="p-5">
            <div className="form-group">
              <label className="text-sm font-proxima-semibold text-black dark:text-white">
                Price <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  value={closePrice}
                  onChange={(e) =>
                    setClosePrice(isFirstDigitZeroNotAcceptable(e.target.value))
                  }
                  type="number"
                  min={0}
                  placeholder="Price"
                  className="w-full px-5 py-2 rounded-md block bg-transparent border border-borderGrey dark:border-darkBorder text-sm font-proxima-semibold text-black dark:text-white placeholder:text-gray-500 placeholder:font-proxima-regular outline-none focus:outline-none"
                />
                {isSubmitted && !closePrice && "Please enter the price."}
              </div>
            </div>
            <div className="form-group mt-5">
              <label className="text-sm font-proxima-semibold text-black dark:text-white">
                Date and Time <span className="text-red-500">*</span>
              </label>
              <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                <div className="mt-1">
                  <div className="block dark:hidden">
                    <DateTime
                      onChange={handleSelect}
                      value={dateTime}
                      isValidDate={validation}
                      className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <DateTime
                      onChange={handleSelect}
                      value={dateTime}
                      isValidDate={validation}
                      className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker dark"
                    />
                  </div>
                </div>
              </div>
              {isSubmitted && !dateTime && "Please enter date and time."}
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black bg-[#F5F5F5] hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setSquareOffDialog(!squareOffDialog)}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              onClick={handleSquareOff}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loader ? "Loading..." : "Square off"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default DailyJournal;
