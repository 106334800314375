/* LAYOUT COMPONENTS */
import Sidebar from "./sidebar/index";
import Header from "./header";
import ResponsiveHeader from "./header/responsive";

const Layout = ({ children }) => {
  return (
    <section className="bg-bodyBackground dark:bg-bgDark overflow-x-hidden">
      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-start">
          <div className="hidden xl:block lg:block">
            <Sidebar />
          </div>
          <div className="flex-shrink-0 flex-1">
            <div className="hidden xl:block lg:block px-5 pt-3 pb-0">
              <Header />
            </div>
            <div className="block xl:hidden lg:hidden">
              <ResponsiveHeader />
            </div>
            <div className="h-auto xl:h-mainSection lg:h-mainSection min-h-screen xl:min-h-fit lg:min-h-fit overflow-auto overflow-x-hidden hide-scrollbar">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
