import axios from "axios";
import { SERVER_URL } from "../config/index";
import store from "../redux/store";
import actions from "../redux/user/actions";
const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];

request.interceptors.request.use(
  (config) => {
    if (store.getState().user?.token) {
      config.headers.Authorization = `${store.getState().user?.token}`;
    }
    store.dispatch(actions.setLoading(true));

    requests.push(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  function (response) {
    store.dispatch(actions.setLoading(false));
    return response;
  },
  function (error) {
    store.dispatch(actions.setLoading(false));
    return Promise.reject(error);
  }
);
export default request;
