const actions = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOADING: "SET_LOADING",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_EXCHANGE_CONNECTED: "SET_EXCHANGE_CONNECTED",
  SET_SELECTED_EXCHANGE: "SET_SELECTED_EXCHANGE",
  SET_START_DATE: "SET_START_DATE",
  SET_END_DATE: "SET_END_DATE",
  SELECTED_DAY: "SELECTED_DAY",
  SET_SELECTED_TRADE: "SET_SELECTED_TRADE",
  setUser: (data) => {
    return {
      type: actions.SET_USER,
      data,
    };
  },
  setToken: (data) => {
    return {
      type: actions.SET_TOKEN,
      data,
    };
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
  setLoggedIn: (data) => {
    return {
      type: actions.SET_LOGGEDIN,
      data,
    };
  },
  setConnectedExchange: (data) => {
    return {
      type: actions.SET_EXCHANGE_CONNECTED,
      data,
    };
  },
  setSelectedExchange: (data) => {
    return {
      type: actions.SET_SELECTED_EXCHANGE,
      data,
    };
  },
  setStartDate: (data) => {
    return {
      type: actions.SET_START_DATE,
      data,
    };
  },
  setEndDate: (data) => {
    return {
      type: actions.SET_END_DATE,
      data,
    };
  }, 
  setSelectedDay: (data) => {
    return {
      type: actions.SELECTED_DAY,
      data,
    };
  },
  setSelectedTrade: (data) => {
    return {
      type: actions.SET_SELECTED_TRADE,
      data,
    };
  },
  
};

export default actions;
